import React, { HTMLAttributes } from 'react';
import cn from 'classnames';

interface InputSkeletonProps extends HTMLAttributes<HTMLDivElement> {
  skeletonClasses?: string;
}

/**
 * `InputSkeleton` represents a loading structure for a variety of `Inputs` components.
 * @author Alan Chávez<alan.chavez@evacenter.com>
 * Created at 2023-01-17
 */
export const InputSkeleton = ({ className, skeletonClasses, ...restProps }: InputSkeletonProps) => {
  const skeletonClassnames = cn(skeletonClasses, 'e-bg-neutral-500 e-animate-pulse');
  const classes = {
    container: cn(className, 'e-flex e-flex-col e-space-y-2'),
    label: cn(skeletonClassnames, 'e-h-3 e-w-24 e-max-w-full e-rounded-lg'),
    input: cn(skeletonClassnames, 'e-h-10 e-rounded-lg'),
  };

  return (
    <div className={classes.container} {...restProps}>
      <div className={classes.label} />
      <div className={classes.input} />
    </div>
  );
};
