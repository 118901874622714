import React, { ButtonHTMLAttributes } from 'react';

import { Icon, IconCatalog } from '../../Icon/Icon';
import { cn } from '../..';

export enum ActionButtonItemSize {
  base = 'base',
}

const Sizes: Record<ActionButtonItemSize, string> = {
  [ActionButtonItemSize.base]: 'e-gap-1 e-p-2 e-text-2xs',
};

export enum ActionButtonVariant {
  primary = 'primary',
  secondary = 'secondary',
  destructive = 'destructive',
}

const Variants: Record<ActionButtonVariant, string> = {
  [ActionButtonVariant.primary]: 'e-text-primary-500',
  [ActionButtonVariant.secondary]: 'e-text-base-white',
  [ActionButtonVariant.destructive]: 'e-text-error-500',
};

enum ActionButtonIconSize {
  base = 16,
}

export enum ActionButtonHtmlType {
  button = 'button',
  reset = 'reset',
  submit = 'submit',
}

export interface ActionButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * Changes the size of the button, giving it more or less padding
   */
  size?: ActionButtonItemSize;

  /**
   * The icon to display.
   */
  icon: IconCatalog;

  /**
   * Disables the button, disallowing user interaction.
   */
  isDisabled?: boolean;

  /**
   * HTML type attribute of the button.
   */
  htmlType?: ActionButtonHtmlType;

  /**
   * Text to display inside the Navbar.
   */
  variant?: ActionButtonVariant;

  /**
   * Text to display inside the Navbar.
   */
  label: string;
}

/**
 * Action buttons are used to perform actions in a toolbar, navbar, or other layout.
 * Figma: https://www.figma.com/file/BgmAmgok8EtETlcx4u7KXF/DICOM-Viewer?type=design&node-id=1-6516&t=tk6UJqyMH1MDOkVr-0
 * @author Jesus Bossa<x@jesusbossa.dev>
 * Created at 2023-05-18
 */
export const ActionButton = React.forwardRef<HTMLButtonElement, ActionButtonProps>((props: ActionButtonProps, ref) => {
  const {
    label,
    size = ActionButtonItemSize.base,
    icon,
    isDisabled = false,
    variant = ActionButtonVariant.primary,
    htmlType = ActionButtonHtmlType.button,
    className,
    onClick,
    ...restOfProps
  } = props;

  const classes = {
    button: cn(
      'e-flex e-items-center e-justify-center e-flex-col',
      'e-font-medium',
      'hover:e-bg-neutral-800 e-outline-none',
      Sizes[size],
      Variants[variant],
      className,
      {
        'e-opacity-30 e-cursor-not-allowed': isDisabled,
      },
    ),
  };

  /* Render JSX */
  return (
    <button
      ref={ref}
      className={classes.button}
      type={htmlType}
      disabled={isDisabled}
      onClick={onClick}
      {...restOfProps}>
      {icon && <Icon icon={icon} width={ActionButtonIconSize[size]} height={ActionButtonIconSize[size]} />}
      <span>{label}</span>
    </button>
  );
});
