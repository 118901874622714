import React, { ChangeEvent, ReactNode, useMemo, useState } from 'react';
import cn from 'classnames';
import { NestedSelectOption } from '../../../../common';
import { Checkbox } from '../../../Controls/Checkbox/Checkbox';
import { Icon, IconCatalog } from '../../../Icon/Icon';
import { OptionItem, OptionItemLabel } from '../../../Inputs/OptionLabel/OptionLabel';
import { useTreeOptions } from '../../hooks/useTreeOptionsContext';

export interface ParentOptionProps {
  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * Specify an optional className to be added to the ParentOption component
   */
  parentItemClassName?: string;

  /**
   * Indicate if the parent option should mark the checkbox when clicking the label
   */
  selectOnParentClick?: boolean;

  /**
   * The option config
   */
  option: NestedSelectOption;

  /**
   * Component children to display as the main content
   */
  children?: ReactNode;
}

/**
 * Represent the Parent option (collapsible) within the DropdownTree (NestedMultiSelect)
 * @author Sergio Ruiz Davila<sergio.ruiz@evacenter.com>
 * Created at 2022-09-30
 */
export const ParentOption = ({
  className,
  parentItemClassName,
  selectOnParentClick = false,
  option,
  children,
}: ParentOptionProps) => {
  const [open, setOpen] = useState(option.isOpen);
  const { borderless, changeParentCheck, onSelectedOptionChange } = useTreeOptions();

  const classes = {
    collapsible: cn(className, 'e-overflow-hidden', {
      'e-h-auto': open,
      'e-h-0': !open,
    }),
  };

  const handleToggleClick = (): void => setOpen(!open);

  const handleCheckboxClick = (event: React.MouseEvent) => event.stopPropagation();

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    const { checked } = event.target;
    const output = changeParentCheck(option.value, checked);
    onSelectedOptionChange(option, checked, output);
  };

  const checkbox = useMemo(
    () => (
      <Checkbox
        id={option.value}
        color={option.color}
        onChange={handleCheckboxChange}
        onClick={handleCheckboxClick}
        checked={option.checked}
        indeterminate={option.indeterminate}
      />
    ),
    [option.checked, option.options, option.value],
  );

  if (!option.isVisible) return null;

  const parentItemClasses = cn(parentItemClassName, 'e-relative hover:e-bg-neutral-700 e-border-b', {
    'e-border-transparent': borderless,
    'e-border-neutral-500': !borderless,
  });

  return (
    <div className={parentItemClasses} onClick={handleToggleClick}>
      <OptionItem
        {...(selectOnParentClick && {
          as: 'label',
          htmlFor: option.value,
        })}
        role="option"
        className="e-px-4 e-py-2 e-h-10 e-flex e-items-center e-space-x-3 e-cursor-pointer e-overflow-hidden"
        aria-selected={option.checked}>
        {checkbox}
        <OptionItemLabel>{option.label}</OptionItemLabel>
        {/* CARET ICON (ARROW) */}
        {children && (
          <div className="e-flex e-items-center e-space-x-2 e-self-stretch e-flex-shrink-0 e-pl-1 e-ml-auto">
            <Icon className="e-text-neutral-50" icon={open ? IconCatalog.chevronUp : IconCatalog.chevronDown} />
          </div>
        )}
      </OptionItem>
      <div className={classes.collapsible}>{children}</div>
    </div>
  );
};
