import React from 'react';
import cn from 'classnames';
import { ColumnPinningPosition } from '@tanstack/react-table';
import { CellGradientVariant } from '../../../../common';

interface PinnedCellFadeProps {
  /**
   * Position of the pinned cell to take as reference for the direction
   */
  position: ColumnPinningPosition;
  /**
   * Indicate if the row is currently selected
   */
  isRowSelected?: boolean;
  /**
   * Provide a variant to style the gradient/fade color
   */
  variant?: CellGradientVariant;
}

/**
 * `PinnedCellFade` generates a fade effect over pinned cells
 * @author Alan Chávez<alan.chavez@evacenter.com>
 * Created at 2022-09-15
 */
export const PinnedCellFade = ({
  position,
  isRowSelected = false,
  variant = CellGradientVariant.BodyCell,
}: PinnedCellFadeProps) => {
  if (!position) return null;

  const variantClasses = {
    [CellGradientVariant.BodyCell]: cn({
      ' e-to-neutral-900': !isRowSelected,
      ' e-to-primary-900': isRowSelected,
    }),
    [CellGradientVariant.HeaderCell]: 'e-to-neutral-700',
  };

  const classes = {
    gradientFade: cn(
      'e-w-8 xl:e-hidden',
      'e-absolute e-top-0 e-h-full e-pointer-events-none',
      'e-from-transparent',
      variantClasses[variant],
      {
        'e-bg-gradient-to-r e-left-0 -e-translate-x-full': position === 'right',
        'e-bg-gradient-to-l e-right-0 e-translate-x-full': position === 'left',
      },
    ),
  };

  return <div className={classes.gradientFade} />;
};
