import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { Trigger, TabsTriggerProps } from '@radix-ui/react-tabs';

import { getDataStateAttribute } from '../../../../../common';
import { Icon, IconCatalog } from '../../../../Icon/Icon';
import { Tag, TagVariant } from '../../../../Tag/Tag';

const MAX_COUNTER_VALUE = 99;

export enum TabsItemSize {
  xs = 'xs',
  sm = 'sm',
  base = 'base',
}

const Sizes: Record<TabsItemSize, string> = {
  [TabsItemSize.xs]: 'e-gap-1 e-p-2 e-text-2xs',
  [TabsItemSize.sm]: 'e-gap-1 e-p-2 e-text-sm',
  [TabsItemSize.base]: 'e-gap-2 e-p-4 e-text-base',
};

export enum TabsItemVariant {
  vertical = 'vertical',
  horizontal = 'horizontal',
}

const Variants: Record<TabsItemVariant, string> = {
  [TabsItemVariant.horizontal]: 'e-flex-row',
  [TabsItemVariant.vertical]: 'e-flex-col',
};

enum TabsItemIconSize {
  xs = 16,
  sm = 16,
  base = 24,
}

export enum TabsHtmlType {
  button = 'button',
  reset = 'reset',
  submit = 'submit',
}

export interface TabsItemProps extends TabsTriggerProps {
  /**
   * Changes the size of the button, giving it more or less padding
   */
  size?: TabsItemSize;

  /**
   * The icon to display.
   */
  icon?: IconCatalog;

  /**
   * Disables the button, disallowing user interaction.
   */
  isDisabled?: boolean;

  /**
   * HTML type attribute of the button.
   */
  htmlType?: TabsHtmlType;

  /**
   * Text to display inside the Navbar.
   */
  label: string;

  /**
   * Amount to be attached next to the label as a badge.
   */
  counter?: number;

  /**
   * Tag's variant colors to be used for the tab item.
   */
  tagVariant?: TagVariant;
}

/**
 * Tabs are used to initialize an action.
 * @author Jesus Bossa<x@jesusbossa.dev>
 * Created at 2022-05-24
 * @deprecated Use `react-tabs` instead
 * https://github.com/eva-tech/ui-kit/pkgs/npm/react-tabs
 */
export const TabsItem = React.forwardRef<HTMLButtonElement, TabsItemProps>((props: TabsItemProps) => {
  const {
    label,
    size = TabsItemSize.base,
    icon,
    isDisabled = false,
    htmlType = TabsHtmlType.button,
    className,
    tagVariant = TagVariant.primary,
    counter,
    onClick,
    ...restOfProps
  } = props;

  const itemReference = useRef<HTMLButtonElement>(null);
  const [selected, setSelected] = useState(false);
  const [variant, setVariant] = useState(TabsItemVariant.horizontal);

  const setSelectedClasses = () => {
    if (selected) {
      if (tagVariant === TagVariant.transparent) return 'e-border-neutral-50 e-text-neutral-50';
      return 'e-border-primary-500 e-text-neutral-50';
    }

    if (tagVariant === TagVariant.transparent) return 'e-border-transparent e-text-neutral-50';
    return 'e-border-transparent e-text-neutral-300';
  };

  const classes = {
    tab: cn(
      className,
      'e-flex e-items-center e-justify-center e-transition-colors e-duration-500',
      'e-border-b-4 e-font-medium e-outline-none',
      setSelectedClasses(),
      Variants[variant],
      Sizes[size],
      {
        'e-opacity-30 e-cursor-not-allowed': isDisabled,
        'hover:e-bg-neutral-800': tagVariant !== TagVariant.transparent,
      },
    ),
  };

  // Because a memo inside radix tab trigger component, we listen when data-orientation is setted
  useEffect(() => {
    const currentElement = itemReference.current;
    if (!currentElement) return setVariant(TabsItemVariant.horizontal);

    const currentVariant = currentElement.getAttribute('data-orientation');

    if (currentVariant === 'vertical') return setVariant(TabsItemVariant.vertical);
    return setVariant(TabsItemVariant.horizontal);
  }, [itemReference]);

  // Listen when a component change it's active behavior
  useEffect(() => {
    const currentElement = itemReference.current;
    if (!currentElement) return;

    const observer = new MutationObserver((mutationsList) => {
      const isActive = getDataStateAttribute(mutationsList, currentElement);
      setSelected(isActive || false);
    });

    observer.observe(currentElement, { attributes: true });

    return () => observer.disconnect();
  }, []);

  const getFormattedCounterAmount = () => {
    return counter && counter > MAX_COUNTER_VALUE ? `+${MAX_COUNTER_VALUE}` : counter;
  };

  const renderCounter = () => {
    if (!counter) return;
    if (counter <= 0) return;

    return (
      <Tag invert variant={tagVariant}>
        {getFormattedCounterAmount()}
      </Tag>
    );
  };

  /* Render JSX */
  return (
    <Trigger
      className={classes.tab}
      type={htmlType}
      disabled={isDisabled}
      onClick={onClick}
      ref={itemReference}
      {...restOfProps}>
      {icon && <Icon icon={icon} width={TabsItemIconSize[size]} height={TabsItemIconSize[size]} />}
      <span>{label}</span>
      {renderCounter()}
    </Trigger>
  );
});
