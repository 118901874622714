import React from 'react';
import { cn } from '../../../common/utils';
import { Button, ButtonProps } from '../Button/Button';
import { IconCatalog } from '../../Icon/Icon';

export enum BrandedButtonVariant {
  whatsapp = 'whatsapp',
}

const Variants: Record<BrandedButtonVariant, string> = {
  [BrandedButtonVariant.whatsapp]: 'e-bg-success-900 e-text-success-400 hover:e-bg-success-800',
};

const IconVariants: Record<BrandedButtonVariant, IconCatalog> = {
  [BrandedButtonVariant.whatsapp]: IconCatalog.whatsapp,
};

export interface BrandedButtonProps extends Partial<Omit<ButtonProps, 'variant' | 'startIcon' | 'endIcon'>> {
  /**
   * The shape of the component. It determines the importance in the hierarchy, for example, the contained button commands the most attention
   */
  variant?: BrandedButtonVariant;
}

/**
 * This is a button component that is used as
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2024-03-19
 */
export const BrandedButton = ({
  children,
  variant = BrandedButtonVariant.whatsapp,
  className,
  ...restOfProps
}: BrandedButtonProps) => {
  const classes = {
    button: cn(Variants[variant], className),
  };

  /* Render JSX */
  return (
    <Button {...restOfProps} endIcon={IconVariants[variant]} className={classes.button}>
      {children}
    </Button>
  );
};
