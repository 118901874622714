import { dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import enUs from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import { WeekStartsOn } from '../types';

// Enabled locales for the calendar
const locales = {
  es,
  en: enUs,
};

export const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: (date: Date) => startOfWeek(date, { weekStartsOn: WeekStartsOn.MONDAY }),
  getDay,
  locales,
});
