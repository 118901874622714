import React, { createContext, useContext } from 'react';

export interface ViewerInfoType {
  classes?: {
    footer?: { text?: string; title?: string; footerContainer?: string };
    headerDesktop?: { header?: string; text?: string };
  };
  showRightSerieInformation?: boolean;
  showLeftSerieInformation?: boolean;
  showPatientInfomation?: boolean;
  showImageDimensionInBottomRigth?: boolean;
}

interface ViewerInfoProps {
  children: JSX.Element;
  value?: ViewerInfoType;
}

const initialContext = {
  showPatientInfomation: true,
  showLeftSerieInformation: true,
  showRightSerieInformation: true,
};

export const ViewerInfoContext = createContext<ViewerInfoType>(initialContext);

export const ViewerInfoProvider = ({ children, value }: ViewerInfoProps): JSX.Element => {
  return <ViewerInfoContext.Provider value={{ ...initialContext, ...value }}>{children}</ViewerInfoContext.Provider>;
};

export const useViewerInfoProviderContext = (): ViewerInfoType => {
  const context = useContext(ViewerInfoContext);
  return context;
};
