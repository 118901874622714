import React, { useMemo } from 'react';
import cn from 'classnames';
import {
  ColumnDef,
  getCoreRowModel,
  RowData,
  Table as TableType,
  TableOptions,
  useReactTable,
} from '@tanstack/react-table';
import { TableHead } from './TableHead/TableHead';
import { TableBody } from './TableBody/TableBody';
import { TablePagination, TablePaginationProps } from './TablePagination/TablePagination';
import { CheckboxCell } from './TableCell/CheckboxCell/CheckboxCell';
import { TableProvider } from '../../common/contexts';
import { ColumnAccessors } from '../../common';
import { Scrollable } from '../Scrollable/Scrollable';

const DEFAULT_PAGE_COUNT = -1;

export interface TableProps<T extends RowData> extends Omit<TableOptions<T>, 'getCoreRowModel'>, TablePaginationProps {
  /**
   * Specify an optional className to be added to the Table container.
   */
  className?: string;

  /**
   * Specify if pagination is enabled for the table
   */
  enablePagination?: boolean;

  /**
   * Specify the amount of results to be shown, this helps calculating the amount of page items to be displayed
   */
  totalCount?: number;

  /**
   * Specify if the pagination helper should be displayed
   */
  enablePaginationHelper?: boolean;

  /**
   * Specify an optional className to be added to the Table Pagination container.
   */
  tablePaginationClassName?: string;

  /**
   * Specify an optional className to be added to the `table` element.
   */
  tableClassName?: string;

  /**
   * Callback to be called when a row deletion animation ends
   */
  onDeleteRowAnimationEnd?: (rowId: string) => void;
}

/**
 * `Table` helps to represent a set of data in a table
 * @author Alan Chávez<alan.chavez@evacenter.com>
 * Created at 2022-09-23
 */
export const Table = <T extends RowData>({
  className,
  enableRowSelection,
  initialState,
  data,
  columns,
  state,
  enablePagination,
  totalCount,
  tablePaginationClassName,
  tableClassName,
  paginationHelper,
  onDeleteRowAnimationEnd,
  ...restProps
}: TableProps<T>) => {
  const classes = {
    container: cn(className, 'e-flex e-flex-col xl:e-space-y-4 e-max-w-full'),
    table: cn(tableClassName, 'e-w-full e-text-sm'),
  };

  const displayColumns = useMemo(
    () =>
      (
        [
          // Verify if row selection is enabled to display the checkboxes
          enableRowSelection && {
            id: ColumnAccessors.RowSelect,
            header: ({ table }) => <CheckboxCell table={table} />,
            cell: ({ row }) => <CheckboxCell row={row} />,
          },
        ] as Array<ColumnDef<T>>
      ).filter(Boolean),
    [enableRowSelection],
  );

  const columnDefs = [...displayColumns, ...columns];
  const defaultState = {
    enablePinning: true,
    columnPinning: {
      right: [ColumnAccessors.Actions],
    },
  };

  const tableInstance = useReactTable({
    getCoreRowModel: getCoreRowModel(),
    ...restProps,
    data: data,
    columns: columnDefs,
    initialState: initialState ?? {},
    pageCount: totalCount ?? DEFAULT_PAGE_COUNT,
    state: {
      ...defaultState,
      ...state,
    },
  });

  return (
    <TableProvider tableInstance={tableInstance as TableType<RowData>}>
      <div className={classes.container}>
        <Scrollable className="e-h-full">
          <table data-testid="table" className={classes.table}>
            <TableHead />
            <TableBody onDeleteRowAnimationEnd={onDeleteRowAnimationEnd} />
          </table>
        </Scrollable>
        {enablePagination && (
          <TablePagination className={tablePaginationClassName} paginationHelper={paginationHelper} />
        )}
      </div>
    </TableProvider>
  );
};
