import React, { useRef } from 'react';
import cn from 'classnames';
import { Editor, IAllProps as EditorProps } from '@tinymce/tinymce-react';
import type { Editor as TinyMCEEditor } from 'tinymce';
import { ALLOWED_PLUGINS } from './constants';
import { CDN_URL } from '../../../common/constants';

export interface RichTextareaProps extends Omit<EditorProps, 'onChange' | 'onEditorChange'> {
  /**
   * Class to be added to the component
   */
  className?: string;

  /**
   * Descriptive text to be read to screenreaders
   */
  ariaLabel?: string;

  /**
   * The value of the textarea
   */
  value?: string;

  /**
   * The minimum height of the textarea
   */
  minHeight?: number;

  /**
   * The maximum height of the textarea
   */
  maxHeight?: number;

  /**
   * Callback to be called when the value changes
   */
  onChange?: (value: string) => void;
}

/**
 * This is the textarea component with rich text capabilities
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created on 2023-07-17
 */
export const RichTextarea = ({
  className,
  minHeight = 200,
  maxHeight = 500,
  value,
  onChange,
  ...restProps
}: RichTextareaProps) => {
  const classes = {
    container: cn(className, 'eva-richtextarea'),
  };

  const editorRef = useRef<TinyMCEEditor | null>(null);

  const handleEditorChange = (newValue: string) => {
    if (onChange) onChange(newValue);
  };

  return (
    <div className={classes.container}>
      {/* https://stackoverflow.com/questions/71843690/tinymce-react-component-editor-cannot-be-used-as-a-jsx-component */}
      {/* @ts-ignore */}
      <Editor
        {...restProps}
        tinymceScriptSrc={`${CDN_URL}/libs/tinymce/6.8.3/tinymce.min.js`}
        onInit={(_evt, editor) => (editorRef.current = editor)}
        initialValue={value}
        onEditorChange={handleEditorChange}
        init={{
          menubar: false,
          toolbar: false,
          statusbar: false,
          body_class: 'eva-richtextarea__body',
          plugins: ALLOWED_PLUGINS,
          autoresize_bottom_margin: 50,
          max_height: maxHeight,
          min_height: minHeight,
          // TODO: Tried to move this into the plugin, and using the content_css, but couldn't find the path to the /styles.css
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:10pt; color: #fff; }',
        }}
      />
    </div>
  );
};
