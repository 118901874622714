import React from 'react';
import cn from 'classnames';
import { Button, ButtonVariant, ButtonSize } from '../../../../Buttons/Button/Button';

export interface ConfirmationRowProps {
  text?: string;
  className?: string;
  onConfirmClick: () => void;
  onCancelClick: () => void;
  positiveConfirmationText?: string;
  negativeConfirmationText?: string;
}

/**
 * This component represent the confirmation section
 * @author Sergio Ruiz <sergio.ruiz@evacenter.com>
 * Created at 2022-11-07
 */
export const ConfirmationRow = ({
  text,
  className,
  onConfirmClick,
  onCancelClick,
  positiveConfirmationText,
  negativeConfirmationText,
}: ConfirmationRowProps) => {
  const classes = cn(className, 'e-inline-flex e-items-center e-space-x-2');

  const handleConfirmationClick = () => {
    if (onConfirmClick) onConfirmClick();
  };

  const handleCancelClick = () => {
    if (onCancelClick) onCancelClick();
  };

  return (
    <div className={classes}>
      <div className="e-text-neutral-50 e-text-xs e-whitespace-nowrap">{text}</div>
      <Button variant={ButtonVariant.ghost} size={ButtonSize.xs} onClick={handleConfirmationClick}>
        {positiveConfirmationText}
      </Button>
      <Button variant={ButtonVariant.ghost} size={ButtonSize.xs} onClick={handleCancelClick}>
        {negativeConfirmationText}
      </Button>
    </div>
  );
};
