export enum DayPickerLocale {
  EN = 'en',
  ES = 'es',
  PT = 'pt',
}

export enum DayPickerMode {
  DEFAULT = 'default',
  SINGLE = 'single',
  MULTIPLE = 'multiple',
  RANGE = 'range',
}
