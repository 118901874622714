import { useMemo, useState, MouseEvent } from 'react';

/**
 * A hook to create a virtual element to be used with the Popover component
 * @param x A number to set the x position of the virtual element
 * @param y A number to set the y position of the virtual element
 * @returns A handler to set the mouse position and a virtual element
 */
export const useVirtualElement = (x: number = 0, y: number = 0) => {
  const [mousePos, setMousePos] = useState([x, y]);

  const virtualElement = useMemo(
    () => ({
      getBoundingClientRect: () => {
        return {
          top: mousePos[1] || 0,
          left: mousePos[0] || 0,
          bottom: mousePos[0],
          right: mousePos[1],
          width: 0,
          height: 0,
        };
      },
    }),
    [mousePos],
  );

  /**
   * A function to handle mouse move events
   * @param e A mouse event
   */
  const handleMouseMove = (e: MouseEvent<HTMLDivElement>) => {
    setMousePos([e.clientX, e.clientY]);
  };

  return {
    virtualElement,
    handleMouseMove,
  };
};
