import React, { forwardRef } from 'react';
import cn from 'classnames';
import { Locale, Translations } from '../../../../common';
import { BaseThumbnail, BaseThumbnailProps } from '../BaseThumbnail/BaseThumbnail';
import { BaseThumbnailSkeleton } from '../BaseThumbnail/BaseThumbnailSkeleton';
import { Icon, IconCatalog } from '../../../Icon/Icon';

const translations: Translations = {
  en: {
    title: 'Key Images',
  },
  es: {
    title: 'Imgs. Clave',
  },
  pt: {
    title: 'Imgs. Chave',
  },
};

interface KeyImageThumbnailProps extends Omit<BaseThumbnailProps, 'title'> {
  /**
   * The locale to be used by the component
   */
  locale?: Locale;

  /**
   * Whether the thumbnail is loading, show a skeleton.
   */
  isLoading?: boolean;
}

/**
 * Represent the thumbnail of a stack of images in the viewer.
 */
export const KeyImageThumbnail = 
forwardRef<HTMLButtonElement, KeyImageThumbnailProps>(
({
  className,
  locale = Locale.es,
  isLoading = false,
  ...restProps
}, ref) => {
  const classes = {
    container: cn(className),
  };

  if (isLoading) return <BaseThumbnailSkeleton hasAnimation />;

  return (
    <BaseThumbnail {...restProps} ref={ref} title={translations[locale].title} className={classes.container}>
      <div className="e-flex e-items-center e-justify-center e-w-full e-mb-1">
        <Icon className="e-text-base-white" icon={IconCatalog.keyImage} width={24} height={24} />
      </div>
    </BaseThumbnail>
  );
})
