import React, {
  ChangeEvent,
  FocusEvent,
  SelectHTMLAttributes,
  useState,
  useRef,
  useEffect,
  useContext,
  RefObject,
} from 'react';
import cn from 'classnames';
import {
  FormFieldState,
  Input,
  NestedSelectOptionType,
  OptionItemType,
  ParentCheckStatus,
  useOnClickOutsideV2,
} from '../../../../common';
import { Icon, IconCatalog } from '../../../Icon/Icon';
import { Spinner, SpinnerSize, SpinnerVariant } from '../../../Loaders';
import { Tag, TagVariant } from '../../../Tag/Tag';
import { Scrollable, ScrollableDirection } from '../../../Scrollable/Scrollable';
import { PopperContext } from '../../../Overlays';
import { useTreeOptions } from '../../../TreeOptions/hooks/useTreeOptionsContext';

const DEFAULT_CLOSE_OPTIONS_TIMEOUT = 100;

export interface InnerMultiSelectProps extends Input, Omit<SelectHTMLAttributes<HTMLInputElement>, 'required'> {
  /**
   * Specify a text to be displayed when separated tags are truncated
   */
  viewAllText?: string;
  /**
   * Callback to execute when the input value changes
   */
  onInputValueChange?: (value: string) => void;
}

/**
 * This represent the MultiSelect input within the NestedMultiSelect component
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2022-10-6
 */
export const InnerMultiSelect = React.forwardRef<HTMLDivElement, InnerMultiSelectProps>(
  ({
    id,
    dataTestId,
    placeholder,
    name,
    autoComplete = 'off',
    fieldState = FormFieldState.default,
    isDisabled = false,
    isRequired = false,
    isFullWidth = false,
    isLoading = false,
    isReadOnly = false,
    label,
    assistiveText,
    className,
    value = '',
    onFocus,
    onBlur,
    onInputValueChange,
    viewAllText = 'View more',
    ...restOfProps
  }) => {
    const [isFocused, setIsFocused] = useState(false);
    const [isShowMoreVisible, setIsShowMoreVisible] = useState(false);
    const isInputActive = fieldState === FormFieldState.active && !isDisabled;
    const { changeParentCheck, changeChildCheck, options, checkParentStatus, showOptions, resetOptions } =
      useTreeOptions();

    // Refs
    const searchBoxRef = useRef<HTMLInputElement | null>(null);
    const searchWrapperRef = useRef<HTMLDivElement | null>(null);
    const optionTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const tagsContainerRef = useRef<HTMLDivElement>(null);

    const { isOpen, closePopper, openPopper, togglePopper, popoverElement, refTriggerNode, menuRef } =
      useContext(PopperContext);

    // Close the multiselect if a click is registered outside the element
    useOnClickOutsideV2([refTriggerNode.current, menuRef], () => {
      onCloseOptionList();
    });

    const handleCloseTagClick = (item: NestedSelectOptionType, type: OptionItemType) => () => {
      if (isDisabled) return;
      if (type === OptionItemType.parent) changeParentCheck(item.value, false);
      if (type === OptionItemType.child) changeChildCheck(item.value, false);
      searchBoxRef.current?.focus();
    };

    const getRendereableTag = (item: NestedSelectOptionType, type: OptionItemType) => ({
      label: item.label,
      element: (
        <Tag
          key={item.value as string}
          className="e-m-0.5 e-flex-initial e-max-w-full"
          variant={TagVariant.neutral}
          onCloseClick={handleCloseTagClick(item, type)}
          // Prevents closing the popover when removing a tag
          onMouseDown={(event) => event?.stopPropagation()}
          hasCloseBtn={!isDisabled}>
          {item.label}
        </Tag>
      ),
    });

    const tagItems = options
      .map((parent) => {
        const status = checkParentStatus(parent);
        switch (status) {
          case ParentCheckStatus.everyChild:
          case ParentCheckStatus.hasNoChild:
            return getRendereableTag(parent, OptionItemType.parent);
          case ParentCheckStatus.someChild:
            return parent.options?.map((child) => {
              if (!child.checked) return null;
              return getRendereableTag(child, OptionItemType.child);
            });
          default:
            return null;
        }
      })
      .flat()
      .filter((tagOption) => Boolean(tagOption));

    const isChipsRenderedMode = isFocused && tagItems.length;
    const isTagsSeparatedRenderedMode = !isFocused && tagItems.length;

    const classes = {
      container: cn(className),
      multiSelectContainer: cn(
        'e-relative e-flex e-items-start e-overflow-hidden e-outline-none',
        'e-bg-neutral-700 e-text-neutral-50 e-border',
        'e-rounded-lg e-p-1.5 e-min-h-10',
        {
          'e-w-full': isFullWidth,
          'e-border-neutral-400': fieldState === FormFieldState.default && !isDisabled,
          'e-border-error-500': fieldState === FormFieldState.error && !isDisabled,
          'e-border-primary-500': (fieldState === FormFieldState.default && isFocused) || isInputActive,
          'e-border-transparent': isDisabled,
          'e-bg-neutral-700': !isDisabled,
          'e-bg-neutral-800': isDisabled,
        },
      ),
      placeholder: cn('e-flex e-items-center e-text-neutral-300 e-font-light e-text-base e-truncate'),
      searchBox: cn({
        'e-max-w-full e-flex-1': isChipsRenderedMode || !isTagsSeparatedRenderedMode,
        'e-w-0 e-flex-none': isTagsSeparatedRenderedMode,
      }),
      searchBoxInput: cn(
        'e-w-full e-ml-1 e-transition e-duration-100 e-ease-out e-outline-none e-bg-transparent',
        'e-text-base e-font-regular placeholder:e-text-neutral-300 placeholder:e-font-light placeholder:e-text-base',
      ),
      endContainer: cn('e-flex-initial e-flex-shrink-0 e-flex e-items-center e-space-x-2 e-pl-1 e-mt-0.5'),
      assistiveText: cn('e-mt-2 e-text-xs e-font-extra-light', {
        'e-text-neutral-200': fieldState === FormFieldState.default,
        'e-text-error-500': fieldState === FormFieldState.error,
      }),
    };

    const handleInputValueChange = (event: ChangeEvent<HTMLInputElement>): void => {
      const { value: newValue } = event.target;
      if (onInputValueChange) onInputValueChange(newValue);
      showOptions(newValue);
      openPopper?.();
    };

    const handleSearchBoxFocus = (event: FocusEvent<HTMLInputElement>): void => {
      if (isOpen && optionTimeoutRef.current) return clearTimeout(optionTimeoutRef.current);

      togglePopper?.();
      setIsFocused(true);

      if (onFocus) onFocus(event);
    };

    const onCloseOptionList = () => {
      if (onInputValueChange) onInputValueChange('');
      setIsFocused(false);
      showOptions('');
      closePopper?.();
    };

    const handleChevronClick = () => {
      if (isFocused) onCloseOptionList();
      if (!isFocused) searchBoxRef.current?.focus();
    };

    const handleSearchBoxBlur = (event: FocusEvent<HTMLInputElement>) => {
      // Prevent blurring the input if a click is registered at popover or multiselect container elements.
      if (popoverElement?.contains(event.relatedTarget) || refTriggerNode.current?.contains(event.target)) return;

      optionTimeoutRef.current = setTimeout(() => onCloseOptionList(), DEFAULT_CLOSE_OPTIONS_TIMEOUT);

      if (onBlur) onBlur(event);
    };

    const handleCleanBtnClick = () => {
      if (isDisabled) return;
      resetOptions();
      setIsShowMoreVisible(false);
      searchBoxRef.current?.focus();
    };

    const renderEndContent = () => {
      if (isLoading) return <Spinner size={SpinnerSize.xs} variant={SpinnerVariant.neutral} />;

      if (isChipsRenderedMode)
        return (
          <Icon
            onMouseDown={(event) => event?.stopPropagation()}
            className="e-cursor-pointer"
            icon={IconCatalog.close}
            onClick={handleCleanBtnClick}
          />
        );

      if (isTagsSeparatedRenderedMode)
        return (
          <div
            onClick={() => searchBoxRef.current?.focus()}
            className={cn('e-py-1 e-px-2 e-text-xs e-text-primary-300 e-font-semi-bold e-cursor-pointer', {
              'e-opacity-0': !isShowMoreVisible,
              'e-opacity-100': isShowMoreVisible,
            })}>
            {viewAllText}
          </div>
        );

      return null;
    };

    const handleIsShowMoreVisible = () => {
      if (!tagsContainerRef.current) return;
      setIsShowMoreVisible(tagsContainerRef?.current.offsetWidth < tagsContainerRef.current.scrollWidth);
    };

    useEffect(() => {
      if (isFocused) return;
      handleIsShowMoreVisible();
    }, [isFocused, tagItems]);

    const renderTagsContainer = () => {
      if (!tagItems.length) return null;
      if (isChipsRenderedMode) return tagItems.map((tagItem) => tagItem?.element);

      return (
        <div
          ref={tagsContainerRef}
          className="e-truncate e-min-w-0 e-flex-grow"
          onClick={() => searchBoxRef.current?.focus()}>
          {tagItems.map((tagItem) => tagItem?.label).join(', ')}
        </div>
      );
    };

    const renderMultiSelectContent = () => {
      const multiSelectContent = (
        <>
          {renderTagsContainer()}
          <div className={classes.searchBox}>
            <div className="e-min-w-8 e-max-w-full e-flex-1">
              <input
                id={id}
                ref={searchBoxRef}
                data-testid={dataTestId}
                name={name}
                value={value}
                className={classes.searchBoxInput}
                disabled={isDisabled}
                readOnly={isReadOnly}
                autoComplete={autoComplete}
                placeholder={tagItems.length === 0 ? placeholder : ''}
                onChange={handleInputValueChange}
                onFocus={handleSearchBoxFocus}
                onBlur={handleSearchBoxBlur}
                {...restOfProps}
              />
            </div>
          </div>
        </>
      );

      if (!isChipsRenderedMode || (!isFocused && !tagItems.length))
        return <div className="e-overflow-x-hidden e-flex e-flex-1">{multiSelectContent}</div>;

      return (
        <Scrollable
          ref={searchWrapperRef}
          direction={ScrollableDirection.Vertical}
          className={cn('e-flex e-flex-1 e-max-h-40 e-flex-wrap e-items-center')}>
          {multiSelectContent}
        </Scrollable>
      );
    };

    const handleManualInputBlur = () => searchBoxRef.current?.focus();

    useEffect(() => {
      window.addEventListener('resize', handleIsShowMoreVisible);
      searchWrapperRef.current?.addEventListener('click', handleManualInputBlur);

      return () => {
        window.removeEventListener('resize', handleIsShowMoreVisible);
        if (optionTimeoutRef.current) clearTimeout(optionTimeoutRef.current);
        searchWrapperRef.current?.removeEventListener('click', handleManualInputBlur);
      };
    }, []);

    return (
      <div className={classes.container}>
        {label && (
          <label className="e-mb-2 e-text-neutral-50 e-text-sm e-font-regular" htmlFor={id}>
            {label}
            {isRequired && '*'}
          </label>
        )}

        <div ref={refTriggerNode as RefObject<HTMLDivElement>} className={classes.multiSelectContainer}>
          {renderMultiSelectContent()}

          <div className={classes.endContainer}>
            {renderEndContent()}

            {/* CHEVRON ICON */}
            <Icon
              onClick={handleChevronClick}
              className="e-cursor-pointer"
              icon={isFocused ? IconCatalog.chevronUp : IconCatalog.chevronDown}
            />
          </div>
        </div>
        {assistiveText && <span className={classes.assistiveText}>{assistiveText}</span>}
      </div>
    );
  },
);
