import React from 'react';
import cn from 'classnames';

import { Transition } from '@headlessui/react';

import { ViewerInformationPatientInfo, ViewerInformationState } from '../ViewerInfoContainerTypes';
import { ViewerInfoText } from '../ViewerInfoText/ViewerInfoText';
import { Icon, IconCatalog } from '../../../Icon/Icon';
import { t } from '../../../Calendar/i18n';
import { CalendarLocale } from '../../../Calendar';
import { useViewerInfoProviderContext } from '../ViewerInfoContainerContext';

export interface ViewerInfoHeaderMobileProps {
  /**
   * The CSS class to apply to the component.
   */
  className?: string;

  /**
   * Whether the patient info is expanded or not.
   */
  isExpanded?: boolean;

  /**
   * The locale to be used.
   */
  locale: CalendarLocale | string;

  /**
   * The patient information.
   */
  patientInfo?: ViewerInformationPatientInfo;

  /**
   * The information state of the component.
   */
  informationState?: ViewerInformationState;

  /**
   * Callback fired when the patient's select is clicked.
   */
  onSelectClick?: () => void;
}

/**
 * This component is used to display the patient on the viewer's header.
 */
export const ViewerInfoHeaderMobile = ({
  locale,
  className,
  patientInfo,
  onSelectClick,
  informationState,
}: ViewerInfoHeaderMobileProps) => {
  const { showPatientInfomation } = useViewerInfoProviderContext();

  if (!showPatientInfomation) return null;

  const classes = {
    header: cn(
      'e-py-4 e-px-5',
      'e-bg-gradient-to-b e-to-transparent e-from-base-black',
      'e-transition-all e-transform e-duration-500 e-ease-in-out',
      className,
    ),
    text: cn('e-text-2xs', {
      'e-text-left': informationState !== ViewerInformationState.hiddenL,
      'e-text-right':
        informationState === ViewerInformationState.rightAligned || informationState === ViewerInformationState.hiddenR,
    }),
    headerSelect: cn(
      'e-flex e-items-center e-gap-2 e-text-neutral-50 e-select-none e-cursor-pointer e-pointer-events-auto',
      {
        'e-justify-start': informationState !== ViewerInformationState.hiddenL,
        'e-justify-end':
          informationState === ViewerInformationState.rightAligned ||
          informationState === ViewerInformationState.hiddenR,
      },
    ),
    headerPatientInfo: cn('e-flex e-flex-col e-gap-1 e-text-xs e-mt-1 e-text-neutral-50 e-font-regular'),
    chevronIcon: cn('e-transition-transform e-grow-0 e-shrink-0', {
      'e-rotate-180':
        informationState !== ViewerInformationState.hiddenL && informationState !== ViewerInformationState.hiddenR,
      '-e-rotate-0':
        informationState === ViewerInformationState.hiddenL || informationState === ViewerInformationState.hiddenR,
    }),
  };

  const textGender = Boolean(patientInfo?.gender?.length) ? `${patientInfo?.gender}` : '';
  const textAge = Boolean(patientInfo?.age?.length) ? `${patientInfo?.age}` : '';
  const textBirthDate = Boolean(patientInfo?.birthDate?.length) ? `(${patientInfo?.birthDate})` : '';
  const textAgeComplete = [textAge, textBirthDate].join(' ');
  const textDescription = textAgeComplete !== ' ' ? [textGender, textAgeComplete].join(', ') : textGender;
  const textStudyName = Boolean(patientInfo?.studyName?.length) ? `${patientInfo?.studyName}` : '';
  const textStudyDate = Boolean(patientInfo?.studyDate?.length) ? `${patientInfo?.studyDate}` : '';
  const textStudyDateFormatted = Boolean(patientInfo?.studyDateFormatted?.length)
    ? ` (${patientInfo?.studyDateFormatted})`
    : '';
  const textDate = `${textStudyDate}${textStudyDateFormatted}`;
  const textFacility = Boolean(patientInfo?.facility?.length) ? `${patientInfo?.facility}` : '';

  const informationStateHidden =
    informationState === ViewerInformationState.hiddenL || informationState === ViewerInformationState.hiddenR;

  const handleSelectClick = () => {
    if (onSelectClick) onSelectClick();
  };

  return (
    <div className={classes.header}>
      {/* Patient name collapse */}
      <div className={classes.headerSelect} onClick={handleSelectClick}>
        <span className="e-font-semi-bold e-text-sm e-line-clamp-1">{patientInfo?.name}</span>
        <Icon className={classes.chevronIcon} icon={IconCatalog.chevronDown} width={24} height={24} />
      </div>

      {/* Patient info expanded */}
      <Transition
        show={!informationStateHidden}
        enter="e-transition-all e-duration-500"
        enterFrom="e-opacity-0"
        enterTo="e-opacity-100"
        leave="e-transition-all e-duration-100"
        leaveFrom="e-opacity-100"
        leaveTo="e-opacity-0">
        <div className={classes.headerPatientInfo}>
          <ViewerInfoText
            className={cn(classes.text, 'e-line-clamp-2')}
            title={`${t('sexAge', locale)}: `}
            info={textDescription}
          />
          <ViewerInfoText
            className={cn(classes.text, 'e-line-clamp-2')}
            title={`${t('study', locale)}: `}
            info={textStudyName}
          />
          <ViewerInfoText
            className={cn(classes.text, 'e-line-clamp-1')}
            title={`${t('capture', locale)}: `}
            info={textDate}
          />
          <ViewerInfoText
            className={cn(classes.text, 'e-line-clamp-1')}
            title={`${t('branch', locale)}: `}
            info={textFacility}
          />
        </div>
      </Transition>
    </div>
  );
};
