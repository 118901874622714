import React, { forwardRef } from 'react';
import cn from 'classnames';
import { BaseThumbnail, BaseThumbnailProps } from '../BaseThumbnail/BaseThumbnail';
import { BaseThumbnailSkeleton } from '../BaseThumbnail/BaseThumbnailSkeleton';

interface PDFThumbnailProps extends BaseThumbnailProps {
  /**
   * Whether the thumbnail is loading, show a skeleton.
   */
  isLoading?: boolean;
}

/**
 * Represent a single image thumbnail in the viewer.
 */
export const PDFThumbnail = 
forwardRef<HTMLButtonElement, PDFThumbnailProps>(
({ className, title, isLoading = false, ...restProps }, ref) => {
  const classes = {
    container: cn(className),
  };

  if (isLoading) return <BaseThumbnailSkeleton hasAnimation />;

  return (
    <BaseThumbnail {...restProps} ref={ref} title={title} className={classes.container}>
      <div className="e-flex e-items-center e-justify-center e-w-full e-mb-4">
        <svg className="e-text-base-white" xmlns="http://www.w3.org/2000/svg" width="18" height="24" fill="none">
          <path
            fill="currentColor"
            d="M4.758 18.397h.666c.179 0 .326-.03.44-.091a.583.583 0 0 0 .253-.25.777.777 0 0 0 .084-.367.766.766 0 0 0-.084-.365.563.563 0 0 0-.255-.245.955.955 0 0 0-.442-.09h-.662v1.408ZM8.881 19.681h-.594v-2.67h.615c.27 0 .496.049.678.148a.941.941 0 0 1 .415.442c.093.196.14.443.14.74 0 .298-.047.547-.14.745a.957.957 0 0 1-.419.447c-.185.099-.417.148-.695.148Z"
          />
          <path
            fillRule="evenodd"
            fill="currentColor"
            d="M1.842.5h12.012L17.5 4.092v18.075c0 .736-.6 1.333-1.342 1.333H1.842A1.338 1.338 0 0 1 .5 22.167V1.833C.5 1.097 1.1.5 1.842.5Zm14.43 3.43-2.868-2.825V3.93h2.868ZM4.035 16.4v3.89h.723v-1.314h.763c.308 0 .567-.055.777-.165.21-.111.37-.264.477-.458.108-.194.162-.415.162-.665 0-.246-.054-.467-.162-.66a1.128 1.128 0 0 0-.473-.46c-.208-.112-.465-.167-.771-.167H4.035Zm3.53 3.89h1.351c.405 0 .752-.077 1.04-.233.29-.156.51-.38.664-.67.155-.292.232-.64.232-1.045 0-.404-.077-.751-.23-1.041a1.58 1.58 0 0 0-.658-.667c-.285-.155-.625-.233-1.02-.233h-1.38v3.89Zm3.973-3.89v3.89h.723v-1.652h1.657v-.59H12.26V16.99h1.832v-.59h-2.555Z"
            clipRule="evenodd"
          />
        </svg>
      </div>
    </BaseThumbnail>
  );
})
