import React, { useEffect, useRef, useState, ClipboardEventHandler, KeyboardEvent, useImperativeHandle } from 'react';
import cn from 'classnames';
import { Key, FormFieldState } from '../../../../common';
import { DigitInput } from '../DigitInput/DigitInput';

export interface MultiDigitInputProps {
  /**
   * Specify an optional test ID to use on e2e tests.
   */
  dataTestId?: string;

  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * Initial value for the MultiDigit Input.
   */
  defaultValue?: Array<string>;

  /**
   * Set the Text Input state
   */
  fieldState?: FormFieldState;

  /**
   * Set an assistive text
   */
  assistiveText?: string;

  /**
   * Set if the digit inputs are loading.
   */
  isLoading?: boolean;

  /**
   * Set if the digit inputs are disabled.
   */
  isDisabled?: boolean;

  /**
   * The handler to get notified when the value changes.
   */
  onChange: (value: string) => void;

  /**
   * The handler to get notified when all digits are filled.
   */
  onFilled?: (value: string) => void;
}

export interface MultiDigitInputHandle {
  /**
   * Sharable method to clear the internal value of the component
   */
  clean: () => void;
}

/**
 * An MultiDigitInput allows the user to interact with and input 4 digits verification code or birthday date.
 * @author Sergio Ruiz<sergio.ruiz@evacenter.com>
 * Created at 2024-06-18
 */
export const MultiDigitInput = React.forwardRef<MultiDigitInputHandle, MultiDigitInputProps>(
  (
    {
      isLoading = false,
      isDisabled = false,
      className,
      dataTestId,
      assistiveText,
      fieldState = FormFieldState.default,
      defaultValue = [],
      onChange,
      onFilled,
    },
    ref,
  ) => {
    const INPUTS_AMOUNT = 4;
    const classes = {
      container: cn('e-inline-flex e-flex-col', className),
      assistiveText: cn('e-mt-4 e-text-sm e-text-center', {
        'e-text-neutral-200': fieldState === FormFieldState.default,
        'e-text-error-500': fieldState === FormFieldState.error,
      }),
    };
    const inputsRef = useRef<Array<HTMLInputElement>>([]);
    const [value, setValue] = useState(defaultValue);

    // Expose methods to the parent component
    useImperativeHandle(ref, () => ({
      clean: () => {
        setValue([]);
        onChange('');
        if (inputsRef.current[0]) {
          inputsRef.current[0].focus();
          inputsRef.current[0].select();
        }
      },
    }));

    useEffect(() => {
      if (inputsRef.current[0]) inputsRef.current[0]?.focus();
    }, []);

    const handleOnChange = (index: number) => (digit: string) => {
      const LAST_INDEX = INPUTS_AMOUNT - 1;
      const newValueArray = [...value];
      newValueArray[index] = digit;
      setValue(newValueArray);

      // Just emit the value when all digits are filled
      const newValue = newValueArray.join('');
      if (newValue.length === INPUTS_AMOUNT) {
        onChange(newValue);
        if (onFilled) onFilled(newValue);
        inputsRef.current[index]?.blur();
      } else {
        onChange(newValue);
      }

      // Focus next input if digit is filled
      if (digit && index < LAST_INDEX) inputsRef.current[index + 1]?.focus();
    };

    const handleKeyDown = (index: number) => (event: KeyboardEvent) => {
      if (event.key === Key.Backspace && !value[index] && index > 0) {
        inputsRef.current[index - 1]?.focus();
      }
    };

    const handleFocus = (index: number) => () => inputsRef.current[index]?.select();

    const handlePaste: ClipboardEventHandler<HTMLDivElement> = (event) => {
      event.preventDefault();
      const newValueArray = [...value];
      const pastedData = event.clipboardData?.getData('text');
      if (pastedData) {
        // Remove non-digit characters
        const sanitizedData = pastedData.replace(/\D/g, '');
        for (let index = 0; index < INPUTS_AMOUNT && index < sanitizedData.length; index++) {
          newValueArray[index] = sanitizedData.charAt(index);
          if (newValueArray && index < INPUTS_AMOUNT - 1) inputsRef.current[index + 1]?.focus();
        }

        const newValue = newValueArray.join('');
        setValue(newValueArray);
        onChange(newValue);

        // Emit the value when all digits are filled
        if (newValueArray.length === INPUTS_AMOUNT) {
          if (onFilled) onFilled(newValue);
        }
      }
    };

    /* Render JSX */
    return (
      <div className={classes.container} data-testid={dataTestId} onPaste={handlePaste}>
        <div className="e-flex e-gap-2">
          {Array.from({ length: INPUTS_AMOUNT }).map((_, index) => (
            <DigitInput
              key={index}
              ref={(input) => {
                inputsRef.current[index] = input as HTMLInputElement;
              }}
              value={value[index] || ''}
              onChange={handleOnChange(index)}
              onKeyDown={handleKeyDown(index)}
              onFocus={handleFocus(index)}
              maxLength={1}
              isLoading={isLoading}
              fieldState={fieldState}
              isDisabled={isDisabled}
            />
          ))}
        </div>
        {assistiveText && <span className={classes.assistiveText}>{assistiveText}</span>}
      </div>
    );
  },
);
