import React, { Fragment, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { Transition } from '@headlessui/react';
import cn from 'classnames';

interface BottomSheetRootProps {
  /**
   * Optional classname prop to include classNames to the modal's root element
   */
  className?: string;

  /**
   * Elements to display inside the BottomSheetRoot.
   */
  children: ReactNode;
}

/**
 * `BottomSheetRoot` represents the main root where bottom sheets will be rendered.
 */
export const BottomSheetRoot = ({ children, className }: BottomSheetRootProps) => {
  const classes = {
    root: cn(
      'e-fixed e-top-0 e-left-0 e-bottom-0 e-right-0 e-z-30 e-overflow-x-hidden e-overflow-y-auto e-outline-0 e-transition-opacity',
      className,
    ),
    container: cn('e-flex e-absolute e-bottom-0 e-max-h-[90%] e-w-full'),
    overlay: cn('e-fixed e-inset-0 e-bg-neutral-500/75 e-transition-opacity'),
  };

  const bottomSheetRoot = (
    <div className={classes.root} aria-labelledby="bottom-sheet-title" role="dialog" aria-modal="true">
      {/* OVERLAY */}
      <div className={classes.overlay} aria-hidden="true"></div>

      {/* BOTTOM CONTAINER */}
      <Transition
        as={Fragment}
        appear
        show
        enter="e-transition-all e-ease-in-out e-duration-500"
        enterFrom="e-translate-y-full"
        enterTo="e-translate-y-0"
        leave="e-transition-all e-ease-in-out"
        leaveFrom="e-translate-y-0"
        leaveTo="e-translate-y-full">
        <div className={classes.container}>{children}</div>
      </Transition>
    </div>
  );

  return ReactDOM.createPortal(bottomSheetRoot, document.body);
};
