import React, { useEffect, useMemo, useState } from 'react';
import { CalendarProps as BigCalendarProps } from 'react-big-calendar';
import { withDragAndDropProps } from 'react-big-calendar/lib/addons/dragAndDrop';
import { Toolbar } from './components/Toolbar';
import { CalView } from './components/View';
import { useDefaultView } from './hooks/useDefaultView';
import { CalendarContext } from './hooks/useCalendarContext';
import { CalendarLocale, CalendarView, DateRange, Event, Resource } from './types';
import { DEFAULT_CALENDAR_VIEWS, DEFAULT_TIME_STEP } from './constants';
import { getRange } from './utils/helpers';

export interface CalendarProps {
  /**
   * The default date to display in the calendar.
   */
  currentDate?: Date;
  /**
   * The content displayed inside the calendar context.
   */
  children?: React.ReactNode;
  /**
   * The start view to display in the calendar.
   */
  defaultView?: CalendarView;
  /**
   * The current view of the calendar.
   */
  view?: CalendarView;
  /**
   * The views availables in the calendar
   */
  views?: Array<CalendarView>;
  /**
   * The locale to use in the calendar.
   */
  locale?: CalendarLocale;
  /**
   * Events to display in the calendar.
   */
  events?: Array<Event>;
  /**
   * The resources to display in the calendar on `day` view.
   */
  resources?: Array<Resource>;
  /**
   * Determines the selectable time increments in week and day views
   */
  step?: BigCalendarProps<Event>['step'];
  /**
   * Function to handle the change of the current date.
   */
  onChangeDate?: (date: Date | null, range: DateRange) => void;
  /**
   * Function to handle the change of the current view.
   */
  onViewChange?: (view: CalendarView) => void;
  /**
   * Function to handle when a event is moved.
   */
  onMoveEvent?: withDragAndDropProps<Event>['onEventDrop'];
  /**
   * Function to handle when a event is resized.
   */
  onResizeEvent?: withDragAndDropProps<Event>['onEventResize'];
  /**
   * Function to handle when a event is selected.
   */
  onSelectEvent?: (event: Event, e: React.SyntheticEvent) => void;
  /**
   * Function to handle when a slot is selected (only for month view).
   */
  onSelectSlot?: BigCalendarProps<Event>['onSelectSlot'];
  /**
   * Function to handle when a slot is selected (only for time views).
   */
  onSelecting?: BigCalendarProps<Event>['onSelecting'];
}

/**
 * A calendar is a component viewer to display a list of events.
 * @author Javier Diaz<javier.diaz@evacenter.com>
 */
const EvaCalendar = ({
  children,
  currentDate,
  defaultView = CalendarView.WEEK,
  events = [],
  step = DEFAULT_TIME_STEP,
  resources = [],
  view,
  views = DEFAULT_CALENDAR_VIEWS,
  locale = CalendarLocale.ES,
  onChangeDate,
  onViewChange,
  onMoveEvent,
  onResizeEvent,
  onSelectEvent,
  onSelectSlot,
  onSelecting,
}: CalendarProps) => {
  if (!views || views.length === 0) throw new Error('Calendar: Views cannot be empty, please define at least one view');

  // Inmutable views
  const staticViews = useMemo(() => views, []);

  const currentDefaultView = useDefaultView(staticViews, defaultView);
  const [date, setDate] = useState<Date>(currentDate || new Date());
  const [internalView, changeInternalView] = useState<CalendarView>(currentDefaultView);

  useEffect(() => {
    if (currentDate) setDate(currentDate);
  }, [currentDate]);

  useEffect(() => {
    if (!view) return;

    const viewExists = staticViews.includes(view);
    if (viewExists) changeInternalView(view);
  }, [view]);

  /**
   * A function to handle when the date is changed
   * @param date A new date to set in the calendar
   */
  const handleChangeDate = (date: Date) => {
    setDate(date);
    if (onChangeDate) onChangeDate(date, getRange(date, internalView));
  };

  /**
   * A function to handle when the view is changed
   * @param value The new view selected
   */
  const handleChangeView = (value: CalendarView) => {
    changeInternalView(value);
    if (onViewChange) onViewChange(value);
  };

  /**
   * A function to handle when a event was moved from one slot to another
   * @param args Properties of the event moved ex. event, start, end
   */
  const handleOnMoveEvent: withDragAndDropProps<Event>['onEventDrop'] = (args) => {
    if (onMoveEvent) onMoveEvent(args);
  };

  /**
   * A fuction to handle when a event was resized
   * @param args Properties of the event resized ex. event, start, end
   */
  const handleOnResizeEvent: withDragAndDropProps<Event>['onEventResize'] = (args) => {
    if (onResizeEvent) onResizeEvent(args);
  };

  /**
   * A function to handle when a event was selected
   * @param event The info of the event selected on calendar
   * @param eventEmitted The event object emitted from the virtual dom
   */
  const handleOnSelectEvent: BigCalendarProps<Event>['onSelectEvent'] = (
    event: Event,
    eventEmitted: React.SyntheticEvent,
  ) => {
    if (onSelectEvent) onSelectEvent(event, eventEmitted);
  };

  /**
   * A function to handle when a slot was selected
   * @param slotInfo The info of the slot selected on calendar
   */
  const handleOnSelectSlot: BigCalendarProps<Event>['onSelectSlot'] = (slotInfo) => {
    if (onSelectSlot) onSelectSlot(slotInfo);
  };

  /**
   * A function to handle when multiples slot was selected
   * @param range A range date from the slots selected on calendar
   */
  const handleOnSelecting: BigCalendarProps<Event>['onSelecting'] = (range) => {
    if (onSelecting) return onSelecting(range);
  };

  const contextValues = useMemo(
    () => ({
      defaultView,
      date,
      locale,
      events,
      step,
      view: internalView,
      views: staticViews,
      resources,
      handleChangeView,
      handleChangeDate,
      handleOnMoveEvent,
      handleOnResizeEvent,
      handleOnSelectEvent,
      handleOnSelectSlot,
      handleOnSelecting,
    }),
    [resources, date, locale, internalView, events],
  );

  return (
    <CalendarContext.Provider value={contextValues}>
      <div className="e-flex e-flex-col e-h-full e-w-full">{children}</div>
    </CalendarContext.Provider>
  );
};

export const Calendar = Object.assign(EvaCalendar, { Toolbar, View: CalView });
