export const isValidDate = (dateString: string) => {
  const regex = /^\d{2}\/\d{2}\/\d{4}$/;

  if (!regex.test(dateString)) return false;

  const [day, month, year] = dateString.split('/').map(Number);
  const date = new Date(year, month - 1, day);

  // Check if the format date is valid (DD/MM/YYYY)
  return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
};
