import React from 'react';
import cn from 'classnames';
import { Scrollable } from '../../../Scrollable/Scrollable';

export interface BottomSheetBodyProps {
  /**
   * Elements to display inside the Modal.
   */
  children: React.ReactNode;

  /**
   * Specify an optional className to be added to the body section.
   */
  className?: string;

  /**
   * Flag to turn on/off the scrollable body content
   */
  isBodyScrollable?: boolean;
}

/**
 * `BottomSheetBody` represents the body section of a bottom sheet
 * @author Sergio Ruiz <sergioruizdavila@gmail.com>
 * Created at 2024-02-12
 */
export const BottomSheetBody = ({ className, isBodyScrollable = true, children }: BottomSheetBodyProps) => {
  const classes = {
    body: cn('e-px-5 e-relative e-flex-auto', className),
  };

  if (isBodyScrollable) return <Scrollable className={classes.body}>{children}</Scrollable>;

  return <div className={classes.body}>{children}</div>;
};
