import React from 'react';
import cn from 'classnames';
import type { Event } from '../../types';

type PopupContentProps = {
  /**
   * The className of the popup content.
   */
  className?: string;
  /**
   * The event to display.
   */
  event: Event;
};

export const PopupContent = ({ className, event }: PopupContentProps) => {
  return (
    <div
      className={cn('e-border e-border-neutral-400 e-text-neutral-300 e-bg-neutral-600 e-rounded-lg e-p-2', className)}>
      <div className="flex flex-col">
        <span className="font-semibold">{event.title}</span>
      </div>
    </div>
  );
};
