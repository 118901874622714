import React from 'react';
import cn from 'classnames';
import { useBodyClass } from '../../../../common';
import { BottomSheetBody } from './BottomSheetBody';
import { BottomSheetHeader } from './BottomSheetHeader';
import { BottomSheetFooter } from './BottomSheetFooter';

export interface BottomSheetContentProps {
  /**
   * Specify an optional className to be added to the body section.
   */
  className?: string;

  /**
   * The children of the modal content.
   */
  children?: React.ReactNode;
}

/**
 * `BottomSheetContent` is the main container where the different sections of the bottom sheet must be used
 * @author Sergio Ruiz <sergioruizdavila@gmail.com>
 * Created at 2024-02-12
 */
export const BottomSheetContent = ({ className, children }: BottomSheetContentProps) => {
  useBodyClass('e-opened-bottom-sheet');

  const classes = {
    content: cn(
      'e-bg-neutral-900',
      'e-rounded-t-2xl',
      'e-overflow-hidden',
      'e-relative e-flex e-flex-col e-w-full e-pointer-events-auto e-bg-clip-padding e-outline-0',
      'e-w-full',
      className,
    ),
  };

  return <div className={classes.content}>{children}</div>;
};

BottomSheetContent.Header = BottomSheetHeader;
BottomSheetContent.Body = BottomSheetBody;
BottomSheetContent.Footer = BottomSheetFooter;
