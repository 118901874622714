import React from 'react';
import { IconCatalog } from '../../Icon/Icon';
import {ContactButton, ContactButtonProps} from '../ContactButton/ContactButton';

/**
 * This is a wrapper around the ContactButton component that sets the icon for the phone button
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2023-08-01
 */
export const PhoneContactButton = ({
  ...restOfProps
}: ContactButtonProps) => <ContactButton {...restOfProps} icon={IconCatalog.phone} />;
  
