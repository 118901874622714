import { subDays } from 'date-fns';
import { Preset } from '../PresetDateOptions/PresetDateOptions';
import { Locale, Translations } from '../../../../common';

const translations: Translations = {
  en: {
    today: 'Today',
    yesterday: 'Yesterday',
    last7Days: 'Last 7 days',
    last15Days: 'Last 15 days',
    last30Days: 'Last 30 days',
    thisWeek: 'This week',
    thisMonth: 'This month',
    lastMonth: 'Last month',
  },
  es: {
    today: 'Hoy',
    yesterday: 'Ayer',
    last7Days: 'Últimos 7 días',
    last15Days: 'Últimos 15 días',
    last30Days: 'Últimos 30 días',
    thisWeek: 'Esta semana',
    thisMonth: 'Este mes',
    lastMonth: 'Mes pasado',
  },
  pt: {
    today: 'Hoje',
    yesterday: 'Ontem',
    last7Days: 'Últimos 7 dias',
    last15Days: 'Últimos 15 dias',
    last30Days: 'Últimos 30 dias',
    thisWeek: 'Esta semana',
    thisMonth: 'Este mês',
    lastMonth: 'Mês passado',
  },
};

export const getPresetOptions = (locale: Locale): Array<Preset> => {
  return [
    { label: translations[locale].today, range: [new Date(), null] },
    { label: translations[locale].yesterday, range: [subDays(new Date(), 1), null] },
    { label: translations[locale].last7Days, range: [subDays(new Date(), 7), new Date()] },
    { label: translations[locale].last15Days, range: [subDays(new Date(), 15), new Date()] },
    { label: translations[locale].last30Days, range: [subDays(new Date(), 30), new Date()] },
    {
      label: translations[locale].thisWeek,
      range: [
        new Date(
          new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate() - ((new Date().getDay() + 6) % 7),
          ),
        ),
        new Date(),
      ],
    },
    {
      label: translations[locale].thisMonth,
      range: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()],
    },
    {
      label: translations[locale].lastMonth,
      range: [
        new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
        new Date(new Date().getFullYear(), new Date().getMonth(), 0),
      ],
    },
  ];
};
