import React from 'react';
import cn from 'classnames';

import { ViewerInformationState } from '../ViewerInfoContainerTypes';
import { ViewerInfoText } from '../ViewerInfoText/ViewerInfoText';
import { twMerge } from '../../../../common';
import { useViewerInfoProviderContext } from '../ViewerInfoContainerContext';

// TODO: Replace this with the actual serie data structure.
export type SerieInfo = {
  serieId: string;
  imageStackInfo: string;
  sizes: string;
  loc: string;
  thickness: string;
  zoom: string;
  w: string;
  l: string;
  compression: string;
};

export interface ViewerInfoFooterProps {
  /**
   * Whether the component is being rendered on a mobile device or not.
   */
  isMobile?: boolean;

  /**
   * The width of the parent container.
   */
  parentWidth?: number;

  /**
   * The CSS class to apply to the component.
   */
  className?: string;

  /**
   * The serie information.
   */
  serieInfo?: SerieInfo;

  /**
   * The information state of the component.
   */
  informationState?: ViewerInformationState;
}

/**
 * This component is used to display the series information on the viewer's footer.
 */
export const ViewerInfoFooter = ({
  className,
  serieInfo,
  informationState,
  isMobile,
  parentWidth,
}: ViewerInfoFooterProps) => {
  const {
    showRightSerieInformation,
    showLeftSerieInformation,
    showImageDimensionInBottomRigth,
    classes: classesExtension,
  } = useViewerInfoProviderContext();

  const informationStateHidden =
    informationState === ViewerInformationState.hiddenL || informationState === ViewerInformationState.hiddenR;

  const classes = {
    text: twMerge(
      cn(
        'e-flex e-gap-1',
        {
          'e-text-2xs e-text-base-white': isMobile,
          'e-text-viewer e-text-base-white e-text-neutral-400': !isMobile,
        },
        classesExtension?.footer?.text,
      ),
    ),
    title: twMerge(
      cn(
        {
          'e-font-bold': isMobile,
          'e-font-medium': !isMobile,
        },
        classesExtension?.footer?.title,
      ),
    ),

    footer: twMerge(
      cn(
        'e-p-5 e-flex e-justify-between e-w-full e-transition-opacity e-duration-300',
        className,
        {
          'e-opacity-0': informationStateHidden,
          'e-opacity-100': !informationStateHidden,
        },
        classesExtension?.footer?.footerContainer,
      ),
    ),
  };

  const textSerieId = Boolean(serieInfo?.serieId?.length) ? `${serieInfo?.serieId}` : '';
  const textImageStackInfo = Boolean(serieInfo?.imageStackInfo?.length) ? `${serieInfo?.imageStackInfo}` : '';
  const textSizes = Boolean(serieInfo?.sizes?.length) ? `${serieInfo?.sizes}` : '';
  const textLoc = Boolean(serieInfo?.loc?.length) ? `${serieInfo?.loc} mm` : '';
  const textThickness = Boolean(serieInfo?.thickness?.length) ? `${serieInfo?.thickness} mm` : '';
  const textZoom = Boolean(serieInfo?.zoom?.length) ? `${serieInfo?.zoom}%` : '';
  const textW = Boolean(serieInfo?.w?.length) ? `${serieInfo?.w}` : '';
  const textL = Boolean(serieInfo?.l?.length) ? `${serieInfo?.l}` : '';
  const textCompression = Boolean(serieInfo?.compression?.length) ? `${serieInfo?.compression}` : '';

  return (
    <div className={classes.footer}>
      {/* Left side serie information */}
      {showLeftSerieInformation && (
        <div className="e-flex e-flex-col e-gap-1 e-text-neutral-50 e-text-2xs">
          <ViewerInfoText
            parentWidth={parentWidth}
            className={classes.text}
            title="Ser:"
            titleClassName={classes.title}
            info={textSerieId}
          />
          <ViewerInfoText
            parentWidth={parentWidth}
            className={classes.text}
            title="Ima:"
            titleClassName={classes.title}
            info={textImageStackInfo}
          />
          <ViewerInfoText parentWidth={parentWidth} className={classes.text} info={textSizes} />
          <div className="e-flex e-flex-wrap e-items-center e-gap-1">
            <ViewerInfoText
              parentWidth={parentWidth}
              className={cn(classes.text, 'e-whitespace-nowrap')}
              title="Loc:"
              titleClassName={classes.title}
              info={textLoc}
            />
            <ViewerInfoText
              parentWidth={parentWidth}
              className={cn(classes.text, 'e-whitespace-nowrap')}
              title="Thick:"
              titleClassName={classes.title}
              info={textThickness}
            />
          </div>
        </div>
      )}

      {/* Right side serie information */}
      {showRightSerieInformation && (
        <div className="e-flex e-flex-col e-items-end e-gap-1 e-text-neutral-50 e-text-2xs e-ml-auto">
          {showImageDimensionInBottomRigth && (
            <ViewerInfoText
              parentWidth={parentWidth}
              className={classes.text}
              title=""
              titleClassName={classes.title}
              info={textImageStackInfo}
            />
          )}
          <ViewerInfoText
            parentWidth={parentWidth}
            className={classes.text}
            title="Zoom:"
            titleClassName={classes.title}
            info={textZoom}
          />
          <div className="e-flex e-items-center e-gap-1">
            <ViewerInfoText
              parentWidth={parentWidth}
              className={classes.text}
              title="W:"
              titleClassName={classes.title}
              info={textW}
            />
            <ViewerInfoText
              parentWidth={parentWidth}
              className={classes.text}
              title="L:"
              titleClassName={classes.title}
              info={textL}
            />
          </div>
          <ViewerInfoText
            parentWidth={parentWidth}
            className={cn(classes.text, 'e-text-right')}
            info={textCompression}
          />
        </div>
      )}
    </div>
  );
};
