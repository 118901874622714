import React, { forwardRef } from 'react';
import cn from 'classnames';
import { Icon, IconCatalog } from '../../../Icon/Icon';
import { TextInputProps } from '../../TextInput/TextInput';
import { FormFieldState } from '../../../../common/constants';

export interface RangePickerSelectProps
  extends Omit<TextInputProps, 'onEndIconClick' | 'onChange' | 'value' | 'disabled'> {
  /**
   * The value of the button
   */
  value?: string;

  /**
   * Whether the button is active or not
   */
  isActive?: boolean;
}

/**
 * This component represents a Text Input component for the RangeDatePicker
 */
export const RangePickerSelect = forwardRef<HTMLDivElement, RangePickerSelectProps>(
  (
    {
      id,
      value,
      placeholder = 'Selecciona una fecha o rango',
      onClick,
      isDisabled,
      className,
      isFullWidth,
      isRounded,
      isRequired,
      isFlat,
      isActive = false,
      fieldState = FormFieldState.default,
      borderless,
      label,
      ...restOfProps
    },
    ref,
  ) => {
    const classes = {
      container: cn({ 'e-w-full': isFullWidth }, className),
      buttonContainer: cn('e-relative e-flex e-items-center e-overflow-hidden e-space-x-2', 'e-h-10', {
        'e-w-full': isFullWidth,
        'e-rounded-lg e-p-2': !isRounded,
        'e-rounded-full e-px-4 e-py-2': isRounded,
        'e-border-neutral-400': fieldState === FormFieldState.default && !isDisabled,
        'e-border-error-500': fieldState === FormFieldState.error && !isDisabled,
        'e-border-primary-500': fieldState === FormFieldState.active || isActive,
        'e-border e-bg-neutral-700': !borderless && !isDisabled,
        'e-border-0 e-bg-neutral-700': isFlat,
        'e-text-neutral-50': !isDisabled,
        'e-text-neutral-300 e-bg-neutral-800 e-border-transparent e-cursor-default': isDisabled,
      }),
      valueContainer: cn('e-w-full'),
      value: cn('e-inline-flex e-items-center e-gap-2'),
      placeholder: cn(
        'e-w-full',
        'e-transition e-duration-100 e-ease-out e-outline-none e-bg-transparent',
        'placeholder:e-text-neutral-300 e-text-base e-font-light',
      ),
      icon: cn('e-flex e-items-center e-justify-center', {
        'e-cursor-default': isDisabled,
      }),
    };

    const handleClick = (event: any) => {
      if (isDisabled) return;
      if (onClick) onClick(event);
    };

    const renderContent = () => {
      if (value) {
        const [start, end] = value.split(' - ');

        return (
          <div className={classes.valueContainer}>
            <div className={classes.value}>
              <div className="e-flex-nowrap">{start}</div>
              {end && (
                <Icon className="e-text-neutral-300 e-shrink-0" icon={IconCatalog.arrowRight} width={20} height={20} />
              )}
              <div className="e-flex-nowrap">{end}</div>
            </div>
          </div>
        );
      }
      return <div className={classes.placeholder}>{placeholder}</div>;
    };

    return (
      <div className={classes.container}>
        {label && (
          <label className="e-mb-2 e-text-neutral-50 e-text-sm e-font-regular" htmlFor={id}>
            {label}
            {isRequired && '*'}
          </label>
        )}
        <div
          ref={ref}
          role="button"
          tabIndex={0}
          placeholder={placeholder}
          className={classes.buttonContainer}
          aria-disabled={isDisabled}
          aria-haspopup="menu"
          onClick={handleClick}
          {...restOfProps}>
          {/* PLACEHOLDER AND VALUE */}
          {renderContent()}
          <div className={classes.icon}>
            {/* CALENDAR ICON */}
            <Icon icon={IconCatalog.calendar} />
          </div>
        </div>
      </div>
    );
  },
);
