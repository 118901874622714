import React, { forwardRef, FocusEvent, useRef, useState, useEffect } from 'react';
import DatePicker, { CalendarContainer, registerLocale } from 'react-datepicker';
import { TextInputProps } from '../TextInput/TextInput';
import cn from 'classnames';
import es from 'date-fns/locale/es';
import pt from 'date-fns/locale/pt';
import { isSameDay } from 'date-fns';
import './RangeDatePickerInput.css';
import { RANGE_DATE_FORMAT } from './constants';
import { Locale, FormFieldState } from '../../../common';
import { PresetDateOptions } from './PresetDateOptions/PresetDateOptions';
import { RangeDatePickerHeader } from './RangeDatePickerHeader/RangeDatePickerHeader';
import { RangePickerSelect } from './RangePickerSelect/RangePickerSelect';

registerLocale(Locale.es, es);
registerLocale(Locale.pt, pt);

export interface RangeDatePickerInputProps extends Omit<TextInputProps, 'onChange' | 'value'> {
  /**
   * Start date of the range.
   */
  startDateValue: Date | null;

  /**
   * End date of the range.
   */
  endDateValue: Date | null;

  /**
   * Set a minimum Year on the Year Select Dropdown
   */
  minYear?: number;

  /**
   * Set a maximum Year on the Year Select Dropdown
   */
  maxYear?: number;

  /**
   * Set a custmo date format. Default is 'MMM d, yyyy'
   */
  dateFormat?: string;

  /**
   * Sets the className for the Popper component, the very first container of the DatePicker.
   */
  popperClassName?: string;

  /**
   * Set the locale to be used by the component
   */
  locale?: Locale;

  /**
   * Provide a handler that is called when the input changes.
   */
  onChange?: (startDate: Date | null, endDate: Date | null) => void;

  /**
   * Provide a handler that is called when the close button was clicked.
   */
  onCloseClick?: () => void;
}

/**
 * A Range Date Picker is a text input to capture a range of dates.
 */
export const RangeDatePickerInput = forwardRef<HTMLInputElement, RangeDatePickerInputProps>(
  (
    {
      className,
      placeholder,
      startDateValue,
      endDateValue,
      minYear = 1922,
      maxYear,
      dateFormat = RANGE_DATE_FORMAT,
      onChange,
      onBlur,
      onFocus,
      onCloseClick,
      popperClassName,
      isDisabled,
      isFullWidth = false,
      locale = Locale.es,
      assistiveText,
      ...restOfProps
    },
    ref,
  ) => {
    const classes = {
      popperClassName: cn(popperClassName, 'e-z-1'),
      assistiveText: cn('e-mt-2 e-text-xs e-font-extra-light', {
        'e-text-error-500': restOfProps.fieldState === FormFieldState.error,
        'e-text-neutral-200': restOfProps.fieldState !== FormFieldState.error,
      }),
    };

    const checkIfSameDay = (date1: Date | null, date2: Date | null) => {
      if (!date1 || !date2) return false;
      return isSameDay(date1, date2);
    };

    const [startDate, setStartDate] = useState<Date | null>(startDateValue);

    // NOTE: Don't use setEndDate, use setParsedEndDate instead
    const [endDate, setEndDate] = useState<Date | null>(
      checkIfSameDay(startDateValue, endDateValue) ? null : endDateValue,
    );

    const [isSelectActive, setIsSelectActive] = useState(false);
    const datePickerRef = useRef(null);

    // If both dates are the same, set null to the end date because it's not a range
    const setParsedEndDate = (newStartDate: Date | null, newEndDate: Date | null) => {
      let parsedEndDate = newEndDate;
      if (checkIfSameDay(newStartDate, newEndDate)) parsedEndDate = null;
      setEndDate(parsedEndDate);
    };

    // Update the state when the props change
    useEffect(() => {
      if (startDateValue !== startDate) setStartDate(startDateValue);
      if (endDateValue !== endDate) setParsedEndDate(startDateValue, endDateValue);
    }, [startDateValue, endDateValue]);

    const changeRange = (newStartDate: Date | null, newEndDate: Date | null) => {
      setStartDate(newStartDate);
      setParsedEndDate(newStartDate, newEndDate);
    };

    const closePopover = () => {
      if (!datePickerRef.current) return;
      (datePickerRef as any).current.setOpen(false);
    };

    const handleDateChange = ([newStartDate, newEndDate]: [Date | null, Date | null]) => {
      changeRange(newStartDate, newEndDate);
      if (onChange) onChange(newStartDate, newEndDate);
    };

    const handleStartDateInputChange = (date: Date | null) => {
      setStartDate(date);
      if (onChange) onChange(date, endDate);
    };
    const handleEndDateInputChange = (date: Date | null) => {
      setParsedEndDate(startDate, date);
      if (onChange) onChange(startDate, date);
    };

    const handlePresetOptionClick = ([newStartDate, newEndDate]: [Date | null, Date | null]) => {
      changeRange(newStartDate, newEndDate);
      if (onChange) onChange(newStartDate, newEndDate);
      closePopover();
    };

    const handleInputBlur = (event: FocusEvent<HTMLInputElement>): void => {
      if (onBlur) onBlur(event);
    };

    const handleInputFocus = (event: FocusEvent<HTMLInputElement>): void => {
      if (onFocus) onFocus(event);
    };

    /*
     * This is a custom container for the calendar, it will wrap the calendar and the PresetDateOptions component
     */
    const Container = ({ className, children }: any) => {
      const classes = {
        root: cn('eden-range-datepicker e-flex e-flex-col e-bg-neutral-700 e-rounded-lg e-shadow-lg'),
        container: cn(className),
      };

      return (
        <div className={classes.root}>
          <div className="e-flex e-gap-3 e-p-3">
            <PresetDateOptions
              locale={locale}
              startDateValue={startDate}
              endDateValue={endDate}
              onOptionClick={handlePresetOptionClick}
            />
            <CalendarContainer className={classes.container}>
              <RangeDatePickerHeader
                className="e-mb-4"
                startDateValue={startDate}
                endDateValue={endDate}
                onChangeStartDate={handleStartDateInputChange}
                onChangeEndDate={handleEndDateInputChange}
              />
              {children}
            </CalendarContainer>
          </div>
        </div>
      );
    };

    /* Render JSX
     * NOTE: The custom input is just a dumb component, every TextInput prop should be on the DatePicker component
     */
    return (
      <>
        <DatePicker
          ref={datePickerRef}
          selected={startDate}
          startDate={startDate}
          endDate={endDate}
          monthsShown={2}
          onChange={handleDateChange}
          onBlur={handleInputBlur}
          onFocus={handleInputFocus}
          selectsRange
          locale={locale}
          dateFormat={dateFormat}
          popperClassName={classes.popperClassName}
          placeholderText={placeholder}
          disabled={isDisabled}
          customInput={
            <RangePickerSelect {...restOfProps} isActive={isSelectActive} isDisabled={isDisabled} ref={ref} />
          }
          calendarContainer={Container}
          showPopperArrow={false}
          portalId="root-portal"
          disabledKeyboardNavigation
          shouldCloseOnSelect={false}
          strictParsing={true}
          onCalendarOpen={() => setIsSelectActive(true)}
          onCalendarClose={() => setIsSelectActive(false)}
        />
        {assistiveText && <span className={classes.assistiveText}>{assistiveText}</span>}
      </>
    );
  },
);
