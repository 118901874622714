import React, { FocusEvent, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { TextInput, TextInputProps } from '../TextInput/TextInput';
import { IconCatalog } from '../../Icon/Icon';
import { DatePickerHeader } from './DatePickerHeader/DatePickerHeader';
import { DateTimePicker } from './DateTimePicker/DateTimePicker';
import cn from 'classnames';
import es from 'date-fns/locale/es';
import pt from 'date-fns/locale/pt';
import './DatePickerInput.css';
import { DEFAULT_DATE_TIME_FORMAT, DEFAULT_DATE_FORMAT } from './constants';
import { Translations, Locale, FormFieldState } from '../../../common';

registerLocale(Locale.es, es);
registerLocale(Locale.pt, pt);

const translations: Translations = {
  en: {
    timeLabel: 'Time',
  },
  es: {
    timeLabel: 'Hora',
  },
  pt: {
    timeLabel: 'Hora',
  },
};

export interface DatePickerInputProps extends Omit<TextInputProps, 'onChange' | 'value'> {
  /**
   * Value of input.
   */
  value?: Date | null;

  /**
   * Set a minimum Year on the Year Select Dropdown
   */
  minYear?: number;

  /**
   * Set a maximum Year on the Year Select Dropdown
   */
  maxYear?: number;

  /**
   * Set a custmo date format. Default is 'dd/MM/yyyy'
   */
  dateFormat?: string;

  /**
   * Provide a handler that is called when the input changes.
   */
  onChange?: (date: Date | null) => void;

  /**
   * Provide a handler that is called when the close button was clicked.
   */
  onCloseClick?: () => void;

  /**
   * Sets the className for the Popper component, the very first container of the DatePicker.
   */
  popperClassName?: string;

  /**
   * Set the locale to be used by the component
   */
  locale?: Locale;

  /**
   * Set if the time input should be shown
   */
  showTimeInput?: boolean;

  /**
   * Class name for the MAIN container of the DatePicker input.
   */
  inputClassName?: string;

  /**
   * Custom input React node to allow custom input components
   */
  customInput?: React.ReactNode;
}

/**
 * A Date Picker is a text input to capture a date. You can select a single date or date and time.
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2022-07-05
 */
export const DatePickerInput = React.forwardRef<HTMLInputElement, DatePickerInputProps>(
  (
    {
      className,
      placeholder,
      value = new Date(),
      minYear = 1922,
      maxYear,
      dateFormat,
      onChange,
      onBlur,
      onFocus,
      onCloseClick,
      popperClassName,
      customInput,
      isDisabled,
      locale = Locale.es,
      assistiveText,
      showTimeInput = false,
      inputClassName,

      ...restOfProps
    },
    ref,
  ) => {
    const classes = {
      popperClassName: cn(popperClassName, 'e-z-1'),
      assistiveText: cn('e-mt-2 e-text-xs e-font-extra-light', {
        'e-text-error-500': restOfProps.fieldState === FormFieldState.error,
        'e-text-neutral-200': restOfProps.fieldState !== FormFieldState.error,
      }),
    };

    const [selectedDate, setSelectedDate] = useState(value);

    const handleDateChange = (date: Date | null) => {
      setSelectedDate(date);
      if (onChange) onChange(date);
    };

    const handleInputBlur = (event: FocusEvent<HTMLInputElement>): void => {
      if (onBlur) onBlur(event);
    };

    const handleInputFocus = (event: FocusEvent<HTMLInputElement>): void => {
      if (onFocus) onFocus(event);
    };

    const dateFormatted = dateFormat ? dateFormat : showTimeInput ? DEFAULT_DATE_TIME_FORMAT : DEFAULT_DATE_FORMAT;

    const customInputElement = customInput ? (
      React.cloneElement(customInput as React.ReactElement, {
        ref,
        value: '',
        ...restOfProps,
      })
    ) : (
      <TextInput className={inputClassName} ref={ref} endIcon={IconCatalog.calendar} value="" {...restOfProps} />
    );

    /* Render JSX
     * NOTE: The custom input is just a dumb component, every TextInput prop should be on the DatePicker component
     */
    return (
      <>
        <DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          onBlur={handleInputBlur}
          onFocus={handleInputFocus}
          locale={locale}
          dateFormat={dateFormatted}
          popperClassName={classes.popperClassName}
          placeholderText={placeholder}
          disabled={isDisabled}
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <DatePickerHeader
              date={date}
              minYear={minYear}
              maxYear={maxYear}
              changeYear={changeYear}
              changeMonth={changeMonth}
              decreaseMonth={decreaseMonth}
              increaseMonth={increaseMonth}
              prevMonthButtonDisabled={prevMonthButtonDisabled}
              nextMonthButtonDisabled={nextMonthButtonDisabled}
              locale={locale}
            />
          )}
          customInput={customInputElement}
          calendarClassName="eden-datepicker"
          showPopperArrow={false}
          portalId="root-portal"
          customTimeInput={<DateTimePicker className="eden-datepicker" onTimeChange={handleDateChange} />}
          showTimeInput={showTimeInput}
          disabledKeyboardNavigation
          timeInputLabel={translations[locale].timeLabel}
        />
        {assistiveText && <span className={classes.assistiveText}>{assistiveText}</span>}
      </>
    );
  },
);
