import React from 'react';
import cn from 'classnames';
import { Button, ButtonSize, ButtonVariant } from '../../../Buttons';
import { IconCatalog } from '../../../Icon/Icon';

export interface BottomSheetHeaderProps {
  /**
   * Title for modal header
   */
  title?: string;

  /**
   * Render close button
   */
  hasCloseBtn?: boolean;

  /**
   * Set an extra action on the modal header
   */
  actionElement?: React.ReactNode;

  /**
   * Callback to execute when clicking on the close button
   */
  onCloseBtnClick?: () => void;
}

/**
 * `BottomSheetHeader` represents the header section of a bottom sheet
 * @author Sergio Ruiz <sergioruizdavila@gmail.com>
 * Created at 2024-02-12
 */
export const BottomSheetHeader = ({ title, actionElement, hasCloseBtn, onCloseBtnClick }: BottomSheetHeaderProps) => {
  const classes = {
    header: cn('e-flex e-items-center e-flex-shrink-0 e-justify-between e-pt-4 e-pb-2 e-px-5'),
  };

  return (
    <div className={classes.header}>
      {title && <h2 className="e-text-xl e-font-semi-bold e-text-base-white e-line-clamp-3">{title}</h2>}

      <div className="e-flex e-items-center e-ml-auto">
        {actionElement}
        {hasCloseBtn && (
          <Button
            startIcon={IconCatalog.close}
            variant={ButtonVariant.ghostNeutral}
            size={ButtonSize.xs}
            onClick={onCloseBtnClick}
          />
        )}
      </div>
    </div>
  );
};
