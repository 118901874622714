import React from 'react';
import cn from 'classnames';
import { Select } from '../../Inputs';
import { PaginationProps } from '../Pagination';

const RESULTS_RANGE_OFFSET = 1;
const DEFAULT_PAGE_SIZE_OPTIONS = [10, 20, 30, 40];

export interface PaginationHelperProps {
  /**
   * Specify an optional className to be added to the component
   */
  className?: string;
  /**
   * List of available page size options
   */
  pageSizeOptions?: Array<number>;
  /**
   * Indicate what's the current page size
   */
  currentPageSize: number;
  /**
   * Callback to trigger when the page size changes
   */
  onPageSizeChange: PaginationProps['onPageSizeChange'];
  /**
   * Number of total results
   */
  totalCount: number;
  /**
   * Number of results at the current page
   */
  resultsOffset: number;
  /**
   * Indicate the text parts that compose the pagination helper
   */
  textParts: {
    /**
     * 'Results per page' text
     */
    resultsPerPage: string;
    /**
     * 'Showing' text
     */
    showing: string;
    /**
     * 'Of' text
     */
    of: string;
    /**
     * 'Results' text, f.i. `results` | `studies`, etc
     */
    results: string;
  };
}

/**
 * `PaginationHelper` acts as a complement of the `Pagination` component to help
 * visualize the current and total count of elements being listed, and interact with
 * a page size selector.
 * @author Alan Chávez<alan.chavez@evacenter.com>
 * Created at 2022-29-09
 */
export const PaginationHelper = ({
  className,
  pageSizeOptions = DEFAULT_PAGE_SIZE_OPTIONS,
  currentPageSize,
  onPageSizeChange,
  totalCount,
  resultsOffset,
  textParts,
}: PaginationHelperProps) => {
  const { resultsPerPage, showing, of, results } = textParts;
  const classes = {
    container: cn(
      className,
      'e-flex e-flex-col sm:e-flex-row e-flex-wrap e-space-y-2 e-justify-center sm:e-space-y-0 sm:e-space-x-4 lg:e-space-x-4 xl:e-space-x-8 lg:e-flex-nowrap e-space-x-2 lg:e-justify-start e-items-center e-w-full',
    ),
  };
  const currentResultsRange = (
    <span>
      {resultsOffset - currentPageSize + RESULTS_RANGE_OFFSET} - {resultsOffset}
    </span>
  );
  const rowsPerPageOptions = pageSizeOptions.map((pageSize) => ({
    label: `${pageSize}`,
    value: `${pageSize}`,
  }));
  return (
    <div className={classes.container}>
      <div className="e-flex e-space-x-2 e-items-center">
        <div className="e-text-neutral-300">{resultsPerPage}</div>
        <Select
          value={`${currentPageSize}`}
          options={rowsPerPageOptions}
          onChange={({ value }) => onPageSizeChange(Number(value))}
        />
      </div>
      <div className="e-text-neutral-300">
        <div>
          {showing} <span className="e-text-base-white">{currentResultsRange}</span> {of}{' '}
          <span className="e-text-base-white">
            {totalCount} {results}
          </span>
        </div>
      </div>
    </div>
  );
};
