import React from 'react';
import cn from 'classnames';

const DEFAULT_STEP_VALUE = 1;

export enum StepState {
  active = 'active',
  enabled = 'enabled',
  disabled = 'disabled',
  success = 'success',
  error = 'error',
}

export const StepStateIconVariant: Record<StepState, string> = {
  [StepState.active]: 'e-border-none e-bg-primary-500 e-text-base-white',
  [StepState.enabled]: 'e-border-neutral-400 e-bg-neutral-500',
  [StepState.disabled]: 'e-border-neutral-400 e-bg-neutral-500',
  [StepState.success]: 'e-border-success-400 e-bg-success-800 e-text-success-50',
  [StepState.error]: 'e-bg-error-800 e-border-error-500 e-text-error-50',
};

export interface StepProps {
  /**
   * Value to indicate the step number
   */
  step?: number;

  /**
   * Text to be displayed at the very top of the step, above `content`
   */
  title?: string;

  /**
   * Text to be displayed as the main text of the step
   */
  content?: string;

  /**
   * Text to be displayed at the bottom of the step, right after `content`
   */
  assistiveText?: string;

  /**
   * Value to indicate the specific state of the step
   */
  state?: StepState;

  /**
   * Flag to indicate if a divider should be displayed at the bottom of the step.
   * Helpful for situations where you have multiple Step components in the same place.
   */
  isLastStep?: boolean;

  /**
   * Class names used for external styles
   */
  className?: string;

  /**
   * Descriptive text to be read to screenreaders.
   */
  ariaLabel?: string;
}

/**
 * A Step component is used to indicate users their current progress within a flow.
 * @author Alan Chávez <alan.chavez@evacenter.com>
 * Created at 2022-06-01
 */
export const Step = ({
  step,
  title,
  content,
  assistiveText,
  state,
  isLastStep = false,
  className,
  ariaLabel = 'step',
}: StepProps) => {
  const currentStep = step ?? DEFAULT_STEP_VALUE;
  const stepState = state ?? StepState.enabled;
  const isDisabled = stepState === StepState.disabled;
  const isError = stepState === StepState.error;
  const isEnabled = stepState === StepState.enabled;

  const classes = {
    step: cn(className, 'e-flex e-items-center e-space-x-2', {
      'e-pb-4 e-mb-4 e-border-b e-border-neutral-600': !isLastStep,
    }),
    title: cn('e-text-xs e-font-light', {
      'e-text-neutral-50': !isDisabled,
      'e-text-neutral-400': isDisabled,
    }),
    icon: cn(
      'e-flex-none e-border e-w-6 e-h-6 e-rounded-full e-flex e-items-center e-justify-center e-font-semi-bold e-text-xs e-text-neutral-50',
      StepStateIconVariant[stepState],
    ),
    contentContainer: cn({
      'e-text-neutral-200': !isDisabled,
      'e-text-neutral-400': isDisabled,
    }),
    content: cn({
      'e-text-neutral-200 e-font-light': isEnabled,
      'e-text-neutral-400 e-font-light': isDisabled,
      'e-text-neutral-50 e-font-semi-bold': !isEnabled && !isDisabled,
    }),
    assistiveText: cn('e-text-xs e-font-light', {
      'e-text-error-600': isError,
      'e-text-neutral-400': isDisabled,
    }),
  };

  return (
    <div className={classes.step} aria-label={ariaLabel}>
      <div className={classes.icon} aria-label={`${ariaLabel}-icon`}>
        {currentStep}
      </div>
      <div>
        {title && <div className={classes.title}>{title}</div>}
        <div className={classes.contentContainer}>
          {content && <div className={classes.content}>{content}</div>}
          {assistiveText && (
            <div className={classes.assistiveText} aria-label={`${ariaLabel}-assistiveText`}>
              {assistiveText}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
