import React, {
  ChangeEvent,
  FocusEvent,
  InputHTMLAttributes,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';
import { FormFieldState } from '../../../common/constants';
import { Input } from '../../../common/types/input';

export enum Resize {
  both = 'both',
  vertical = 'vertical',
  horizontal = 'horizontal',
  none = 'none',
}

export interface TextareaProps extends Input, Omit<InputHTMLAttributes<HTMLTextAreaElement>, 'required'> {
  /**
   * Whether the Textarea allows autosize or not
   */
  hasAutoSize?: boolean;

  /**
   * Set the min height (px)
   */
  minHeight?: string;

  /**
   * Whether the Textarea is resizable or not
   */
  resize?: Resize;
}

/**
 * Similar to the Input component, enable the user to interact with and input long content and data.
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2022-06-07
 */
export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      id,
      isDisabled = false,
      isRequired = false,
      isReadOnly = false,
      fieldState = FormFieldState.default,
      label,
      assistiveText,
      className,
      dataTestId,
      value = '',
      name,
      resize = Resize.vertical,
      hasAutoSize = false,
      minHeight = '72px',
      onChange,
      onBlur,
      onFocus,
      ...restOfProps
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);
    const isInputActive = fieldState === FormFieldState.active && !isDisabled;
    const classes = {
      container: cn(className),
      textareaContainer: cn(
        'e-relative e-flex e-items-center e-overflow-hidden',
        'e-bg-neutral-700 e-text-neutral-50 e-border',
        'e-rounded-lg e-p-2 e-w-full',
        {
          'e-border-neutral-400': fieldState === FormFieldState.default && !isDisabled,
          'e-border-error-500': fieldState === FormFieldState.error && !isDisabled,
          'e-border-primary-500': (fieldState === FormFieldState.default && isFocused) || isInputActive,
          'e-border-transparent': isDisabled,
        },
      ),
      textarea: cn(
        'e-transition e-duration-100 e-ease-out e-outline-none',
        'placeholder:e-text-neutral-300 placeholder:e-font-light e-text-base e-font-regular',
        'e-w-full',
        {
          'e-bg-neutral-700': !isDisabled,
          'e-bg-neutral-800': isDisabled,
          'e-resize-none': resize === Resize.none,
          'e-resize': resize === Resize.both,
          'e-resize-y': resize === Resize.vertical,
          'e-resize-x': resize === Resize.horizontal,
        },
      ),
      assistiveText: cn('e-mt-2 e-text-xs e-font-extra-light', {
        'e-text-neutral-200': fieldState === FormFieldState.default,
        'e-text-error-500': fieldState === FormFieldState.error,
      }),
    };

    const myRef = useRef<HTMLTextAreaElement | null>(null);

    useEffect(() => {
      if (!hasAutoSize) return;
      if (myRef && myRef.current) {
        myRef.current.style.height = minHeight;
        const scrollHeight = myRef.current.scrollHeight;
        myRef.current.style.height = scrollHeight + 'px';
      }
    }, [value]);

    const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
      if (onChange) onChange(event);
    };

    const handleInputBlur = (event: FocusEvent<HTMLTextAreaElement>): void => {
      setIsFocused(false);
      if (onBlur) onBlur(event);
    };

    const handleInputFocus = (event: FocusEvent<HTMLTextAreaElement>): void => {
      setIsFocused(true);
      if (onFocus) onFocus(event);
    };

    // https://stackoverflow.com/questions/62238716/using-ref-current-in-react-forwardref
    const handleRef = (node: HTMLTextAreaElement | null) => {
      myRef.current = node;
      if (typeof ref === 'function') ref(node);
      else if (ref) (ref as MutableRefObject<HTMLTextAreaElement | null>).current = node;
    };

    /* Render JSX */
    return (
      <div className={classes.container}>
        {label && (
          <label className="e-mb-2 e-text-neutral-50 e-text-sm e-font-regular" htmlFor={id}>
            {label}
            {isRequired && '*'}
          </label>
        )}
        <div className={classes.textareaContainer}>
          <textarea
            id={id}
            data-testid={dataTestId}
            ref={handleRef}
            name={name}
            className={classes.textarea}
            disabled={isDisabled}
            value={value}
            readOnly={isReadOnly}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            onFocus={handleInputFocus}
            {...restOfProps}
          />
        </div>
        {assistiveText && <span className={classes.assistiveText}>{assistiveText}</span>}
      </div>
    );
  },
);
