import React from 'react';
import { Image } from '../../../../Image/Image';
import { Button, ButtonVariant, ButtonSize } from '../../../../Buttons/Button/Button';
import { FileRowType, Icon, IconCatalog } from '../../../..';

export interface ValidationErrorRowProps {
  file: FileRowType;
  errorMsg: string;
  onDeleteClick?: (fileId: string) => void;
}

const generateFileExtension = (fileName: string) => {
  return fileName.split('.').pop();
};

/**
 * This component represents the error validation row instance.
 * @author Salvador Gonzalez <salvador.gonzalez@evacenter.com>
 * Created at 2023-10-01
 */
export const ValidationErrorRow = ({ file, errorMsg, onDeleteClick }: ValidationErrorRowProps) => {
  const handleDeleteClick = () => {
    if (onDeleteClick) onDeleteClick(file.id);
  };

  return (
    <div className="e-flex e-items-center e-mb-4 e-w-full">
      <div className="e-flex e-items-center e-w-full">
        {/* THUMBNAIL */}
        <div className="e-relative e-w-8 e-h-8 e-mr-2 e-flex-shrink-0">
          <Image
            alt={file.name}
            noImg={
              <div className="e-bg-base-black e-border e-border-warning-600 e-w-full e-h-full e-rounded-lg e-flex e-items-center e-flex-col e-justify-center">
                <span className="e-text-base-white e-text-2xs e-font-semi-bold e-leading-none">
                  {generateFileExtension(file.name)}
                </span>
              </div>
            }
            hasMaxWidth
          />
          <div className="e-absolute e-border-2 e-top-5 -e-left-1.5 e-border-neutral-900 e-rounded-full e-bg-warning-600 e-w-4 e-h-4">
            <Icon className="e-text-neutral-50 e-w-3 e-h-3" icon={IconCatalog.warning} />
          </div>
        </div>

        <div style={{ minWidth: '80px' }} className=" e-flex e-items-start e-flex-col e-w-full e-space-y-1">
          {/* FILE NAME */}
          <div className="e-text-base-white e-font-semi-bold e-truncate e-w-full e-text-xs">{file.name}</div>

          {/* ERROR MSG */}
          <div className="e-text-warning-600 e-font-regular e-truncate e-w-full e-text-2xs">{errorMsg}</div>
        </div>

        <Button
          variant={ButtonVariant.ghost}
          size={ButtonSize.xs}
          onClick={handleDeleteClick}
          endIcon={IconCatalog.trash}
        />
      </div>
    </div>
  );
};
