import React from 'react';
import cn from 'classnames';

import { ViewerInformationPatientInfo, ViewerInformationState } from './ViewerInfoContainerTypes';
import { ViewerInfoHeaderDesktop } from './ViewerInfoHeaderDesktop/ViewerInfoHeaderDesktop';
import { ViewerInfoHeaderMobile } from './ViewerInfoHeaderMobile/ViewerInfoHeaderMobile';
import { ViewerInfoFooter, SerieInfo } from './ViewerInfoFooter/ViewerInfoFooter';
import { CalendarLocale } from '../../Calendar';

export interface ViewerInfoContainerProps {
  /**
   * The width of the parent container.
   */
  parentWidth?: number;

  /**
   * The CSS class to apply to the component.
   */
  className?: string;

  /**
   * The CSS class to apply to the header.
   */
  headerClassName?: string;

  /**
   * The CSS class to apply to the footer.
   */
  footerClassName?: string;

  /**
   * Whether the component is being rendered on a mobile device or not.
   */
  isMobile?: boolean;

  /**
   * The locale to be used.
   */
  locale: CalendarLocale | string;

  /**
   * The information state of the component.
   */
  informationState?: ViewerInformationState;

  /**
   * The patient information.
   */
  patientInfo?: ViewerInformationPatientInfo;

  /**
   * The serie information.
   */
  serieInfo?: SerieInfo;

  /**
   * Callback fired when the patient's select is clicked.
   */
  onSelectClick?: () => void;
}

/**
 * This component is used to display the patient and series information on the viewer.
 */
export const ViewerInfoContainer = ({
  isMobile,
  className,
  serieInfo,
  locale = CalendarLocale.ES,
  patientInfo,
  parentWidth,
  onSelectClick,
  headerClassName,
  footerClassName,
  informationState,
}: ViewerInfoContainerProps) => {
  const classes = {
    header: cn(headerClassName),
    container: cn(className),
    footer: cn(footerClassName),
  };

  const handlePatientSelectClick = () => {
    if (onSelectClick) onSelectClick();
  };

  return (
    <div className={classes.container}>
      {/* Header */}
      {isMobile && (
        <ViewerInfoHeaderMobile
          locale={locale}
          patientInfo={patientInfo}
          className={classes.header}
          informationState={informationState}
          onSelectClick={handlePatientSelectClick}
        />
      )}
      {!isMobile && (
        <ViewerInfoHeaderDesktop
          locale={locale}
          isMobile={isMobile}
          parentWidth={parentWidth}
          patientInfo={patientInfo}
          className={classes.header}
          informationState={informationState}
          onSelectClick={handlePatientSelectClick}
        />
      )}

      {/* Footer */}
      <ViewerInfoFooter
        isMobile={isMobile}
        serieInfo={serieInfo}
        parentWidth={parentWidth}
        className={classes.footer}
        informationState={informationState}
      />
    </div>
  );
};
