import React, { ChangeEvent, SelectHTMLAttributes } from 'react';
import cn from 'classnames';
import { Icon, IconCatalog } from '../../../Icon/Icon';

export type Option = {
  value: string | number;
  label: string | number;
};

export interface NativeSelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  /**
   * Set the select options list.
   */
  options: Array<Option>;
}

/**
 * This component represents a NativeSelect component for the DatePicker
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2022-08-25
 */
export const DatePickerSelect = ({
  name,
  options = [],
  placeholder,
  value,
  className,
  onChange,
}: NativeSelectProps) => {
  const classes = {
    selectContainer: cn(
      className,
      'e-relative e-flex e-items-center e-space-x-1 e-overflow-hidden e-p-2',
      'e-bg-neutral-700 e-text-neutral-50 e-rounded-lg hover:e-bg-neutral-500',
      'e-h-8',
    ),
    select: cn(
      'e-pr-6',
      'e-transition e-duration-100 e-ease-out',
      'e-appearance-none e-outline-none e-truncate',
      'placeholder:e-text-neutral-300 e-text-base e-font-regular e-bg-transparent',
    ),
    option: cn('e-bg-neutral-500'),
    endContainer: cn(
      'e-flex e-items-center e-space-x-2 e-self-stretch e-flex-shrink-0 e-ml-auto e-pointer-events-none e-absolute e-right-1 e-top-1',
    ),
  };

  const renderOptions = options.map((item, index) => (
    <option key={index} value={item.value} className={classes.option}>
      {item.label}
    </option>
  ));

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    if (onChange) onChange(event);
  };

  const defaultValue = options.find((option) => option.value === value);

  return (
    <div className={classes.selectContainer}>
      <select className={classes.select} name={name} value={defaultValue?.value || ''} onChange={handleChange}>
        {placeholder && (
          <option disabled value="">
            {placeholder}
          </option>
        )}
        {renderOptions}
      </select>
      <div className={classes.endContainer}>
        {/* CHEVRON ICON */}
        <Icon className="e-text-primary-300" icon={IconCatalog.chevronDown} />
      </div>
    </div>
  );
};
