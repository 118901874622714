import React, { ChangeEvent, useState } from 'react';
import { TextInput } from '../../TextInput/TextInput';
import { cn } from '../../../../common';
import { format, parse } from 'date-fns';
import { Icon, IconCatalog } from '../../../Icon/Icon';
import { DEFAULT_DATE_FORMAT } from '../constants';
import { isValidDate } from '../utils';

export interface RangeDatePickerHeaderProps {
  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * Start date of the range.
   */
  startDateValue?: Date | null;

  /**
   * End date of the range.
   */
  endDateValue?: Date | null;

  /**
   * Provide a method that is called when the start date text input has changed.
   */
  onChangeStartDate: (date: Date) => void;

  /**
   * Provide a method that is called when the end date text input has changed.
   */
  onChangeEndDate: (date: Date) => void;
}

/**
 * This component represent the Range Date Picker Header (RangeDatePicker)
 */
export const RangeDatePickerHeader = ({
  className,
  startDateValue,
  endDateValue,
  onChangeStartDate,
  onChangeEndDate,
}: RangeDatePickerHeaderProps) => {
  const classes = {
    container: cn('e-flex e-justify-center e-items-center e-gap-2', className),
  };

  const parsedStartDate = startDateValue ? format(startDateValue, DEFAULT_DATE_FORMAT) : '';
  const parsedEndDate = endDateValue ? format(endDateValue, DEFAULT_DATE_FORMAT) : parsedStartDate;
  const [inputStartValue, setInputStartValue] = useState(parsedStartDate);
  const [inputEndValue, setInputEndValue] = useState(parsedEndDate);

  const handleStartDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputStartValue(value);
    if (!isValidDate(value)) return;
    if (onChangeStartDate) onChangeStartDate(parse(value, DEFAULT_DATE_FORMAT, new Date()));
  };
  const handleEndDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputEndValue(event.target.value);
    if (!isValidDate(event.target.value)) return;
    if (onChangeEndDate) onChangeEndDate(parse(event.target.value, DEFAULT_DATE_FORMAT, new Date()));
  };

  /*
   * Render JSX
   */
  return (
    <div className={classes.container}>
      <TextInput isFullWidth value={inputStartValue} onChange={handleStartDateChange} endIcon={IconCatalog.calendar} />
      <Icon className="e-shrink-0" icon={IconCatalog.arrowRight} width={16} height={16} />
      <TextInput isFullWidth value={inputEndValue} onChange={handleEndDateChange} endIcon={IconCatalog.calendar} />
    </div>
  );
};
