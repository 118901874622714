import { useLayoutEffect, useState } from 'react';
import throttle from 'lodash.throttle';

interface ElementSize {
  elementWidth?: number;
  elementHeight?: number;
}

interface UseElementSize {
  (
    /*
     * Reference to the element.
     */
    elemRef: React.RefObject<HTMLElement>,

    /*
     * Waiting time in milliseconds to throttle the resize event.
     */
    waitingTime?: number,
  ): ElementSize;
}

export const useElementSize: UseElementSize = (elemRef, waitingTime = 400) => {
  const [elementSize, setElementSize] = useState<ElementSize>({
    elementWidth: undefined,
    elementHeight: undefined,
  });

  const handleSize = () => {
    if (!elemRef.current) return;
    setElementSize({
      elementWidth: elemRef.current.getBoundingClientRect().width,
      elementHeight: elemRef.current.getBoundingClientRect().height,
    });
  };

  // Update size on resize
  useLayoutEffect(() => {
    // Initial size on mount
    handleSize();

    // Update windowSize on mount
    const calcInnerWidth = throttle(() => handleSize(), waitingTime);

    // Add event listener for resize
    window.addEventListener('resize', calcInnerWidth);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('resize', calcInnerWidth);
    };
  }, []);

  return elementSize;
};
