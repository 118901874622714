import React from 'react';
import {
  CreatorAlertCard,
  CreatorAlertCardProps,
  CreatorAlertCardVariant,
} from '../../Cards/CreatorAlertCard/CreatorAlertCard';
import { IconCatalog } from '../../../Icon/Icon';

interface CreatorErrorStateProps extends Omit<CreatorAlertCardProps, 'icon' | 'iconSize' | 'variant'> {}

const DEFAULT_ICON_CREATOR_SIZE = 24;

/**
 * A CreatorErrorState is an element to display error state for creator errors.
 * @author Salvador Gonzalez<salvador.gonzalez@evacenter.com>
 * Created on 2023-08-15
 */
export const CreatorErrorState = (props: CreatorErrorStateProps) => {
  return (
    <CreatorAlertCard
      {...props}
      icon={IconCatalog.warningTriangle}
      iconSize={DEFAULT_ICON_CREATOR_SIZE}
      variant={CreatorAlertCardVariant.error}
    />
  );
};
