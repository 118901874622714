import React, { BaseHTMLAttributes } from 'react';
import cn from 'classnames';

import { Button, ButtonSize, ButtonVariant } from '../Button/Button';
import { IconCatalog } from '../../Icon/Icon';

export interface ValueControlProps extends BaseHTMLAttributes<HTMLDivElement> {
  title?: string;
  value: number;
  valueSymbol?: string;
  minValue?: number;
  maxValue?: number;
  unit?: string;
  size?: ButtonSize;
  isDisabled?: boolean;
  onDecrease: () => void;
  onIncrease: () => void;
}

/**
 * ValueControl are used most commonly for increase o decrease a value.
 * Created at 2023-01-05
 */
export const ValueControl = ({
  title,
  value,
  minValue,
  maxValue,
  unit,
  size = ButtonSize.xs,
  className,
  valueSymbol = '',
  isDisabled = false,
  onDecrease,
  onIncrease,
  ...restOfProps
}: ValueControlProps) => {
  const classes = {
    container: cn('e-text-base-white e-bg-neutral-700 e-flex e-rounded-lg e-items-center e-w-max', className),
    title: cn('e-text-xs e-text-neutral-50 e-font-medium e-mr-1 e-ml-px', {
      'e-text-xs': size === ButtonSize.xs,
      'e-text-base': size !== ButtonSize.xs,
    }),
    value: cn('e-text-xs e-text-neutral-50 e-font-medium e-ml-px e-mr-1', {
      'e-text-xs': size === ButtonSize.xs,
      'e-text-base': size !== ButtonSize.xs,
    }),
    unit: cn('e-font-regular e-text-neutral-200 e-text-2xs e-mx-px'),
    minus: cn('e-mr-2'),
    plus: cn('e-ml-2'),
  };

  const isIncreaseBtnDisabled = isDisabled || maxValue === value;
  const isDecreaseBtnDisabled = isDisabled || minValue === value;

  return (
    <div className={classes.container} {...restOfProps}>
      <Button
        variant={ButtonVariant.ghostNeutral}
        size={size as ButtonSize}
        className={classes.minus}
        onClick={onDecrease}
        disabled={isDecreaseBtnDisabled}
        isDisabled={isDecreaseBtnDisabled}
        startIcon={IconCatalog.decrease}
      />
      <div>
        {title && <span className={classes.title}>{title}</span>}
        <span className={classes.value}>{`${value}${valueSymbol}`}</span>
        {unit && <span className={classes.unit}>{unit}</span>}
      </div>

      <Button
        variant={ButtonVariant.ghostNeutral}
        size={size as ButtonSize}
        className={classes.plus}
        onClick={onIncrease}
        disabled={isIncreaseBtnDisabled}
        isDisabled={isIncreaseBtnDisabled}
        startIcon={IconCatalog.plus}
      />
    </div>
  );
};
