import startOfMonth from 'date-fns/startOfMonth';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import addWeeks from 'date-fns/addWeeks';
import addDays from 'date-fns/addDays';
import { CalendarView, DateRange, WeekStartsOn } from '../types';
import { CALENDAR_NAVIGATION_STEP } from '../constants';

export enum Action {
  PREV = 'prev',
  NEXT = 'next',
  TODAY = 'today',
}

/**
 * A function to handle the navigation between the views
 * @param date A date object
 * @param action A string representing the action to be performed
 * @param view A string representing the current view
 * @returns Date
 * @author Javier Diaz <javier.diaz@evacenter.com>
 * Created at 2022-12-14
 */
export const navigate = (date: Date, action: Action, view: CalendarView = CalendarView.MONTH): Date => {
  const navigationActions = {
    [Action.PREV]: () => {
      if (view === CalendarView.MONTH) {
        return addMonths(date, CALENDAR_NAVIGATION_STEP.DECREMENT);
      } else if (view === CalendarView.WEEK) {
        return addWeeks(date, CALENDAR_NAVIGATION_STEP.DECREMENT);
      }
      return addDays(date, CALENDAR_NAVIGATION_STEP.DECREMENT);
    },
    [Action.NEXT]: () => {
      if (view === CalendarView.MONTH) {
        return addMonths(date, CALENDAR_NAVIGATION_STEP.INCREMENT);
      } else if (view === CalendarView.WEEK) {
        return addWeeks(date, CALENDAR_NAVIGATION_STEP.INCREMENT);
      }
      return addDays(date, CALENDAR_NAVIGATION_STEP.INCREMENT);
    },
    [Action.TODAY]: () => date,
  };
  return (navigationActions[action] || navigationActions[Action.TODAY])();
};

/**
 * Function to get the range of the week
 * @param date A date object to get the range of the week
 * @returns DateRange
 */
export const getRange = (date: Date, view: CalendarView): DateRange => {
  const rangeOptions = {
    [CalendarView.DAY]: () => {
      return {
        start: date,
        // same day as the current.
        end: date,
      };
    },
    [CalendarView.WEEK]: () => {
      return {
        start: startOfWeek(date, { weekStartsOn: WeekStartsOn.MONDAY }),
        end: endOfWeek(date, { weekStartsOn: WeekStartsOn.MONDAY }),
      };
    },
    [CalendarView.MONTH]: () => {
      return {
        start: startOfMonth(date),
        end: endOfMonth(date),
      };
    },
  };
  return (rangeOptions[view] || rangeOptions[CalendarView.WEEK])();
};
