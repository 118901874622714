import React, { HTMLAttributes, createContext, useContext, useEffect, useRef, useState } from 'react';
import { Popover, PopoverPlacement } from '../../Overlays';
import classNames from 'classnames';

const DEFAULT_OPTION_TOOLTIP_TIMEOUT = 1000;

interface OptionItemContext {
  isContentOverflowing: boolean;
  setIsContentOverflowing: React.Dispatch<React.SetStateAction<boolean>>;
  isTooltipOpen: boolean;
  setIsTooltipOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const OptionItemContext = createContext({} as OptionItemContext);

type OptionItemProps<C extends React.ElementType> = {
  /**
   * Flag to indicate if the tooltip can be show
   */
  showTooltip?: boolean;

  /**
   * Specify which element should the option label be
   */
  as?: C;
};

export type OptionProps<C extends React.ElementType> = React.PropsWithChildren<OptionItemProps<C>> &
  React.ComponentPropsWithoutRef<C>;

export const OptionItem = <C extends React.ElementType = 'div'>({
  as,
  children,
  onMouseEnter,
  onMouseLeave,
  showTooltip = true,
  ...restProps
}: OptionProps<C>) => {
  const Element = as || 'div';
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [isContentOverflowing, setIsContentOverflowing] = useState(false);
  const popoverTimeout = useRef<NodeJS.Timeout | undefined>();

  const handleOptionMouseEnter = () => {
    if (!isContentOverflowing || !showTooltip) return;

    popoverTimeout.current = setTimeout(() => {
      setIsTooltipOpen(true);
    }, DEFAULT_OPTION_TOOLTIP_TIMEOUT);
  };

  const handleOptionMouseLeave = () => {
    if (!isContentOverflowing) return;
    setIsTooltipOpen(false);

    if (popoverTimeout.current) {
      clearTimeout(popoverTimeout.current);
      popoverTimeout.current = undefined;
    }
  };

  return (
    <OptionItemContext.Provider
      value={{
        isContentOverflowing,
        setIsContentOverflowing,
        isTooltipOpen,
        setIsTooltipOpen,
      }}>
      {/*
       * Ignoring this error because of an issue caused by react-big-calendar
       * due different react versions and types mismatch.
       */}
      {/* @ts-ignore */}
      <Element
        {...restProps}
        className={classNames(restProps?.className, 'e-flex e-min-w-0 e-w-full e-relative')}
        onMouseEnter={handleOptionMouseEnter}
        onMouseLeave={handleOptionMouseLeave}>
        {children}
      </Element>
    </OptionItemContext.Provider>
  );
};

export const OptionItemLabel = ({ children, className, ...restProps }: HTMLAttributes<HTMLSpanElement>) => {
  const { isTooltipOpen, isContentOverflowing, setIsContentOverflowing } = useContext(OptionItemContext);
  const [optionContentRef, setOptionContentRef] = useState<HTMLSpanElement | null>(null);

  useEffect(() => {
    if (!optionContentRef) return;

    const handleWindowResize = () => {
      const { scrollWidth, clientWidth } = optionContentRef;
      setIsContentOverflowing(scrollWidth > clientWidth);
    };

    handleWindowResize();

    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, [optionContentRef]);

  const optionLabelContent = (
    <span
      {...restProps}
      ref={(ref) => setOptionContentRef(ref)}
      className="e-text-neutral-50 e-min-w-0 e-text-left e-truncate e-flex-grow">
      {children}
    </span>
  );

  if (isContentOverflowing)
    return (
      <Popover
        role="tooltip"
        isOpen={isTooltipOpen}
        content={
          <div className="e-text-xs e-p-2 e-border-0.5 e-border-neutral-500 e-bg-neutral-600 e-text-left e-rounded-lg">
            {children}
          </div>
        }
        placement={PopoverPlacement.bottomEnd}
        menuClassName="e-w-64 e-overflow-hidden">
        {optionLabelContent}
      </Popover>
    );

  return optionLabelContent;
};
