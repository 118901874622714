import React, { ChangeEvent, InputHTMLAttributes } from 'react';
import { getExtensionFile, toMb } from '../../../common';

export interface FileInputValidationResponse {
  file: File;
  error: string;
}

export interface FileInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onDrop'> {
  /**
   * Descriptive text to be read to screenreaders
   */
  ariaLabel?: string;

  /**
   * Whether the component allows multiple files or not
   */
  multiple?: boolean;

  /**
   * The list of accepted types. e.g. ".jpg, .png, .pdf"
   */
  accept?: string;

  /**
   * The list of accepted extensions (e.g. JPG, PNG, PDF, etc)
   */
  extensions?: Array<string>;

  /**
   * The max file size
   */
  limitSize: number;

  /**
   * Set a message text when the file exceeds the allowed size
   */
  fileExceedSizeMsg?: string;

  /**
   * Set a message text when the file exceeds the allowed size
   */
  unsupportedMediaTypeMsg?: string;

  /**
   * The callback to get notified when the input value changed.
   */
  onInputChange: (validationResponse: Array<FileInputValidationResponse>) => void;

  /**
   * The callback to get notified when the user drop a file.
   */
  onDrop: (validationResponse: Array<FileInputValidationResponse>, event: React.DragEvent<HTMLInputElement>) => void;
}

/**
 * The File Input allows a user to transfer a file or submit content of their own.
 * @author Sergio Ruiz <sergio.ruiz@evacenter.com>
 * Created at 2022-11-07
 */
export const FileInput = React.forwardRef<HTMLInputElement, FileInputProps>(
  (
    {
      ariaLabel,
      multiple = false,
      className,
      style,
      accept,
      extensions,
      limitSize = 10,
      fileExceedSizeMsg = '',
      unsupportedMediaTypeMsg = '',
      onInputChange,
      onDrop,
      ...restOfProps
    },
    ref,
  ) => {
    const isValidFileSize = (size: number): boolean => {
      const MB = toMb(size);
      return MB <= limitSize;
    };

    const isValidExtension = (fileName: string): boolean => {
      if (!extensions) return true;
      const ext = getExtensionFile(fileName);
      return extensions.includes(ext);
    };

    const validator = (file: File): string => {
      if (file === null || !file) return '';
      if (!isValidFileSize(file.size)) return fileExceedSizeMsg;
      if (!isValidExtension(file.name)) return unsupportedMediaTypeMsg;
      return '';
    };

    const validateFiles = (files: FileList) => {
      let validationResponse: Array<FileInputValidationResponse> = [];
      Array.from(files).forEach((file) => {
        const tempFile = file;
        const currentErrorMsg = validator(tempFile);
        validationResponse = [...validationResponse, { file: tempFile, error: currentErrorMsg }];
      });
      return validationResponse;
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
      const files = event.target.files;
      if (!files?.length) return;

      const validationResponse = validateFiles(files);
      if (onInputChange) onInputChange(validationResponse);
      // Clean input file
      event.target.value = '';
    };

    const handleDrop = (event: React.DragEvent<HTMLInputElement>): void => {
      const files = event.dataTransfer.files;
      if (!files.length) return;

      const validationResponse = validateFiles(files);
      if (onDrop) onDrop(validationResponse, event);
    };

    return (
      <input
        ref={ref}
        data-testid="fileInput"
        aria-label={ariaLabel}
        className={className}
        style={style}
        type="file"
        tabIndex={-1}
        accept={accept}
        onChange={handleChange}
        onDrop={handleDrop}
        onDragOver={(event) => event.preventDefault()}
        multiple={multiple}
        {...restOfProps}
      />
    );
  },
);
