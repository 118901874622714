import React from 'react';
import classNames from 'classnames';

export interface BottomSheetFooterProps {
  /**
   * The children of the modal footer.
   */
  children?: React.ReactNode;

  /**
   * Specify an optional className to be added to the body section.
   */
  className?: string;
}

/**
 * `BottomSheetFooter` represents the footer section of a bottom sheet
 * @author Sergio Ruiz <sergioruizdavila@gmail.com>
 * Created at 2024-02-12
 */
export const BottomSheetFooter = ({ children, className }: BottomSheetFooterProps) => {
  const isValidChildren = React.isValidElement(children);

  const classes = {
    footer: classNames(
      'e-flex e-flex-wrap e-flex-shrink-0 e-items-center e-w-full',
      {
        'e-p-6': isValidChildren,
        'e-p-3': !isValidChildren,
      },
      className,
    ),
  };

  return <div className={classes.footer}>{children}</div>;
};
