import React from 'react';
import { SelectOption } from '../../../common/types/input';
import {
  NestedMultiSelect,
  NestedMultiSelectProps,
  NestedMultiSelectVariant,
} from '../NestedMultiSelect/NestedMultiSelect';
import {
  MultiSelectorOutput,
  formatMultiSelectorOutputToSelectOption,
  formatSelectOptionToMultiSelectorOutput,
  formatSelectOptionToNestedSelectOption,
} from '../../../common';

export interface MultiSelectProps
  extends Omit<NestedMultiSelectProps, 'value' | 'options' | 'selectedOptions' | 'onChange'> {
  /**
   * Initial values for the multiselect.
   */
  value?: Array<SelectOption>;

  /**
   * Set the select options list.
   */
  options: Array<SelectOption>;

  /**
   * Provide a handler that is called when an option was selected.
   */
  onChange?: (selectedOptions: Array<SelectOption>, name?: string) => void;
}

/**
 * This component could be thought of as a styled native select tag, but adding to it more functionalities such as filtering, multi selecting, among others.
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2022-05-26
 */
export const MultiSelect = ({ onChange, options, value, ...restProps }: MultiSelectProps) => {
  const selectedOptions = [...(value ?? [])];
  const multiSelectValue = selectedOptions?.map(formatSelectOptionToMultiSelectorOutput);

  const getIsOptionChecked = (option: SelectOption) =>
    selectedOptions.some((selectedOption) => selectedOption?.value === option?.value);

  const multiSelectOptions = options?.map((option) =>
    formatSelectOptionToNestedSelectOption(option, getIsOptionChecked(option)),
  );

  const handleMultiSelectChange = (selectedOptions: Array<MultiSelectorOutput>) => {
    const options = selectedOptions.map(formatMultiSelectorOutputToSelectOption);

    if (onChange) onChange(options);
  };

  return (
    <NestedMultiSelect
      {...restProps}
      options={multiSelectOptions}
      onChange={handleMultiSelectChange}
      value={multiSelectValue}
      borderless
      optionItemClassName="e-py-2"
      variant={NestedMultiSelectVariant.MultiSelect}
    />
  );
};
