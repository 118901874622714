// Table
export enum CellGradientVariant {
  HeaderCell,
  BodyCell,
}

export const enum ColumnAccessors {
  Actions = 'actions',
  RowSelect = 'row-select',
}

export const DEFAULT_COLUMNS_SIZE: Record<string, number> = {
  [ColumnAccessors.RowSelect]: 70,
  [ColumnAccessors.Actions]: 70,
};
