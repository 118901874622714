import React from 'react';

interface TitledDescriptionCellProps {
  /**
   * Specify the content to use as the `title`
   */
  title: React.ReactNode | string;
  /**
   * Specify the content to use as the `description`
   */
  description?: string;
}

/**
 * `TitledDescriptionCell` represents a highlighted title and a description at the bottom
 * @author Alan Chávez<alan.chavez@evacenter.com>
 * Created at 2022-09-23
 */
export const TitledDescriptionCell = ({ title, description }: TitledDescriptionCellProps) => (
  <div className="e-flex e-flex-col e-space-y-1">
    <p className="e-text-neutral-50">{title}</p>
    {description && <span className="e-text-neutral-200 e-text-xs">{description}</span>}
  </div>
);
