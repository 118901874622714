import React, { useEffect, useState, RefObject, useRef } from 'react';
import cn from 'classnames';
import { FileInput, FileInputProps, FileInputValidationResponse } from '../../FileInput/FileInput';
import { Icon, IconCatalog } from '../../../Icon/Icon';

export interface CustomRef {
  handleUpload: () => void;
}

export interface DropZoneProps extends Omit<FileInputProps, 'onDrop' | 'onInputChange' | 'onChange'> {
  /**
   * Specify an optional className to be added to the component.
   */
  className?: string;

  /**
   * Whether the component just accept folders
   */
  acceptOnlyFolders?: boolean;

  /**
   * The files reference
   */
  filesRef?: RefObject<HTMLInputElement>;

  /**
   * Set the error status from the parent component
   */
  hasError?: boolean;

  /**
   * Provide a handler that is called when the user drop o select the files.
   */
  onChange?: (files: Array<File>, validationResponse: Array<FileInputValidationResponse>) => void;

  /*
   * Header title description.
   */
  headerTitle: string;

  /*
   * Header subtitle description.
   */
  headerSubtitle?: string;

  /*
   * Bottom description text to show in gray small style.
   */
  bottomDescription?: string;

  /*
   * Text description to append to the FileUploader when user is dragging over it.
   */
  draggingDescription: string;

  /*
   * Prop that enable/disables the dropzone.
   */
  disableDropZone: boolean;
}

enum DropZoneStatus {
  default = 'default',
  error = 'error',
}

/**
 * Allow to upload a file to the server with the drag and drop ability
 * @author Sergio Ruiz Davila<sergio.ruiz@evacenter.com>
 * Created at 2022-11-12
 */
export const DropZone = React.forwardRef<CustomRef, DropZoneProps>(
  ({
    ariaLabel,
    className,
    accept,
    acceptOnlyFolders,
    extensions,
    hasError,
    multiple,
    limitSize,
    onChange,
    headerTitle,
    headerSubtitle,
    bottomDescription,
    draggingDescription,
    unsupportedMediaTypeMsg,
    fileExceedSizeMsg,
    disableDropZone,
    ...restProps
  }) => {
    const [isDragging, setIsDragging] = useState(false);
    const classes = {
      container: cn(
        'e-inline-flex e-items-center e-rounded-lg e-p-6 e-relative e-w-full e-min-h-10 e-mb-4',
        className,
        {
          'e-border-neutral-400': !isDragging,
          'e-border e-border-dashed hover:e-bg-neutral-800 e-cursor-pointer': !disableDropZone,
          // Applying twice 'from' prop to do the trick for the figma specs.
          'e-border-primary-500 e-bg-gradient-to-r e-from-primary-400 e-from-primary-900': isDragging,
        },
      ),
      icon: cn('e-mr-2', {
        'e-text-primary-400': isDragging,
        'e-text-neutral-50': !isDragging,
      }),
      input: cn('e-absolute e-left-0 e-top-0 e-w-full e-h-full e-opacity-0 e-z-2', {
        'e-cursor-pointer': !disableDropZone,
      }),
      fileUploader: cn('e-flex e-flex-col e-items-center e-w-full e-space-y-3'),
      fileUploaderHeader: cn('e-flex e-items-center', {
        'e-flex-col': disableDropZone,
      }),
    };

    const filesRef = useRef<HTMLInputElement>(null);

    const title = isDragging ? draggingDescription : headerTitle;

    const [, setStatus] = useState<DropZoneStatus>(DropZoneStatus.default);

    const generateFilesAllowedText = (text?: string, unit = 'mb') => {
      const formattedList = accept?.toLowerCase();
      const uniqueStrings = [...new Set(formattedList?.split(', '))].join(', ').toUpperCase();

      return `${text} ${uniqueStrings} (${limitSize}${unit} máx.)`;
    };

    const renderFileUploader = (): JSX.Element => {
      return (
        <div className={classes.fileUploader}>
          <div className={classes.fileUploaderHeader}>
            <Icon className={classes.icon} icon={disableDropZone ? IconCatalog.warning : IconCatalog.attachment} />
            <span className="e-text-neutral-200 e-mr-1">{title}</span>
            {!isDragging && !disableDropZone && (
              <span className="e-text-primary-300 e-font-semi-bold">{headerSubtitle}</span>
            )}
          </div>
          {!isDragging && !disableDropZone && (
            <span className="e-text-neutral-400 e-text-xs">{generateFilesAllowedText(bottomDescription)}</span>
          )}

          {disableDropZone && <span className="e-text-neutral-400 e-text-xs">{bottomDescription}</span>}
        </div>
      );
    };

    const handleOnDrop = async (
      validationResponse: FileInputValidationResponse[],
      event: React.DragEvent<HTMLInputElement>,
    ) => {
      const errorFiles = validationResponse.filter((response) => response.error);
      if (errorFiles) {
        setStatus(DropZoneStatus.error);
        setIsDragging(false);
      }
      event.preventDefault();
      const files = validationResponse.filter((response) => !response.error).map((file) => file.file);
      if (!files) return;

      if (onChange) onChange(files, validationResponse);
      setIsDragging(false);
    };

    const handleInputChange = async (validationResponse: FileInputValidationResponse[]) => {
      const errorFiles = validationResponse.filter((response) => response.error);
      if (errorFiles) {
        setStatus(DropZoneStatus.error);
      }

      // Check React reference is valid and that there are files
      if (!filesRef?.current?.files) return;

      const files = Array.from(filesRef.current.files); // [1]
      if (onChange) onChange(files, validationResponse);
    };

    useEffect(() => {
      if (acceptOnlyFolders && filesRef?.current !== null) {
        // Add non-standart attributes to element to be able to drop directories
        filesRef?.current.setAttribute('directory', '');
        filesRef?.current.setAttribute('webkitdirectory', '');
      }
    }, [filesRef]);

    useEffect(() => {
      if (hasError) setStatus(DropZoneStatus.error);
    }, [hasError]);

    return (
      <div>
        <div aria-label={ariaLabel} className={classes.container}>
          <FileInput
            className={classes.input}
            multiple={multiple}
            ref={filesRef}
            accept={accept}
            disabled={disableDropZone}
            extensions={extensions}
            onDragLeave={() => setIsDragging(false)}
            onDragEnter={() => setIsDragging(true)}
            {...restProps}
            limitSize={limitSize}
            fileExceedSizeMsg={fileExceedSizeMsg}
            unsupportedMediaTypeMsg={unsupportedMediaTypeMsg}
            onInputChange={handleInputChange}
            onDrop={handleOnDrop}
          />
          {renderFileUploader()}
        </div>
      </div>
    );
  },
);

/**
 * References:
 * [1] https://stackoverflow.com/questions/25333488
 * File input uses a FileList which is not an Array or Iterable
 * For better compability with ES5 we transform it the elements to a Array
 */
