import React, { HTMLAttributes } from 'react';
import cn from 'classnames';

interface OptionItemSkeletonProps extends HTMLAttributes<HTMLDivElement> {
  skeletonClasses?: string;
}

/**
 * OptionItemSkeleton represents a skeleton for the OptionItem component.
 * @author Javier Diaz<javier.diaz@evacenter.com>
 * Created on 2023-02-03
 */
export const OptionItemSkeleton = ({ className, skeletonClasses, ...restProps }: OptionItemSkeletonProps) => {
  const skeletonClassnames = cn(skeletonClasses, 'e-bg-neutral-500 e-animate-pulse');

  const classes = {
    container: cn(className, 'e-flex e-items-center e-w-full e-relative e-px-4 e-py-2 e-h-10 e-space-x-2'),
    checkbox: cn(skeletonClassnames, 'e-w-4 e-h-4 e-rounded'),
    label: cn(skeletonClassnames, 'e-w-full e-h-4 e-rounded'),
  };

  return (
    <div className={classes.container} {...restProps}>
      <div className={classes.checkbox} />
      <div className={classes.label} />
    </div>
  );
};
