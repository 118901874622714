import React from 'react';
import { Event } from '../../types';
import { localizer } from '../../utils/custom-localizer';

export const MonthEvent = ({ title, event }: Event) => {
  return (
    <div className="e-flex e-items-center e-justify-between e-w-full e-space-x-3">
      <div className="e-flex e-space-x-2 e-items-center e-w-full e-text-ellipsis e-overflow-hidden">
        <div className="e-w-2 e-h-2 e-rounded-full e-bg-neutral-300 e-flex-shrink-0" />
        <span className="e-block e-font-semibold e-truncate e-min-w-0">{title}</span>
      </div>
      {event.start ? (
        <span className="e-text-sm e-text-neutral-300">{localizer.format(event.start, 'h:mm aaa')}</span>
      ) : null}
    </div>
  );
};
