import React, { ReactNode } from 'react';
import cn from 'classnames';
import { BottomSheetContent } from '../BottomSheetContent';
import { BottomSheetBodyProps } from '../BottomSheetContent/BottomSheetBody';
import { BottomSheetFooterProps } from '../BottomSheetContent/BottomSheetFooter';
import { BottomSheetHeaderProps } from '../BottomSheetContent/BottomSheetHeader';

export interface BaseBottomSheetProps
  extends Omit<BottomSheetBodyProps, 'children'>,
    Omit<BottomSheetFooterProps, 'children'> {
  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * Specify an optional className to be added to the body section.
   */
  bodyClassName?: string;

  /**
   * Specify an optional id to be added to the primary action button.
   */
  primaryActionBtnId?: string;

  /**
   * Define the modal header
   */
  header?: Omit<BottomSheetHeaderProps, 'onCloseBtnClick'>;

  /**
   * Elements to display inside the Modal.
   */
  body: ReactNode;

  /**
   * Elements to display inside the Footer.
   */
  footer?: ReactNode;

  /**
   * Indicates if the footer must be shown, default to `true`
   */
  showFooter?: boolean;

  /**
   * The callback to get notified when the modal was closed
   */
  onClose?: () => void;
}

/**
 * Bottom sheets are a type of partial overlay that is especially suited for mobile devices and that makes temporarily important information readily available but also dismissible.
 * @author Sergio Ruiz <sergioruizdavila@gmail.com>
 * Created at 2024-02-12
 */
export const BaseBottomSheet = ({
  className,
  header,
  body,
  footer,
  onClose,
  bodyClassName,
  showFooter = true,
  isBodyScrollable = true,
}: BaseBottomSheetProps) => {
  const classes = cn(className);

  const handleCancelBtnClick = () => {
    if (onClose) onClose();
  };

  /* Render JSX */
  return (
    <BottomSheetContent className={classes}>
      {/* HEADER */}
      {header && <BottomSheetContent.Header {...header} onCloseBtnClick={handleCancelBtnClick} />}

      {/* BODY */}
      <BottomSheetContent.Body className={bodyClassName} isBodyScrollable={isBodyScrollable}>
        {body}
      </BottomSheetContent.Body>

      {/* FOOTER */}
      {showFooter && <BottomSheetContent.Footer>{footer}</BottomSheetContent.Footer>}
    </BottomSheetContent>
  );
};
