import React, { ReactNode } from 'react';
import cn from 'classnames';

export interface ItemProps {
  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * Set a date to display in the timeline.
   */
  date?: string;

  /**
   * Elements to display inside the Option.
   */
  children?: ReactNode;
}

/**
 * Represents an item to use in the Timeline component
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2024-02-02
 */
export const Item = React.forwardRef<HTMLLIElement, ItemProps>(({ className, date = '', children }, ref) => {
  const classes = {
    container: cn('e-relative e-flex e-flex-col e-gap-3 e-pl-4 e-pb-7 last:e-pb-0', className),
    date: cn('e-absolute -e-top-2 -e-left-1 e-flex e-items-center e-gap-2'),
    dot: cn('e-w-2 e-h-2', 'e-bg-primary-500', 'e-rounded-full'),
  };

  /* Render JSX */
  return (
    <li ref={ref} className={classes.container}>
      <div className={classes.date}>
        <div className={classes.dot}></div>
        <span className="e-text-lg e-text-base-white e-font-regular">{date}</span>
      </div>
      <div className="e-flex e-flex-col e-gap-3 e-pt-7">{children}</div>
    </li>
  );
});
