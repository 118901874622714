import React from 'react';
import cn from 'classnames';
import { flexRender, Header, RowData, SortDirection } from '@tanstack/react-table';
import { useTableContext } from '../../../common/contexts';
import { PinnedCellFade } from '../TableCell/PinnedCellFade/PinnedCellFade';
import { CellGradientVariant, DEFAULT_COLUMNS_SIZE } from '../../../common';
import { SortingIcon } from '../SortingIcon/SortingIcon';

/**
 * `TableHead` is the content where table headers will be rendered
 * @author Alan Chávez<alan.chavez@evacenter.com>
 * Created at 2022-09-23
 */
export const TableHead = <T extends RowData>() => {
  const classes = {
    cell: (index: number, headers: Header<T, unknown>[]) => {
      const isFirstCell = index === 0;
      const isLastCell = index === headers?.length - 1;
      const pinnedPosition = headers[index].column.getIsPinned();

      return cn(
        'e-group e-text-left e-bg-neutral-700 e-text-neutral-200 e-font-medium e-px-4 e-py-3 align-middle e-h-16 w-min-h-16',
        {
          'e-rounded-tl-lg': isFirstCell,
          'e-rounded-tr-lg': isLastCell || pinnedPosition,
          'e-sticky': pinnedPosition,
          'e-right-0': pinnedPosition === 'right',
          'e-left-0': pinnedPosition === 'left',
        },
      );
    },
    innerCell: (canSort: boolean) =>
      cn('e-grid', {
        'e-grid-cols-4 e-content-center e-cursor-pointer e-select-none': canSort,
      }),
    sortingBtn: (isSorted: string | boolean) => cn({ 'group-hover:e-inline-flex e-hidden': isSorted === false }),
  };

  const { tableInstance } = useTableContext();

  return (
    <thead className="e-sticky e-top-0 e-z-1">
      {tableInstance?.getHeaderGroups().map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header, index) => (
            <th
              style={{ minWidth: DEFAULT_COLUMNS_SIZE[header.id] ?? header.getSize() }}
              key={header.id}
              className={classes.cell(index, headerGroup.headers as Header<T, unknown>[])}>
              <PinnedCellFade position={header.column.getIsPinned()} variant={CellGradientVariant.HeaderCell} />
              {header.isPlaceholder ? null : (
                <div
                  {...{
                    className: classes.innerCell(header.column.getCanSort()),
                    onClick: header.column.getToggleSortingHandler(),
                  }}>
                  <span className="e-col-span-3 e-line-clamp-2 e-self-center">
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </span>
                  {header.column.getCanSort() && (
                    <SortingIcon
                      className={classes.sortingBtn(header.column.getIsSorted())}
                      sorting={header.column.getIsSorted() as SortDirection}
                    />
                  )}
                </div>
              )}
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
};
