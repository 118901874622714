import React from 'react';
import cn from 'classnames';
import { isNullOrUndefined, NestedSelectOptionType, OptionItemType } from '../../../common';
import { useTreeOptions } from '../hooks/useTreeOptionsContext';
import { ChildOption } from './ChildOption/ChildOption';
import { ParentOption, ParentOptionProps } from './ParentOption/ParentOption';

export interface OptionsProps {
  /**
   * Specify an optional className to be added to the component
   */
  className?: string;
  /**
   * Component children to display as the main content
   */
  children?: React.ReactNode;
  /**
   * Text to display when no options are visible
   */
  noResultsText?: string;
}

/**
 * Represents the scructure for the options
 * @author Sergio Ruiz Davila<sergio.ruiz@evacenter.com>
 * @contributor Javier Diaz<javier.diaz@evacenter.com>
 * Created on 2022-09-30
 */
export const Options = ({ className, children, noResultsText = 'No results found' }: OptionsProps) => {
  const classes = cn(
    className,
    'e-scrollbar-w-2 e-max-h-80 e-scrollbar-thumb-neutral-400 e-scrollbar-track-neutral-700 e-scrollbar-thumb-rounded-lg e-overflow-y-auto',
  );
  const { options, parentOptionConfig } = useTreeOptions();
  const isImperative = !isNullOrUndefined(options) && isNullOrUndefined(children);

  const hasNoVisibleOptions = options?.every((option) => !option?.isVisible);

  const renderOptionsContent = () => {
    if (hasNoVisibleOptions && noResultsText)
      return <div className="e-font-medium e-text-neutral-400 e-p-6">{noResultsText}</div>;

    if (isImperative) return <OptionsRecursive {...parentOptionConfig} items={options} />;

    return children;
  };

  return (
    <div className={classes} role="listbox">
      {renderOptionsContent()}
    </div>
  );
};

/**************************************
 * Option Recursive Component         *
 **************************************/
export interface OptionsRecursiveProps extends Pick<ParentOptionProps, 'parentItemClassName' | 'selectOnParentClick'> {
  items: Array<NestedSelectOptionType>;
}

/**
 * Represents the scructure for the nested options
 * @author Sergio Ruiz Davila<sergio.ruiz@evacenter.com>
 * @contributor Javier Diaz<javier.diaz@evacenter.com>
 * Created on 2022-09-30
 */
const OptionsRecursive = ({ items = [], parentItemClassName, selectOnParentClick }: OptionsRecursiveProps) => {
  const elements: any = items.map((item) => {
    if (item.type === OptionItemType.child) return <ChildOption key={item.value} className="e-pl-10" option={item} />;
    if (item.type === OptionItemType.parent) {
      return (
        <ParentOption
          parentItemClassName={parentItemClassName}
          selectOnParentClick={selectOnParentClick}
          key={item.value}
          option={item}>
          {item.options && <OptionsRecursive items={item.options} />}
        </ParentOption>
      );
    }
  });

  return elements;
};

/**
 * Reference:
 * https://anuraghazra.github.io/blog/building-a-react-folder-tree-component
 */
