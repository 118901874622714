import React from 'react';
import cn from 'classnames';
import { Cell, flexRender, Row, RowData } from '@tanstack/react-table';
import { useTableContext, RowAnimation } from '../../../common';
import { PinnedCellFade } from '../TableCell/PinnedCellFade/PinnedCellFade';

interface TableBodyProps {
  onDeleteRowAnimationEnd?: (rowId: string) => void;
}

/**
 * `TableBody` holds the main data to be visualized within a table
 * @author Alan Chávez<alan.chavez@evacenter.com>
 * Created at 2022-09-23
 */
export const TableBody = <T extends RowData>({ onDeleteRowAnimationEnd }: TableBodyProps) => {
  const classes = {
    row: (row: Row<T>) =>
      cn('e-border-b e-border-b-neutral-500', {
        'e-bg-primary-900': row.getIsSelected(),
      }),
    cell: (row: Row<T>, cell: Cell<T, unknown>) => {
      const pinnedPosition = cell.column.getIsPinned();
      const isRowSelected = row.getIsSelected();

      return cn('e-text-neutral-50 e-px-4 e-py-3', {
        [`e-sticky e-${pinnedPosition}-0 e-bg-neutral-900`]: pinnedPosition,
        'e-bg-primary-900': isRowSelected && pinnedPosition,
      });
    },
  };

  const { tableInstance } = useTableContext();

  const handleAnimationEnd = (rowId: string) => (event: any) => {
    if (event.animationName !== RowAnimation.HIDE_ROW) return;

    if (onDeleteRowAnimationEnd) onDeleteRowAnimationEnd(rowId);
  };

  return (
    <tbody className="e-z-0">
      {tableInstance?.getRowModel().rows.map((row) => (
        <tr
          data-table-row-id={row.id}
          key={row.id}
          className={classes.row(row as Row<T>)}
          onAnimationEnd={handleAnimationEnd(row.id)}>
          {row.getVisibleCells().map((cell) => (
            <td key={cell.id} className={classes.cell(row as Row<T>, cell as Cell<T, unknown>)}>
              <PinnedCellFade isRowSelected={row.getIsSelected()} position={cell.column.getIsPinned()} />
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};
