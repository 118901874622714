export enum FileExtension {
  jpg = 'jpg',
  jpeg = 'jpeg',
  png = 'png',
  JPG = 'JPG',
  JPEG = 'JPEG',
  PNG = 'PNG',
  pdf = 'pdf',
  PDF = 'PDF',
  stl = 'stl',
  STL = 'STL',
  ZIP = 'ZIP',
  PUB = 'PUB',
  pub = 'pub',
}

export enum FileType {
  PDF = 'PDF',
  CONVENTIONAL_IMAGE = 'CONVENTIONAL_IMAGE',
  DICOM = 'DICOM',
}

export const NO_DICOM_EXTENSIONS = [
  FileExtension.jpg,
  FileExtension.JPG,
  FileExtension.jpeg,
  FileExtension.JPEG,
  FileExtension.png,
  FileExtension.PNG,
  FileExtension.pdf,
  FileExtension.PDF,
  FileExtension.stl,
  FileExtension.STL,
  FileExtension.PUB,
  FileExtension.pub,
];

export const IMAGES_EXTENSIONS = [
  FileExtension.jpg,
  FileExtension.jpeg,
  FileExtension.png,
  FileExtension.JPG,
  FileExtension.JPEG,
  FileExtension.PNG,
  FileExtension.pdf,
  FileExtension.PDF,
];

export const NO_DICOM_FILES_TYPES = NO_DICOM_EXTENSIONS.map((ext) => `.${ext}`).join(', ');
export const IMAGES_FILES_TYPES = IMAGES_EXTENSIONS.map((ext) => `.${ext}`).join(', ');
