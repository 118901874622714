import React from 'react';
import cn from 'classnames';

export enum ProgressVariant {
  primary = 'primary',
  positive = 'positive',
  negative = 'negative',
}

const Variants: Record<ProgressVariant, string> = {
  [ProgressVariant.primary]: 'e-bg-primary-500',
  [ProgressVariant.positive]: 'e-bg-success-500',
  [ProgressVariant.negative]: 'e-bg-error-500',
};

export enum ProgressSize {
  xs = 'xs',
  sm = 'sm',
  base = 'base',
  lg = 'lg',
}

const Sizes: Record<ProgressSize, string> = {
  [ProgressSize.xs]: 'e-h-0.5',
  [ProgressSize.sm]: 'e-h-1',
  [ProgressSize.base]: 'e-h-2',
  [ProgressSize.lg]: 'e-h-3',
};

export interface ProgressProps {
  /**
   * Sets a max value
   */
  maxValue: number;

  /**
   * Sets the progress as a value between 0 and maxValue
   */
  value: number;

  /**
   * The shape of the component.
   */
  variant?: ProgressVariant;

  /**
   * 	Adjusts the height
   */
  size?: ProgressSize;

  /**
   * Whether the progress bar is rounded
   */
  isRounded?: boolean;

  /**
   * Specify an optional className to be added to the component.
   */
  className?: string;
}

/**
 * A Progress is a linear indicator for providing feedback about an ongoing, user-initiated process.
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-07-14
 */
export const Progress: React.FC<ProgressProps> = ({
  maxValue = 100,
  value = 0,
  size = ProgressSize.sm,
  variant = ProgressVariant.primary,
  isRounded = false,
  className,
}) => {
  const classes = {
    progress: cn(className, 'e-bg-neutral-600 e-overflow-hidden e-relative e-w-full', Sizes[size], {
      'e-rounded-full': isRounded,
    }),
    progressBar: cn(
      'e-left-0 e-absolute e-top-0',
      'e-z-1',
      'e-transition-width e-duration-100 e-ease-in-out',
      Sizes[size],
      Variants[variant],
      {
        'e-rounded-full': isRounded,
      },
    ),
  };

  const getPercentage = (): string => {
    const percentage = (100 / maxValue) * value;
    return `${percentage}%`;
  };

  return (
    <div className={classes.progress}>
      <div
        className={classes.progressBar}
        style={{ width: getPercentage() }}
        role="progressbar"
        aria-valuenow={value}
        aria-valuemin={0}
        aria-valuemax={maxValue}></div>
    </div>
  );
};
