export default [
  { label: 'Janeiro', value: 'Janeiro' },
  { label: 'Fevereiro', value: 'Fevereiro' },
  { label: 'Março', value: 'Março' },
  { label: 'Abril', value: 'Abril' },
  { label: 'Maio', value: 'Maio' },
  { label: 'Junho', value: 'Junho' },
  { label: 'Julho', value: 'Julho' },
  { label: 'Agosto', value: 'Agosto' },
  { label: 'Setembro', value: 'Setembro' },
  { label: 'Outubro', value: 'Outubro' },
  { label: 'Novembro', value: 'Novembro' },
  { label: 'Dezembro', value: 'Dezembro' },
];
