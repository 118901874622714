import React from 'react';
import cn from 'classnames';
import { findSelectedPreset, getPresetOptions } from '../utils';
import { Icon, IconCatalog } from '../../../Icon/Icon';
import { Locale } from '../../../../common';

export interface Preset {
  label: string;
  range: Array<Date | null>;
}

export interface PresetDateOptionsProps {
  /**
   * Specify an optional className to be added to the body section.
   */
  className?: string;

  /**
   * Set the locale to be used by the component
   */
  locale?: Locale;

  /**
   * Start date of the range.
   */
  startDateValue: Date | null;

  /**
   * End date of the range.
   */
  endDateValue: Date | null;

  /**
   * Provide a handler that is called if react-datepicker asigns a onChange event to the input field.
   */
  onOptionClick?: ([newStartDate, newEndDate]: [Date | null, Date | null]) => void;
}

/**
 * A component to list the preset date options (Today, Yesterday, Last 7 days, etc)
 */
export const PresetDateOptions = ({
  className,
  locale = Locale.es,
  startDateValue,
  endDateValue,
  onOptionClick,
}: PresetDateOptionsProps) => {
  const classes = {
    container: cn('e-flex e-flex-col e-text-base-white', className),
    item: (isActive: boolean) =>
      cn(
        'e-flex e-items-center e-justify-between',
        'e-px-2 e-py-2.5 e-h-10 e-min-w-40',
        'e-rounded-lg e-cursor-pointer',
        'hover:e-bg-neutral-600',
        {
          'e-bg-primary-900': isActive,
        },
      ),
  };

  const presets = getPresetOptions(locale);
  const selectedPreset = findSelectedPreset(presets, startDateValue, endDateValue);

  const handlePresetDateClick = (range: Array<Date | null>) => () => {
    if (onOptionClick) onOptionClick([range[0], range[1]]);
  };

  return (
    <div className={classes.container}>
      {presets.map((preset, index) => (
        <div
          key={index}
          className={classes.item(selectedPreset?.label === preset.label)}
          onClick={handlePresetDateClick(preset.range)}>
          <span className="e-text-sm e-text-base-white">{preset.label}</span>
          {selectedPreset?.label === preset.label && <Icon className="e-text-primary-500" icon={IconCatalog.check} />}
        </div>
      ))}
    </div>
  );
};
