import React, { ButtonHTMLAttributes, useEffect } from 'react';
import cn from 'classnames';
import { useClipboard } from 'use-clipboard-copy';
import { Icon, IconCatalog } from '../../Icon/Icon';
import { SimpleTooltip, TooltipPosition } from '../../Overlays';
import { COPIED_TIMEOUT, Locale, Translations } from '../../../common';

const translations: Translations = {
  en: {
    copied: 'Copied',
  },
  es: {
    copied: 'Copiado',
  },
  pt: {
    copied: 'Copiado',
  },
};

export enum ContactButtonSize {
  xs = 'xs',
}

enum ButtonIconSize {
  xs = 16,
}

const Sizes: Record<ContactButtonSize, string> = {
  [ContactButtonSize.xs]: 'e-py-1 e-px-2',
};

export enum ContactButtonVariant {
  tertiaryNeutral = 'tertiary neutral',
}

const Variants: Record<ContactButtonVariant, string> = {
  [ContactButtonVariant.tertiaryNeutral]: 'e-bg-base-white e-bg-opacity-10 hover:e-bg-opacity-20 e-text-base-white',
};

const DisabledVariants: Record<ContactButtonVariant, string> = {
  [ContactButtonVariant.tertiaryNeutral]: 'e-bg-transparent e-text-base-white/30',
};

export interface ContactButtonProps extends Partial<Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'>> {
  /**
   * Changes the size of the button, giving it more or less padding
   */
  size?: ContactButtonSize;

  /**
   * The shape of the component. It determines the importance in the hierarchy, for example, the contained button commands the most attention
   */
  variant?: ContactButtonVariant;

  /**
   * The locale to be used by the component
   */
  locale?: Locale;

  /**
   * The icon to display on the right side.
   */
  icon?: IconCatalog;

  /**
   * Disables the button, disallowing user interaction.
   */
  isDisabled?: boolean;

  /**
   * The content of the tooltip
   */
  tooltipContent: string;

  /**
   * The callback to get notified when the text was copied
   */
  onCopied?: (copied: boolean) => void;

  /**
   * The callback to get notified when the button was clicked
   */
  onClick?: (textCopied: string) => void;
}

/**
 * This is a button component that is used to contact the user.
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2023-07-20
 */
export const ContactButton = ({
  children,
  locale = Locale.es,
  size = ContactButtonSize.xs,
  icon = IconCatalog.phone,
  isDisabled = false,
  variant = ContactButtonVariant.tertiaryNeutral,
  tooltipContent,
  className,
  onClick,
  onCopied,
  ...restOfProps
}: ContactButtonProps) => {
  const setVariants = () => {
    if (isDisabled) return DisabledVariants[variant];
    return Variants[variant];
  };

  const classes = {
    button: cn(
      className,
      'e-flex e-items-center e-relative e-overflow-hidden e-min-w-fit e-box-content',
      'e-text-center e-whitespace-nowrap',
      'e-transition e-duration-100 e-ease-out',
      'e-rounded-lg',
      Sizes[size],
      setVariants(),
    ),
  };

  const clipboard = useClipboard({ copiedTimeout: COPIED_TIMEOUT });

  useEffect(() => {
    if (onCopied) onCopied(clipboard.copied);
  }, [clipboard.copied]);

  const handleClick = (e: any): void => {
    e.preventDefault();
    clipboard.copy(tooltipContent);
    if (onClick) onClick(tooltipContent);
  };

  /* Render JSX */
  return (
    <SimpleTooltip
      delayDuration={0}
      description={clipboard.copied ? translations[locale].copied : tooltipContent}
      position={TooltipPosition.RIGHT}
      preventClose>
      <button className={classes.button} disabled={isDisabled} onClick={handleClick} {...restOfProps}>
        {icon && <Icon icon={icon} width={ButtonIconSize[size]} height={ButtonIconSize[size]} />}
      </button>
    </SimpleTooltip>
  );
};
