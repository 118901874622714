import React from 'react';
import { BaseModal, BaseModalProps } from '../BaseModal/BaseModal';
import { ModalSize } from '../ModalContent';

export interface DownloadLoaderModalProps extends Omit<BaseModalProps, 'body' | 'size' | 'footer'> {
  /**
   * Sets the description text to put on the body section.
   */
  description?: string;

  /*
   * Sets the modal's title.
   */
  title?: string;
}

/**
 * Use this component in order to show a loader modal when downloading something.
 * @author Salvador Gonzalez<salvador.gonzalez@evacenter.com>
 * Created at 2022-12-14
 */
export const DownloadLoaderModal = ({
  description,
  className,
  onClose,
  title,
  ...restProps
}: DownloadLoaderModalProps) => {
  const handleCancelButtonClick = () => {
    if (onClose) onClose();
  };

  return (
    <BaseModal
      className={className}
      size={ModalSize.base}
      onClose={handleCancelButtonClick}
      header={{
        title,
        hasCloseBtn: true,
      }}
      bodyClassName="e-mb-8"
      body={
        <div className="flex flex-col gap-y-6 mb-6">
          <p className="e-text-neutral-50">{description}</p>
        </div>
      }
      {...restProps}
    />
  );
};
