import React, { useCallback } from 'react';
import { StepProps, StepState } from '../Step/Step';
import cn from 'classnames';

export interface StepsProps {
  /**
   * Steps children, must be of type Step
   */
  children: React.ReactElement | React.ReactElement[];

  /**
   * Define the step to be the current one from the flow, all previous steps will be have a `StepState.Success` state
   */
  currentStep: number;

  /**
   * Preferred state to use for the `currentStep`
   */
  currentStepState?: StepState;

  /**
   * Indicates wheter or not all the steps should have the state `StepState.Success`
   */
  shouldCompleteAllSteps?: boolean;

  /**
   * Class names used for external styles
   */
  className?: string;

  /**
   * Descriptive text to be read to screenreaders.
   */
  ariaLabel?: string;
}

/**
 * Steps helps to control the selection between multiple Step components
 * @author Alan Chávez <alan.chavez@evacenter.com>
 * Created at 2022-06-01
 */
export const Steps = ({
  children,
  currentStep,
  currentStepState = StepState.active,
  shouldCompleteAllSteps = false,
  className,
  ariaLabel = 'steps',
}: StepsProps) => {
  const stepsClasses = {
    container: cn(className, 'e-flex e-flex-col'),
  };

  const getStepState = useCallback(
    (step: number) => {
      if (step === currentStep && !shouldCompleteAllSteps) return currentStepState ?? StepState.active;
      if (currentStep > step || shouldCompleteAllSteps) return StepState.success;
    },
    [currentStep, shouldCompleteAllSteps, currentStepState],
  );
  const totalSteps = React.Children.count(children);

  const stepsChildren = React.Children.map(children, (child, index) => {
    const item = child as React.ReactElement<React.PropsWithChildren<StepProps>>;
    const { step } = item.props;
    const currentStep = step ?? index + 1;

    return React.cloneElement(child, {
      ...item.props,
      step: currentStep,
      isLastStep: currentStep === totalSteps,
      ...(!item.props.state && {
        state: getStepState(currentStep),
      }),
    });
  });

  return (
    <div className={stepsClasses.container} aria-label={ariaLabel}>
      {stepsChildren}
    </div>
  );
};
