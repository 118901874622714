import React, { FC } from 'react';
import cn from 'classnames';
import { Icon, IconCatalog } from '../../../Icon/Icon';

export enum PickerButtonSize {
  xs = 'xs',
  sm = 'sm',
}

const Sizes: Record<PickerButtonSize, string> = {
  [PickerButtonSize.xs]: 'e-w-6 e-h-3',
  [PickerButtonSize.sm]: 'e-w-6 e-h-3.5',
};

interface PickerButtonProps {
  /**
   * The minimum value.
   */
  min?: number;

  /**
   * The maximum value.
   */
  max: number;

  /**
   * The current value.
   */
  value?: number;

  /**
   * The size of the buttons.
   */
  size?: PickerButtonSize;

  /**
   * The handler to get notified when the up icon was clicked.
   */
  onUpIconClick?: () => void;

  /**
   * The handler to get notified when the down icon was clicked.
   */
  onDownIconClick?: () => void;

  /**
   * The flag to disable the input.
   */
  isInputDisabled?: boolean;
}

/**
 * A PickerButton enable the user to interact with and input numeric values.
 * @author Carlos Cuatin<carlos.cuatin@edenmed.com
 * Created at 2023-11-02
 */
export const PickerButton: FC<PickerButtonProps> = ({
  min = 0,
  max,
  value,
  size = PickerButtonSize.sm,
  onUpIconClick,
  onDownIconClick,
  isInputDisabled,
}) => {
  const isDownDisabled = value === min || isInputDisabled;
  const isUpDisabled = value === max || isInputDisabled;

  const classes = {
    container: cn('e-flex e-flex-col e-space-y-0.5 e-ml-1'),
    button: cn('e-bg-neutral-500 e-rounded-sm e-relative', Sizes[size]),
    buttonUpDisabled: cn({
      'hover:e-bg-neutral-400': !isUpDisabled,
      'active:e-bg-neutral-800': !isUpDisabled,
      'e-bg-neutral-700 e-cursor-default': isUpDisabled,
    }),
    buttonDownDisabled: cn({
      'hover:e-bg-neutral-400': !isDownDisabled,
      'active:e-bg-neutral-800': !isDownDisabled,
      'e-bg-neutral-700 e-cursor-default': isDownDisabled,
    }),
    icon: cn('e-absolute e-top-1/2 e-left-1/2 e-transform -e-translate-y-1/2 -e-translate-x-1/2'),
  };

  return (
    <div className={classes.container}>
      <button
        type="button"
        disabled={isUpDisabled}
        onMouseDown={onUpIconClick}
        className={`${classes.button} ${classes.buttonUpDisabled}`}>
        <Icon className={classes.icon} icon={IconCatalog.caretUp} />
      </button>
      <button
        type="button"
        disabled={isDownDisabled}
        onMouseDown={onDownIconClick}
        className={`${classes.button} ${classes.buttonDownDisabled}`}>
        <Icon className={classes.icon} icon={IconCatalog.caretDown} />
      </button>
    </div>
  );
};
