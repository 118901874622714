import React from 'react';
import { useModalContext } from '../../../common/contexts';
import { BaseModal } from './BaseModal/BaseModal';
import { ModalRoot } from './ModalRoot';
import { ConfirmationModal } from './ConfirmationModal/ConfirmationModal';

const Modals = {
  BaseModal,
  ConfirmationModal,
};

/**
 * The main container for all the Modals in the app.
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2022-08-02
 * @deprecated This wrapper will be removed along with `useModalContext`
 */
export const WrapperModal: React.FC = () => {
  const { currentModal, setCurrentModal } = useModalContext();

  const handleClose = (): void => {
    if (currentModal?.props.onClose) currentModal?.props.onClose();
    return setCurrentModal(null);
  };

  const wrapperModal = () => {
    if (currentModal) {
      const Modal = Modals[currentModal.name];
      return (
        <ModalRoot>
          {/* CURRENT MODAL */}
          <Modal {...currentModal.props} onClose={handleClose} />
        </ModalRoot>
      );
    }
    return null;
  };

  /* RENDER */
  return wrapperModal();
};
