import React, { useRef, useState, FocusEvent, forwardRef, InputHTMLAttributes, ChangeEvent, useEffect } from 'react';
import cn from 'classnames';

import { FormFieldState, Input, Key, useKeyPress } from '../../../common';
import { useNumberInputStyle } from './hooks/useNumberInputStyle';
import { PickerButton, PickerButtonSize } from './PickerButton/PickerButton';

export enum CounterInputSize {
  xs = 'xs',
  sm = 'sm',
}

const Sizes: Record<CounterInputSize, string> = {
  [CounterInputSize.xs]: 'e-h-8',
  [CounterInputSize.sm]: 'e-h-10',
};

const InputSizes: Record<CounterInputSize, string> = {
  [CounterInputSize.xs]: 'e-text-sm',
  [CounterInputSize.sm]: 'e-text-base',
};

export interface CounterInputProps
  extends Input,
    Omit<InputHTMLAttributes<HTMLInputElement>, 'required' | 'size' | 'onChange'> {
  /**
   * The value of the input.
   */
  value?: number;

  /**
   * The label to units of the input.
   */
  unit?: string;

  /**
   * The minimum value of the input.
   */
  min?: number;

  /**
   * The maximum value of the input.
   */
  max: number;

  /**
   * The step value of the input.
   */
  step?: number;

  /**
   * The size of the input.
   */
  size?: CounterInputSize;

  /**
   * Whether the picker buttons should be shown.
   */
  showPicker?: boolean;

  /**
   * The handler to get notified when the value changes.
   */
  onChange: (value: number) => void;

  /**
   * The handler to get notified when the up button is clicked.
   */
  onUpClick?: (value: number) => void;

  /**
   * The handler to get notified when the down button is clicked.
   */
  onDownClick?: (value: number) => void;
}

/**
 * A CounterInput enable the user to interact with and input numeric values.
 * @author Carlos Cuatin<carlos.cuatin@edenmed.com
 * Created at 2023-11-02
 */
export const CounterInput = forwardRef<HTMLInputElement, CounterInputProps>(
  (
    {
      id,
      isDisabled = false,
      isReadOnly = false,
      isRequired = false,
      fieldState = FormFieldState.default,
      label,
      assistiveText,
      borderless = false,
      autoComplete = 'off',
      className,
      dataTestId,
      value,
      name,
      showPicker = true,
      disabled,
      unit,
      size = CounterInputSize.sm,
      min = 0,
      max,
      step = 1,
      placeholder,
      autoFocus,
      onChange,
      onUpClick,
      onDownClick,
      onBlur,
      onFocus,
      ...restOfProps
    },
    ref,
  ) => {
    const isInputDisabled = isDisabled || disabled;
    const isInputActive = fieldState === FormFieldState.active && !isDisabled;
    const pressedArrowUp = useKeyPress(Key.ArrowUp);
    const pressedArrowDown = useKeyPress(Key.ArrowDown);
    const [isFocused, setIsFocused] = useState(autoFocus);
    const inputStyle = useNumberInputStyle({ value, isFocused, unit });
    const inputRef: any = ref || useRef(null);

    const onInputChange = (value: number): void => {
      if (!isNaN(value) && value >= min && value <= max) onChange(value);
      if (value < min) onChange(min);
      if (value > max) onChange(max);
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
      if (event.target.value === '') onChange(min);
      else onInputChange(Number(event.target.value));
    };

    const handleInputBlur = (event: FocusEvent<HTMLInputElement>): void => {
      setIsFocused(false);
      if (inputRef.current) inputRef?.current.blur();
      if (onBlur) onBlur(event);
    };

    const handleInputFocus = (event: FocusEvent<HTMLInputElement>): void => {
      setIsFocused(true);
      if (inputRef.current) inputRef?.current.focus();
      if (onFocus) onFocus(event);
    };

    const handleUpBehavior = () => {
      if (onUpClick) return onUpClick(value || 0);
      setIsFocused(true);
      const newValue = Number(value ?? min) + step;
      onInputChange(Number(newValue.toFixed(2)));
    };

    const handleDownBehavior = () => {
      if (onDownClick) return onDownClick(value || 0);
      setIsFocused(true);
      const newValue = Number(value ?? min) - step;
      onInputChange(Number(newValue.toFixed(2)));
    };

    useEffect(() => {
      if (!inputRef.current) return;
      inputRef.current.value = value?.toString() || '';
    }, []);

    useEffect(() => {
      if (pressedArrowUp && isFocused) handleUpBehavior();
    }, [pressedArrowUp]);

    useEffect(() => {
      if (pressedArrowDown && isFocused) handleDownBehavior();
    }, [pressedArrowDown]);

    const classes = {
      container: cn('e-flex e-flex-col', className),
      innerContainer: cn(
        'e-flex e-justify-between',
        'e-pt-2 e-pb-2 e-pl-2 e-pr-1',
        'e-relative e-flex e-items-center e-overflow-hidden e-rounded-lg',
        Sizes[size],
        {
          'e-text-neutral-50': !isInputDisabled,
          'e-border e-bg-neutral-700': !borderless && !isInputDisabled,
          'e-border-error-500': fieldState === FormFieldState.error && !isInputDisabled,
          'e-border-neutral-400': fieldState === FormFieldState.default && !isInputDisabled,
          'e-border-primary-500': (fieldState === FormFieldState.default && isFocused) || isInputActive,
          'e-text-neutral-300 e-bg-neutral-800 e-border-transparent e-cursor-default': isInputDisabled,
        },
      ),
      inputContainer: cn('e-w-full e-flex e-items-center', {
        'e-space-x-1': value || value === 0,
        'e-cursor-text': !isInputDisabled,
      }),
      input: cn(
        'e-font-light placeholder:e-text-neutral-300',
        'e-transition e-duration-100 e-ease-out e-outline-none e-bg-transparent',
        InputSizes[size],
      ),
      unit: cn('e-text-neutral-300 e-text-base e-font-light e-select-none'),
      label: cn('e-flex e-mb-2 e-text-neutral-50 e-text-sm e-font-regular'),
      assistiveText: cn('e-mt-2 e-text-xs e-font-extra-light', {
        'e-text-neutral-200': fieldState === FormFieldState.default,
        'e-text-error-500': fieldState === FormFieldState.error,
      }),
    };

    const btnSize = PickerButtonSize[size as keyof typeof CounterInputSize]; /* [1] */

    return (
      <div className={classes.container}>
        {label && (
          <label className={classes.label} htmlFor={id}>
            {label}
            {isRequired && '*'}
          </label>
        )}
        <div className={classes.innerContainer} tabIndex={0} onBlur={handleInputBlur} onFocus={handleInputFocus}>
          <div className={classes.inputContainer}>
            <input
              id={id}
              name={name}
              value={value}
              min={min}
              ref={inputRef}
              style={inputStyle}
              readOnly={isReadOnly}
              required={isRequired}
              data-testid={dataTestId}
              className={classes.input}
              disabled={isInputDisabled}
              autoComplete={autoComplete}
              autoFocus={isFocused}
              onChange={handleInputChange}
              placeholder={!unit && !isFocused ? placeholder : undefined}
              {...restOfProps}
            />
            {unit && <span className={classes.unit}>{unit}</span>}
          </div>

          {showPicker && (
            <PickerButton
              value={value}
              min={min}
              max={max}
              size={btnSize}
              onUpIconClick={handleUpBehavior}
              onDownIconClick={handleDownBehavior}
              isInputDisabled={isInputDisabled}
            />
          )}
        </div>
        {assistiveText && <span className={classes.assistiveText}>{assistiveText}</span>}
      </div>
    );
  },
);

/* 
[1] To prevent a bug with a reusage enum 
https://stackoverflow.com/questions/50365598/typescript-runtime-error-cannot-read-property-of-undefined-enum
*/
