import React, { useState } from 'react';
import cn from 'classnames';
import { Button, ButtonVariant, ButtonSize } from '../../../Buttons/Button/Button';
import { Progress, ProgressVariant, ProgressSize } from '../../../Progress/Progress';
import { Switch } from '../../../Controls/Switch/Switch';
import { Icon, IconCatalog } from '../../../Icon/Icon';
import { Image } from '../../../Image/Image';
import { ConfirmationRow } from './ConfirmationRow/ConfirmationRow';
import { ValidationErrorRow } from './ValidationErrorRow/ValidationErrorRow';
import { SimpleTooltip } from '../../../Overlays';

export type FileRowType = {
  id: string;
  name: string;
  fileUrl: string;
  isVisible?: boolean;
  thumbnailUrl?: string;
  uploadProgress: number;
  errorMsg?: string;
  status?: FileRowStatus;
  controller?: AbortController;
  file?: File;
};

export enum FileRowStatus {
  uploading = 'uploading',
  uploaded = 'uploaded',
  server_error = 'server-error',
  validation_error = 'validation-error',
  default = 'default',
}

export interface FileRowProps {
  /**
   * File object
   */
  file: FileRowType;

  /**
   * Set classes to wrapper element
   */
  className?: string;

  /**
   * Set current status
   */
  status?: FileRowStatus;

  /**
   * Provide a handler that is called when the cancel progress button was clicked.
   */
  onStopUploadingClick?: (fileId: string) => void;

  /**
   * Provide a handler that is called when the view button was clicked.
   */
  onViewClick?: (file: FileRowType) => void;

  /**
   * Provide a handler that is called when the retry button was clicked.
   */
  onRetryClick?: (file: FileRowType) => void;

  /**
   * Provide a handler that is called when the delete confirmation button was clicked.
   */
  onDeleteFileClick?: (fileId: string) => void;

  /*
   * Callback to execute when performing the 'delete' action on a temporal file item.
   */
  onDeleteTemporalFile?: (fileId: string) => void;

  /**
   * Provide a handler that is called when the switch button was clicked.
   */
  onSwitchChange?: (file: FileRowType) => void;

  /**
   * Provides the string to put in the Switch component
   */
  switchLabel?: string;

  /*
   * Retry button text.
   */
  retryButtonText?: string;

  /*
   * Cancel button text.
   */
  cancelButtonText?: string;

  /*
   * Confirmation description when deleting to show in the FileRow comp.
   */
  confirmationDeleteRowText?: string;

  /*
   * Confirmation description when canceling to show in the FileRow comp.
   */
  confirmationCancelRowText?: string;

  /*
   * Positive confirmation text to display in the button for FileRow comp.
   */
  positiveConfirmationText?: string;

  /*
   *  Negative confirmation text to display in the button for FileRow comp.
   */
  negativeConfirmationText?: string;

  /*
   * Hides/shows the Switch component to update the file.
   */
  showUpdateFileButton?: boolean;

  /**
   * Hides/shows the view file button
   */
  showViewFileButton?: boolean;
}

const PROGRESS_MAX_VALUE = 100;

/**
 * This component represent the file row
 * @author Sergio Ruiz <sergio.ruiz@evacenter.com>
 * Created at 2022-11-07
 */
export const FileRow = ({
  file,
  className,
  status = FileRowStatus.uploaded,
  onRetryClick,
  onStopUploadingClick,
  onDeleteFileClick,
  onDeleteTemporalFile,
  onSwitchChange,
  onViewClick,
  switchLabel,
  retryButtonText,
  cancelButtonText,
  confirmationDeleteRowText,
  confirmationCancelRowText,
  positiveConfirmationText,
  negativeConfirmationText,
  showUpdateFileButton = true,
  showViewFileButton = true,
}: FileRowProps) => {
  const fileRowClass = cn(className, 'e-w-full');
  const [openConfirmationRow, setOpenConfirmationRow] = useState(false);

  const handleSwitchChange = (checked: boolean) => {
    if (onSwitchChange) onSwitchChange({ ...file, isVisible: checked });
  };

  const handleViewClick = () => {
    if (onViewClick) onViewClick(file);
  };

  const handleRetryClick = () => {
    if (onRetryClick) onRetryClick(file);
  };

  const handleOpenConfirmationRow = () => setOpenConfirmationRow(true);
  const handleCancelAction = () => setOpenConfirmationRow(false);
  const handleConfirmAction = () => {
    switch (status) {
      case FileRowStatus.uploaded:
      case FileRowStatus.default:
        if (onDeleteFileClick) onDeleteFileClick(file.id);
        break;

      default:
        if (onStopUploadingClick) onStopUploadingClick(file.id);
        break;
    }
  };

  const generateFileExtension = (fileName: string) => {
    if (!fileName) return;
    return fileName.split('.').pop();
  };

  const renderFileRow = () => {
    switch (status) {
      case FileRowStatus.uploaded:
        return (
          <div className="e-flex e-items-center e-justify-between e-mb-4 e-w-full">
            <div style={{ minWidth: '80px' }} className="e-flex e-items-center e-w-full">
              {/* THUMBNAIL */}
              <div className="e-relative e-w-8 e-h-8 e-mr-2 e-flex-shrink-0">
                <Image
                  src={file.thumbnailUrl}
                  alt={file.name}
                  noImg={
                    <div className="e-bg-secondary-200 e-w-full e-h-full e-rounded-lg e-flex e-items-center e-flex-col e-justify-center">
                      <span className="e-text-secondary-800 e-text-2xs e-font-semi-bold e-leading-none">
                        {generateFileExtension(file.name)}
                      </span>
                    </div>
                  }
                  hasMaxWidth
                />
                <div className="e-absolute e-border-2 e-top-5 -e-left-1.5 e-border-neutral-900 e-rounded-full e-bg-success-500 e-w-4 e-h-4">
                  <Icon className="e-text-neutral-50 e-w-3 e-h-3" icon={IconCatalog.check} />
                </div>
              </div>

              {/* FILE NAME */}
              <SimpleTooltip description={file.name}>
                <div className="e-text-base-white e-font-semi-bold e-truncate e-w-full e-text-xs">{file.name}</div>
              </SimpleTooltip>
            </div>

            {/* ACTIONS */}
            <div className="e-flex e-items-center e-space-x-3 e-min-w-fit e-ml-5">
              {openConfirmationRow ? (
                <ConfirmationRow
                  text={confirmationDeleteRowText}
                  negativeConfirmationText={negativeConfirmationText}
                  positiveConfirmationText={positiveConfirmationText}
                  onConfirmClick={handleConfirmAction}
                  onCancelClick={handleCancelAction}
                />
              ) : (
                <>
                  {Boolean(onSwitchChange) && showUpdateFileButton && (
                    <Switch value={file.isVisible} onChange={handleSwitchChange} label={switchLabel} />
                  )}

                  {showViewFileButton && (
                    <Button
                      variant={ButtonVariant.ghost}
                      size={ButtonSize.xs}
                      onClick={handleViewClick}
                      endIcon={IconCatalog.view}
                    />
                  )}

                  {onDeleteFileClick && (
                    <Button
                      variant={ButtonVariant.ghost}
                      size={ButtonSize.xs}
                      onClick={handleOpenConfirmationRow}
                      endIcon={IconCatalog.trash}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        );

      case FileRowStatus.server_error:
        return (
          <div className="e-flex e-items-center e-mb-4 e-w-full">
            <div className="e-flex e-items-center e-w-full">
              {/* THUMBNAIL */}
              <div className="e-relative e-w-8 e-h-8 e-mr-2 e-flex-shrink-0">
                <Image
                  src={file.thumbnailUrl}
                  alt={file.name}
                  noImg={
                    <div className="e-bg-base-black e-border e-border-error-500 e-w-full e-h-full e-rounded-lg e-flex e-items-center e-flex-col e-justify-center">
                      <span className="e-text-base-white e-text-2xs e-font-semi-bold e-leading-none">
                        {generateFileExtension(file.name)}
                      </span>
                    </div>
                  }
                  hasMaxWidth
                />
                <div className="e-absolute e-border-2 e-top-5 -e-left-1.5 e-border-neutral-900 e-rounded-full e-bg-error-500 e-w-4 e-h-4">
                  <Icon className="e-text-neutral-50 e-w-3 e-h-3" icon={IconCatalog.warning} />
                </div>
              </div>

              <div style={{ minWidth: '80px' }} className=" e-flex e-items-start e-flex-col e-w-full e-space-y-1">
                {/* FILE NAME */}
                <div className="e-text-base-white e-font-semi-bold e-truncate e-w-full e-text-xs">{file.name}</div>

                {/* ERROR MSG */}
                <div className="e-text-error-500 e-font-regular e-truncate e-w-full e-text-2xs">{file.errorMsg}</div>
              </div>

              <Button className="e-ml-3" variant={ButtonVariant.ghost} size={ButtonSize.xs} onClick={handleRetryClick}>
                {retryButtonText}
              </Button>
            </div>
          </div>
        );

      case FileRowStatus.validation_error:
        return <ValidationErrorRow file={file} errorMsg={file.errorMsg || ''} onDeleteClick={onDeleteTemporalFile} />;

      case FileRowStatus.uploading:
        return (
          <div className="e-flex e-items-center e-mb-4">
            {/* THUMBNAIL */}
            <div className="e-w-8 e-h-8 e-mr-2 e-flex-shrink-0">
              <Image
                src={file.thumbnailUrl}
                alt={file.name}
                noImg={<div className="e-bg-neutral-500 e-w-full e-h-full e-rounded-lg"></div>}
                hasMaxWidth
              />
            </div>

            {/* BASIC INFO */}
            <div style={{ minWidth: '80px' }} className="e-flex e-flex-col e-w-full">
              <div className="e-flex e-items-center e-space-x-1 e-mb-2">
                <div className="e-text-base-white e-text-2xs">{file.uploadProgress}%</div>
                <div className="e-text-base-white e-text-2xs">·</div>
                <div className="e-text-neutral-200 e-text-2xs e-truncate e-w-full">{file.name}</div>
              </div>
              <Progress
                value={file.uploadProgress}
                maxValue={PROGRESS_MAX_VALUE}
                size={ProgressSize.sm}
                variant={ProgressVariant.primary}
                isRounded
              />
            </div>

            {/* ACTIONS */}
            <div className="e-flex e-items-center e-ml-10 e-space-x-7">
              {openConfirmationRow ? (
                <ConfirmationRow
                  text={confirmationCancelRowText}
                  negativeConfirmationText={negativeConfirmationText}
                  positiveConfirmationText={positiveConfirmationText}
                  onConfirmClick={handleConfirmAction}
                  onCancelClick={handleCancelAction}
                />
              ) : (
                <Button variant={ButtonVariant.ghost} size={ButtonSize.xs} onClick={handleOpenConfirmationRow}>
                  {cancelButtonText}
                </Button>
              )}
            </div>
          </div>
        );

      default:
        return (
          <div className="e-flex e-items-center e-justify-between e-mb-4 e-w-full">
            <div style={{ minWidth: '80px' }} className="e-flex e-items-center e-w-full">
              {/* THUMBNAIL */}
              <div className="e-w-8 e-h-8 e-mr-2 e-flex-shrink-0">
                <Image
                  src={file.thumbnailUrl}
                  alt={file.name}
                  noImg={
                    <div className="e-bg-secondary-200 e-w-full e-h-full e-rounded-lg e-flex e-items-center e-flex-col e-justify-center">
                      <span className="e-text-secondary-800 e-text-2xs e-font-semi-bold e-leading-none">
                        {generateFileExtension(file.name)}
                      </span>
                    </div>
                  }
                  hasMaxWidth
                />
              </div>

              {/* FILE NAME */}
              <SimpleTooltip description={file.name}>
                <div className="e-text-base-white e-font-semi-bold e-truncate e-w-full e-text-xs">{file.name}</div>
              </SimpleTooltip>
            </div>

            {/* ACTIONS */}
            <div className="e-flex e-items-center e-space-x-3 e-min-w-fit e-ml-5">
              {openConfirmationRow ? (
                <ConfirmationRow
                  text={confirmationDeleteRowText}
                  negativeConfirmationText={negativeConfirmationText}
                  positiveConfirmationText={positiveConfirmationText}
                  onConfirmClick={handleConfirmAction}
                  onCancelClick={handleCancelAction}
                />
              ) : (
                <>
                  {Boolean(onSwitchChange) && showUpdateFileButton && (
                    <Switch value={file.isVisible} onChange={handleSwitchChange} label={switchLabel} />
                  )}

                  {showViewFileButton && (
                    <Button
                      variant={ButtonVariant.ghost}
                      size={ButtonSize.xs}
                      onClick={handleViewClick}
                      endIcon={IconCatalog.view}
                    />
                  )}

                  {onDeleteFileClick && (
                    <Button
                      variant={ButtonVariant.ghost}
                      size={ButtonSize.xs}
                      onClick={handleOpenConfirmationRow}
                      endIcon={IconCatalog.trash}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        );
    }
  };

  return <div className={fileRowClass}>{renderFileRow()}</div>;
};
