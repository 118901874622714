import React, { forwardRef, KeyboardEvent, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { Key, FormFieldState, Translations, Locale } from '../../../../common';
import { DigitInput } from '../DigitInput/DigitInput';

const translations: Translations = {
  en: {
    dayLabel: 'Day',
    monthLabel: 'Month',
    yearLabel: 'Year',
    dayPlaceholder: 'dd',
    monthPlaceholder: 'mm',
    yearPlaceholder: 'yyyy',
  },
  es: {
    dayLabel: 'Día',
    monthLabel: 'Mes',
    yearLabel: 'Año',
    dayPlaceholder: 'dd',
    monthPlaceholder: 'mm',
    yearPlaceholder: 'aaaa',
  },
  pt: {
    dayLabel: 'Dia',
    monthLabel: 'Mês',
    yearLabel: 'Ano',
    dayPlaceholder: 'dd',
    monthPlaceholder: 'mm',
    yearPlaceholder: 'aaaa',
  },
};

export interface DOBVerificationInputProps {
  /**
   * Specify an optional test ID to use on e2e tests.
   */
  dataTestId?: string;

  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * The locale to be used by the component
   */
  locale: Locale;

  /**
   * Initial value for the MultiDigit Input.
   */
  defaultValue?: Array<string>;

  /**
   * Set if the digit inputs are loading.
   */
  isLoading?: boolean;

  /**
   * Set the Text Input state
   */
  fieldState?: FormFieldState;

  /**
   * Set an assistive text
   */
  assistiveText?: string;

  /**
   * The handler to get notified when the value changes.
   */
  onChange: (value: Array<string>) => void;
}

/**
 * An DOBVerificationInput allows the user to interact with and input birthday date verification.
 * @author Sergio Ruiz<sergio.ruiz@evacenter.com>
 * Created at 2024-06-18
 */
export const DOBVerificationInput = forwardRef<HTMLInputElement, DOBVerificationInputProps>(
  (
    {
      isLoading = false,
      fieldState = FormFieldState.default,
      className,
      dataTestId,
      locale,
      defaultValue = [],
      assistiveText,
      onChange,
    },
    ref,
  ) => {
    const classes = {
      container: cn('e-inline-flex e-flex-col', className),
      assistiveText: cn('e-mt-4 e-text-sm e-text-center', {
        'e-text-neutral-200': fieldState === FormFieldState.default,
        'e-text-error-500': fieldState === FormFieldState.error,
      }),
    };

    const INPUTS_AMOUNT = 3;
    const inputsRef = useRef<Array<HTMLInputElement | null>>([]);
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
      if (inputsRef.current[0]) inputsRef.current[0].focus();
    }, []);

    const handleOnChange = (index: number) => (digit: string) => {
      const LAST_INDEX = INPUTS_AMOUNT - 1;
      const newValueArray = [...value];
      newValueArray[index] = digit;
      setValue([...newValueArray]);
      const isTwoDigits = digit.length === 2;
      const isFourDigits = digit.length === 4;

      // Move focus to the next input when a digit is filled
      if (digit && isTwoDigits && index < LAST_INDEX) inputsRef.current[index + 1]?.focus();

      // Just emit the value when all the digits are filled
      if (index === LAST_INDEX && isFourDigits) {
        onChange([...newValueArray]);
        inputsRef.current[index]?.blur();
      }
    };

    const handleKeyDown = (index: number) => (event: KeyboardEvent) => {
      if (event.key === Key.Backspace && !value[index] && index > 0) {
        inputsRef.current[index - 1]?.focus();
      }
    };

    /* Render JSX */
    return (
      <div ref={ref} className={classes.container} data-testid={dataTestId}>
        <div className="e-flex e-gap-2">
          <div className="e-flex e-flex-col e-items-center e-gap-2">
            <span className="e-text-sm e-text-base-white">{translations[locale].dayLabel}</span>
            <DigitInput
              ref={(input) => {
                inputsRef.current[0] = input;
              }}
              value={value[0] || ''}
              placeholder={translations[locale].dayPlaceholder}
              onChange={handleOnChange(0)}
              onKeyDown={handleKeyDown(0)}
              maxLength={2}
              isLoading={isLoading}
              fieldState={fieldState}
            />
          </div>
          <div className="e-flex e-flex-col e-items-center e-gap-2">
            <span className="e-text-sm e-text-base-white">{translations[locale].monthLabel}</span>
            <DigitInput
              ref={(input) => {
                inputsRef.current[1] = input;
              }}
              value={value[1] || ''}
              placeholder={translations[locale].monthPlaceholder}
              onChange={handleOnChange(1)}
              onKeyDown={handleKeyDown(1)}
              maxLength={2}
              isLoading={isLoading}
              fieldState={fieldState}
            />
          </div>
          <div className="e-flex e-flex-col e-items-center e-gap-2">
            <span className="e-text-sm e-text-base-white">{translations[locale].yearLabel}</span>
            <DigitInput
              ref={(input) => (inputsRef.current[2] = input)}
              value={value[2] || ''}
              placeholder={translations[locale].yearPlaceholder}
              onChange={handleOnChange(2)}
              onKeyDown={handleKeyDown(2)}
              maxLength={4}
              isLoading={isLoading}
              fieldState={fieldState}
            />
          </div>
        </div>
        {assistiveText && <span className={classes.assistiveText}>{assistiveText}</span>}
      </div>
    );
  },
);
