import { Formats } from 'react-big-calendar';

export const customFormats = {
  weekHeaderFormat: 'MMMM, yyyy',
};

export const formats: Formats = {
  dayFormat: 'EEEE dd',
  dayHeaderFormat: 'MMMM dd, yyyy',
  monthHeaderFormat: 'MMMM, yyyy',
  timeGutterFormat: 'hh:mm aa',
};
