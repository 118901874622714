import React, { FocusEvent, Fragment, useEffect, useMemo, useState } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import cn from 'classnames';
import { SelectDropdownOption } from '../SelectDropdownStateless/SelectDropdownOption/SelectDropdownOption';
import { FormFieldState, SelectOption, setMaxHeightByOptions } from '../../../common';
import { Icon, IconCatalog } from '../../Icon/Icon';
import { Scrollable } from '../../Scrollable/Scrollable';
import { Spinner, SpinnerSize, SpinnerVariant } from '../../Loaders';
import type { SelectProps } from '../Select/Select';

export interface SelectComboboxProps extends SelectProps {
  /**
   * Specify an optional text to show when no results are found
   */
  noResultsText?: string;
}

/**
 * `SelectCombobox` represents a list from where to select an option
 * @author Alan Chávez<alan.chavez@evacenter.com>
 * @deprecated Use `SelectDropdown` instead
 * Created at 2023-05-10
 */
export const SelectCombobox = React.forwardRef<HTMLInputElement, SelectComboboxProps>(
  (
    {
      assistiveText,
      className = '',
      fieldState = FormFieldState.default,
      id,
      isDisabled = false,
      isFullWidth = false,
      isLoading = false,
      isRequired = false,
      label,
      name,
      noResultsText = 'No results found',
      onBlur,
      onChange,
      onFocus,
      options = [],
      placeholder,
      value,
    },
    forwardedRef,
  ) => {
    const defaultOption = useMemo(() => options.find((option) => option.value === value), [options, value]);
    const [selectedOption, setSelectedOption] = useState(defaultOption);
    const [query, setQuery] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const isSelectActive = fieldState === FormFieldState.active && !isDisabled;

    const getMatchingQueryOptions = (option: SelectOption) =>
      option.label.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, ''));

    const filteredOptions = useMemo(() => {
      if (query === '') return options;
      return options.filter(getMatchingQueryOptions);
    }, [options, query]);

    const getInputContainerStyles = () => {
      if (isDisabled)
        return cn('e-text-neutral-300 e-bg-neutral-800 e-border-transparent e-cursor-default e-pointer-events-none');

      return cn('e-bg-neutral-700 e-text-neutral-50', {
        'e-border-neutral-400': fieldState === FormFieldState.default,
        'e-border-error-500': fieldState === FormFieldState.error,
      });
    };

    const classes = {
      container: cn(className),
      inputContainer: cn(
        'e-flex e-p-2 e-pl-4 e-cursor-pointer',
        'e-border e-rounded-lg e-appearance-none e-outline-none e-truncate',
        'e-h-10',
        {
          'e-w-full ': isFullWidth,
          'e-border-primary-500': isFocused || isSelectActive,
        },
        getInputContainerStyles(),
      ),
      input: cn(
        'e-w-full e-transition e-duration-100 e-ease-out e-outline-none e-bg-transparent',
        'e-text-base e-font-regular placeholder:e-text-neutral-300 placeholder:e-font-light placeholder:e-text-base',
      ),
      optionsContent: cn(
        'e-shadow-lg e-bg-neutral-600 e-rounded-lg e-z-50 e-absolute e-top-full e-mt-1.5 e-left-0 e-w-full',
        'e-bg-neutral-600 e-rounded-lg',
        'e-border e-border-neutral-500',
        'e-text-base-white e-text-caption e-text-center',
        'e-transition e-duration-100 e-ease-out',
      ),
      option: (item: SelectOption, active: boolean) =>
        cn('e-flex e-space-x-3 e-cursor-pointer', 'e-px-3 e-py-4', 'hover:e-bg-neutral-700', {
          'e-bg-neutral-700': active || item.value === value,
        }),
      endContainer: cn('e-flex e-items-center e-space-x-2 e-self-stretch e-flex-shrink-0 e-pl-1 e-ml-auto'),
      assistiveText: cn('e-mt-2 e-text-xs e-font-extra-light', {
        'e-text-neutral-200': fieldState === FormFieldState.default,
        'e-text-error-500': fieldState === FormFieldState.error,
      }),
    };

    const handleInputBlur = (event: FocusEvent<HTMLDivElement>): void => {
      setIsFocused(false);
      if (onBlur) onBlur(event);
    };

    const handleInputFocus = (event: FocusEvent<HTMLDivElement>): void => {
      if (isDisabled) return;

      setIsFocused(true);
      if (onFocus) onFocus(event);
    };

    const handleOptionChange = (selectedValue: string) => {
      const selectedOption = getOptionByValue(selectedValue);

      setSelectedOption(selectedOption);
      if (onChange) onChange(selectedOption as SelectOption, name);
    };

    const getOptionByValue = (value: string) => options?.find((option) => option?.value === value);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => setQuery(event.target.value);

    const handleClearQuery = () => setQuery('');

    const renderOptions = () => {
      const noResultsFound = filteredOptions.length === 0 && query !== '';

      if (noResultsFound) return <span className="e-text-neutral-400 e-p-3 e-block">{noResultsText}</span>;

      return filteredOptions.map((option) => (
        <SelectDropdownOption
          key={option.value}
          className={({ active }: { active: boolean }) => classes.option(option, active)}
          value={option?.value}>
          {option.label}
        </SelectDropdownOption>
      ));
    };

    useEffect(() => {
      setSelectedOption(defaultOption);
    }, [defaultOption]);

    return (
      <Combobox
        as="div"
        className={classes.container}
        value={selectedOption?.value || value}
        onChange={handleOptionChange}>
        {label && (
          <Combobox.Label htmlFor={id} className="e-mb-2 e-text-neutral-50 e-text-sm e-font-regular">
            {label}
            {isRequired && '*'}
          </Combobox.Label>
        )}
        <div className="e-relative">
          <div className={classes.inputContainer}>
            <Combobox.Input
              ref={forwardedRef}
              className={classes.input}
              displayValue={(value: string) => getOptionByValue(value)?.label || ''}
              placeholder={placeholder}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              autoComplete="off"
            />

            <div className={classes.endContainer}>
              {/* LOADING SPINNER */}
              {isLoading && <Spinner size={SpinnerSize.xs} variant={SpinnerVariant.neutral} />}

              {/* CHEVRON ICON */}
              <Combobox.Button>
                {({ open }) => (
                  <Icon icon={open ? IconCatalog.chevronUp : IconCatalog.chevronDown} aria-hidden="true" />
                )}
              </Combobox.Button>
            </div>
          </div>
          <Transition
            as={Fragment}
            leave="e-transition e-ease-in e-duration-100"
            leaveFrom="e-opacity-100"
            leaveTo="e-opacity-0"
            afterLeave={handleClearQuery}>
            <Combobox.Options>
              <Scrollable
                className={classes.optionsContent}
                style={setMaxHeightByOptions({
                  listLength: filteredOptions?.length,
                  maxOptions: 7,
                  maxHeight: '300px',
                })}>
                {renderOptions()}
              </Scrollable>
            </Combobox.Options>
          </Transition>
        </div>
        {assistiveText && <span className={classes.assistiveText}>{assistiveText}</span>}
      </Combobox>
    );
  },
);
