import React from 'react';
import { Pagination, PaginationProps } from '../../Pagination/Pagination';
import { useTableContext } from '../../../common/contexts';
import { getTotalPageCount } from '../../../common';

const PAGE_INDEX_OFFSET = 1;

export interface TablePaginationProps {
  /**
   * Specify an optional className to be added to the Table container.
   */
  className?: string;
  /**
   * Provides access to the page size handler from a given `ReactNode`
   */
  paginationHelper: PaginationProps['paginationHelper'];
}

/**
 * `TablePagination` displays paginated elements which interacts with a table instance
 * @author Alan Chávez<alan.chavez@evacenter.com>
 * Created at 2022-09-23
 */
export const TablePagination = ({ className, paginationHelper }: TablePaginationProps) => {
  const { tableInstance } = useTableContext();
  const { pageIndex, pageSize } = tableInstance?.getState()?.pagination ?? {};
  const totalCount = tableInstance?.getPageCount() as number;
  const isNextPageEnabled = (pageIndex as number) < getTotalPageCount(totalCount, pageSize as number) - 1;

  const handlePageChange = (pageNumber?: number) => {
    if (!pageNumber) return;
    tableInstance?.setPageIndex(pageNumber - PAGE_INDEX_OFFSET);
  };
  const handlePageSizeChange = (pageSize: number) => tableInstance?.setPageSize(pageSize);

  return (
    <Pagination
      className={className}
      currentPage={(pageIndex as number) + PAGE_INDEX_OFFSET}
      pageSize={pageSize as number}
      totalCount={totalCount}
      enableNextPage={isNextPageEnabled}
      enablePrevPage={tableInstance?.getCanPreviousPage()}
      onNextClick={tableInstance?.nextPage}
      onPreviousClick={tableInstance?.previousPage}
      onPageChange={handlePageChange}
      onPageSizeChange={handlePageSizeChange}
      paginationHelper={paginationHelper}
    />
  );
};
