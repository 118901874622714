import React from 'react';
import cn from 'classnames';

export enum SpecialIconCatalog {
  beta = 'beta',
}

export interface SpecialIconProps {
  /**
   * The icon to display
   */
  icon: SpecialIconCatalog;

  /**
   * Specify an optional className to be added to the component.
   */
  className?: string;
}

/**
 * The special icons catalog
 * @author Jesus Bossa<x@jesusbossa.dev>
 * Created at 2023-04-27
 */
export const SpecialIcon = ({ icon = SpecialIconCatalog.beta, className }: SpecialIconProps) => {
  switch (icon) {
    case SpecialIconCatalog.beta:
      return (
        <div
          className={cn(
            className,
            'e-items-center e-relative e-overflow-hidden e-w-max',
            'e-py-0.5 e-px-2',
            'e-text-center e-whitespace-nowrap e-text-2xs e-font-bold',
            'e-rounded-2xl e-bg-error-500 e-border-error-900 e-border e-text-error-900',
          )}>
          <span>{SpecialIconCatalog.beta.toUpperCase()}</span>
        </div>
      );
  }
};
