import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { ChildNestedSelectOption } from '../../../../common';
import { Checkbox, CheckboxProps } from '../../../Controls/Checkbox/Checkbox';
import { useTreeOptions } from '../../hooks/useTreeOptionsContext';
import { OptionItem, OptionItemLabel } from '../../../Inputs/OptionLabel/OptionLabel';

export interface ChildOptionProps extends CheckboxProps {
  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * The option config
   */
  option: ChildNestedSelectOption;
}

/**
 * Represent the Option (child) within the DropdownTree (NestedMultiSelect)
 * @author Sergio Ruiz Davila<sergio.ruiz@evacenter.com>
 * Created at 2022-09-30
 */
export const ChildOption = ({ option, className }: ChildOptionProps) => {
  const { onSelectedOptionChange, pickNextColor, changeChildCheck, colorsRef, defaultColors, colored } =
    useTreeOptions();
  const [color, setColor] = useState('');

  useEffect(() => {
    if (!colored || color) return;

    let currentColor;

    if (defaultColors) {
      currentColor = defaultColors[option.value];
      if (currentColor) setColor(currentColor.value);
    }
  }, [defaultColors]);

  useEffect(() => {
    if (color || !colored || !colorsRef.current) return;
    const currentColor = colorsRef.current[option.value];

    if (currentColor) setColor(currentColor.value);
  }, [colorsRef.current]);

  const classes = cn(className, 'e-flex e-items-center e-px-3 e-py-3 hover:e-bg-neutral-700 e-space-x-3');

  const assignColor = () => {
    const currentColor = pickNextColor(option.value);
    setColor(currentColor || '');
  };

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    if (!color && colored) assignColor();

    const { checked } = event.target;

    const output = changeChildCheck(option.value, checked);
    onSelectedOptionChange(option, checked, output);
  };

  const handleCheckboxClick = (event: React.MouseEvent) => event.stopPropagation();

  const checkbox = useMemo(
    () => (
      <Checkbox
        id={option.value}
        color={color}
        onChange={handleCheckboxChange}
        onClick={handleCheckboxClick}
        checked={option.checked}
      />
    ),
    [option.checked, option.value, color],
  );

  if (!option.isVisible) return null;

  return (
    <OptionItem role="option" className={classes} aria-selected={option.checked}>
      {checkbox}
      <OptionItemLabel>{option.label}</OptionItemLabel>
    </OptionItem>
  );
};
