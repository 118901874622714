import React from 'react';
import { ResourceHeaderProps } from 'react-big-calendar';

interface ResourceHeaderRenderers {
  /**
   * Render function to customize the resource header component.
   */
  resourceHeaderRenderer?: (resource: ResourceHeaderProps) => JSX.Element;
}

/**
 * A component that renders the resource header.
 * @author Javier Diaz<javier.diaz@evacenter.com>
 * Created on 2023-02-22
 */
export const ResourceHeader =
  (resourceHeaderRenderer: ResourceHeaderRenderers['resourceHeaderRenderer']) => (props: ResourceHeaderProps) => {
    return (
      <div className="e-relative e-flex e-items-center">
        {resourceHeaderRenderer ? (
          resourceHeaderRenderer(props)
        ) : (
          <span className="e-text-base-white">{props.label}</span>
        )}
      </div>
    );
  };
