import React from 'react';

import { cn } from '../..';

const classes = {
  container: cn('e-flex e-flex-col e-gap-2 e-w-full e-items-start e-bg-neutral-900 e-px-5 e-py-3'),
  children: cn('e-rounded-sm e-bg-neutral-700 e-animate-skeleton'),
};

export const StudyInformationPatientHeaderSkeleton = () => (
  <div className={classes.container}>
    <div className={cn(classes.children, 'e-h-4 e-w-28')}></div>
    <div className={cn(classes.children, 'e-h-6 e-w-44')}></div>
    <div className={cn(classes.children, 'e-h-4 e-w-20')}></div>
  </div>
);
