import React from 'react';
import { ICON_SIZE_DEFAULT } from '../../../common';
import { AlertCard, AlertCardProps, AlertCardVariant } from '../../AlertCard/AlertCard';
import { IconCatalog } from '../../Icon/Icon';

interface WarningStateProps extends Omit<AlertCardProps, 'icon' | 'iconSize' | 'variant'> {}

export const WarningState = (props: WarningStateProps) => {
  return (
    <AlertCard {...props} icon={IconCatalog.warning} iconSize={ICON_SIZE_DEFAULT} variant={AlertCardVariant.warning} />
  );
};
