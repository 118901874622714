import React, { useEffect, useRef } from 'react';
import cn from 'classnames';
import { useWindowSize } from '../../../common';
import { Icon, IconCatalog } from '../../Icon/Icon';
import { TagV2, TagV2Appearance, TagV2Type, TagV2Size } from '../../TagV2/TagV2';
import { Thumbnail } from './Thumbnail/Thumbnail';

export enum StudyType {
  image = 'image',
  video = 'video',
  ecg = 'ecg',
  pdf = 'pdf',
  stl = 'stl',
  generic = 'generic',
}

export interface StudyCardProps {
  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * Whether the study card is active or not.
   */
  isActive?: boolean;

  /**
   * The name of the study.
   */
  studyName: string;

  /**
   * The day of the study.
   */
  studyDay: string;

  /**
   * The date of the study.
   */
  studyDate: string;

  /**
   * The type of the study.
   */
  studyType?: StudyType;

  /**
   * If the study type is image, the source URL of the image.
   */
  imageSrcUrl?: string;

  /**
   * The date of the study.
   */
  sinceDate?: string;

  /**
   * The modality of the study (TC, RM, etc)
   */
  modality: string;

  /**
   * The information of the practitioner who made the study.
   */
  practitioner?: string;

  /**
   * The function to be called when the study card is clicked.
   */
  onClick?: () => void;
}

const WIDTH_BREAKPOINT = 330;

/**
 * This component is used to show the patient's study information
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2024-01-22
 */
export const StudyCard = ({
  className,
  studyName,
  studyDate,
  studyType = StudyType.generic,
  sinceDate,
  studyDay,
  imageSrcUrl,
  modality,
  practitioner,
  isActive,
  onClick,
}: StudyCardProps) => {
  const node = useRef<HTMLDivElement>(null);
  const [nodeWidth, setNodeWidth] = React.useState(0);
  const { windowWidth } = useWindowSize();

  const isSmallScreen = nodeWidth > WIDTH_BREAKPOINT;

  // Get node width when window width changes
  useEffect(() => {
    if (!node.current) return;

    setNodeWidth(node.current.getBoundingClientRect().width);
  }, [windowWidth]);

  const classes = {
    container: cn('e-flex e-items-center e-justify-between', 'e-rounded-lg e-p-3', 'e-min-h-[112px]', className, {
      'e-bg-neutral-800': !isActive,
      'e-bg-primary-900 e-border e-border-primary-500': isActive,
    }),
  };

  const handleClick = () => {
    if (!onClick) return;
    onClick();
  };

  /* Render JSX */
  return (
    <div ref={node} className={classes.container} onClick={handleClick}>
      {/* STUDY INFO */}
      <div className="e-flex e-flex-col e-gap-2 e-pr-4">
        <div className="e-text-neutral-50 e-text-sm e-font-medium e-line-clamp-2">{studyName}</div>
        <div className="e-flex e-flex-col e-gap-1">
          <div className="e-flex e-items-center e-gap-2">
            <Icon className="e-text-neutral-400 e-shrink-0" icon={IconCatalog.studyOutline} width={16} height={16} />
            <div className="e-text-neutral-200 e-text-xs e-font-light e-line-clamp-1">{modality}</div>
          </div>
          <div className="e-flex e-items-center e-gap-2">
            <Icon className="e-text-neutral-400 e-flex-shrink-0" icon={IconCatalog.calendar} width={16} height={16} />
            <div className="e-flex e-items-center e-flex-shrink-0">
              {isSmallScreen && studyDay && (
                <span className="e-text-neutral-200 e-text-xs e-font-light e-mr-1">{studyDay}</span>
              )}
              <span className="e-text-neutral-200 e-text-xs e-font-light">{studyDate}</span>
            </div>
            {sinceDate && (
              <TagV2 type={TagV2Type.neutral} size={TagV2Size['2xs']} appearance={TagV2Appearance.moderateAlt}>
                {sinceDate}
              </TagV2>
            )}
          </div>
          {practitioner && (
            <div className="e-flex e-items-center e-gap-2">
              <Icon className="e-text-neutral-400 e-shrink-0" icon={IconCatalog.practitioner} width={16} height={16} />
              <div className="e-text-neutral-200 e-text-xs e-font-light e-line-clamp-1">{practitioner}</div>
            </div>
          )}
        </div>
      </div>

      {/* THUMBNAIL */}
      {isSmallScreen && (
        <Thumbnail
          className="e-flex e-shrink-0 e-grow-0"
          type={studyType}
          imageSrcUrl={imageSrcUrl}
          isActive={isActive}
        />
      )}
    </div>
  );
};
