import React, { forwardRef } from 'react';
import cn from 'classnames';
import { BaseThumbnail, BaseThumbnailProps } from '../BaseThumbnail/BaseThumbnail';
import { BaseThumbnailSkeleton } from '../BaseThumbnail/BaseThumbnailSkeleton';

interface VideoThumbnailProps extends BaseThumbnailProps {
  /**
   * Whether the thumbnail is loading, show a skeleton.
   */
  isLoading?: boolean;
}

/**
 * Represent a single image thumbnail in the viewer.
 */
export const VideoThumbnail = 
forwardRef<HTMLButtonElement, VideoThumbnailProps>(
({ className, title, isLoading = false, ...restProps }, ref) => {
  const classes = {
    container: cn(className),
  };

  if (isLoading) return <BaseThumbnailSkeleton hasAnimation />;

  return (
    <BaseThumbnail {...restProps} ref={ref} title={title} className={classes.container}>
      <div className="e-flex e-items-center e-justify-center e-w-full e-mb-4">
        <svg className="e-text-base-white" xmlns="http://www.w3.org/2000/svg" width="24" height="16" fill="none">
          <path
            fill="currentColor"
            d="M24 12.954V2.553c0-1.188-1.915-1.783-3.035-.943l-7.212 5.41c-.714.535-.69 1.408.051 1.922l7.213 4.993c1.138.789 2.983.183 2.983-.98Z"
          />
          <path
            fill="currentColor"
            d="M0 3.333C0 1.86 1.592.667 3.556.667h8.888C14.408.667 16 1.86 16 3.333v9.333c0 1.473-1.592 2.667-3.556 2.667H3.556C1.592 15.333 0 14.14 0 12.666V3.333Z"
          />
        </svg>
      </div>
    </BaseThumbnail>
  );
  })
