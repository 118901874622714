import React, { createContext, useContext, useState } from 'react';

export enum Modals {
  BaseModal = 'BaseModal',
  ConfirmationModal = 'ConfirmationModal',
}

export interface CurrentModal<T = any> {
  name: Modals;
  props: T;
}

export interface ModalContextType {
  currentModal: CurrentModal | null;
  setCurrentModal: React.Dispatch<React.SetStateAction<CurrentModal | null>>;
}

interface ModalProviderProps {
  children: JSX.Element;
}

/*  CONTEXT DEFINITION  */
export const ModalContext = createContext<ModalContextType>({} as ModalContextType);

/**
 * @deprecated This provider will be removed along with `useModalContext`
 */
export const ModalProvider = ({ children }: ModalProviderProps): JSX.Element => {
  const [currentModal, setCurrentModal] = useState<CurrentModal | null>(null);

  const modalProviderValue = {
    currentModal,
    setCurrentModal,
  };

  return <ModalContext.Provider value={modalProviderValue}>{children}</ModalContext.Provider>;
};
/* EXPORT USE METHOD */
/**
 * @deprecated use `useModal` instead
 */
export const useModalContext = (): ModalContextType => {
  const context = useContext(ModalContext);
  if (!context) throw new Error('useModalContext must be wrapped within ModalWrapper');
  return context;
};
