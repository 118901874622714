import React, { useCallback } from 'react';
import { localizer } from '../utils/custom-localizer';
import { CalendarView } from '../types';
import type { CalendarContextProps } from '../hooks/useCalendarContext';
import { Button, ButtonSize, ButtonVariant } from '../../Buttons';
import { Icon, IconCatalog } from '../../Icon/Icon';
import { formats, customFormats } from '../utils/formats';
import { ViewSwitcher } from './ViewSwitcher';
import { Action, navigate } from '../utils/helpers';
import { useCalendarContext } from '../hooks/useCalendarContext';
import { t } from '../i18n';

export interface ToolbarProps {
  /**
   * The content to be replaced inside of toolbar.
   */
  children?(context: CalendarContextProps): JSX.Element;
}

export const Toolbar = ({ children }: ToolbarProps) => {
  const context = useCalendarContext();

  const handleNavigation = useCallback(
    (action: Action, newDate?: Date) => {
      const date = newDate || context.date;
      const navigatedDate = navigate(date, action, context.view);
      context.handleChangeDate(navigatedDate);
    },
    [context.date, context.handleChangeDate],
  );

  const handleHeaderFormat = useCallback((view: CalendarView) => {
    const calendarFormats = {
      [CalendarView.MONTH]: formats.monthHeaderFormat,
      [CalendarView.WEEK]: customFormats.weekHeaderFormat,
      [CalendarView.DAY]: formats.dayHeaderFormat,
    };

    return calendarFormats[view] as string;
  }, []);

  // TODO: Define a custom render props to the toolbar's children.
  if (children) return children(context);

  // The toolbar from `react-big-calendar` was disabled in order to use our custom toolbar.
  return (
    <div className="e-flex e-justify-between e-items-center e-px-8 e-py-7">
      <div className="e-flex e-space-x-7">
        <Button
          className="e-flex-shrink-0"
          onClick={() => handleNavigation(Action.TODAY, new Date())}
          size={ButtonSize.sm}
          variant={ButtonVariant.tertiary}>
          {t('today', context.locale)}
        </Button>
        <div className="e-flex e-items-center e-space-x-4">
          <Button size={ButtonSize.xs} variant={ButtonVariant.ghost} onClick={() => handleNavigation(Action.PREV)}>
            <Icon className="e-text-primary-300" icon={IconCatalog.chevronLeft} />
          </Button>
          <div className="e-font-medium e-capitalize e-text-lg e-text-base-white">
            {localizer.format(context.date, handleHeaderFormat(context.view), context.locale)}
          </div>
          <Button size={ButtonSize.xs} variant={ButtonVariant.ghost} onClick={() => handleNavigation(Action.NEXT)}>
            <Icon className="e-text-primary-300" icon={IconCatalog.chevronRight} />
          </Button>
        </div>
      </div>
      <div className="e-relative">
        <ViewSwitcher />
      </div>
    </div>
  );
};
