import React, { forwardRef, useLayoutEffect, useState } from 'react';
import cn from 'classnames';
import { Transition } from '@headlessui/react';
import { CDN_URL } from '../../../common';
import { Image } from '../../Image/Image';
import { CompanyLogo, CompanyLogoColor, CompanyLogoSize } from '../../CompanyLogo/CompanyLogo';

export interface FakeSplashProps {
  /**
   * Class names used for external styles
   */
  className?: string;

  /**
   * Timer to be used to show the splash screen
   */
  timer?: number;

  /**
   * Callback to be called after the splash screen leaves
   */
  onAfterLeave?: () => void;
}

const DEFAULT_TIMER = 2800;

const containerClasses = cn(
  'e-fixed e-top-0 e-left-0 e-bottom-0 e-right-0',
  'e-overflow-x-hidden e-overflow-y-auto e-outline-0',
  'e-flex e-items-center e-justify-center',
  'e-h-full e-w-full',
);

const Container = forwardRef<HTMLDivElement, any>((props, ref) => {
  const classes = {
    container: cn(containerClasses),
  };
  return <div {...props} className={classes.container} ref={ref} />;
});

/**
 * This is a fake splash screen that is used to simulate the loading of the application.
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2024-02-15
 */
export const FakeSplash = ({ className, timer = DEFAULT_TIMER, onAfterLeave }: FakeSplashProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isShowing, setIsShowing] = useState(true);

  const classes = {
    content: cn(containerClasses),
    image: cn('e-w-[390px] e-h-[390px]'),
    logo: cn('e-absolute e-bottom-1/10'),
  };

  useLayoutEffect(() => {
    const timeout = setTimeout(() => setIsShowing(false), timer);
    return () => clearTimeout(timeout);
  }, []);

  const handleLoad = () => setIsLoaded(true);
  const handleAfterLeave = () => {
    if (onAfterLeave) onAfterLeave();
  };

  return (
    <Transition
      as={Container}
      appear
      show={isShowing}
      unmount
      enter="e-transition-colors"
      enterFrom="e-bg-transparent"
      enterTo="e-bg-[#101214]"
      entered={className}
      leave="e-transition-colors"
      leaveFrom="e-bg-[#101214]"
      leaveTo="e-bg-transparent">
      <Transition.Child
        enter="e-transition-opacity e-ease-out e-duration-[300ms]"
        enterFrom="e-opacity-0"
        enterTo="e-opacity-100"
        leave="e-transition-opacity e-ease-out e-duration-[300ms]"
        leaveFrom="e-opacity-100"
        leaveTo="e-opacity-0"
        afterLeave={handleAfterLeave}>
        <div role="status" data-testid="fake-splash" className={cn('e-bg-[#101214]', classes.content)}>
          <div className="e-flex e-flex-col e-items-center">
            <div className="e-relative">
              {!isLoaded && (
                <Image
                  className={cn(classes.image, 'e-absolute e-left-0 e-top-0')}
                  src={`${CDN_URL}/images/loaders/eden-loader-thumb-dark.jpg`}
                />
              )}
              <Image
                className={classes.image}
                src={`${CDN_URL}/images/loaders/eden-loader-dark.gif`}
                hasFadeInAnimation={false}
                onLoad={handleLoad}
              />
            </div>

            <CompanyLogo
              className={classes.logo}
              hasLink={false}
              color={CompanyLogoColor.light}
              size={CompanyLogoSize.md}
            />
          </div>
        </div>
      </Transition.Child>
    </Transition>
  );
};
