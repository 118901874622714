import React from 'react';
import cn from 'classnames';

export interface CardProps extends React.HTMLAttributes<HTMLElement> {
  /**
   * Descriptive text to be read to screenreaders
   */
  ariaLabel?: string;

  /**
   * Specify an optional className to be added to the component.
   */
  className?: string;

  /**
   * Whether the Card is clickable, adding some extra styles.
   */
  isClickable?: boolean;

  /**
   * Elements to display inside the Card.
   */
  children: React.ReactNode;

  /**
   * Enable padding on the card
   */
  enablePadding?: boolean;
}
/**
 * A Card is a base element from where other components can extend to style their content as cards
 * @author Alan Chávez<alan.chavez@evacenter.com>
 */
export const Card = ({ ariaLabel, className, isClickable, children, enablePadding = true, ...rest }: CardProps) => {
  const classes = {
    card: cn('e-bg-neutral-700 e-border e-rounded-xl e-border-neutral-500', className, {
      'hover:e-border-neutral-400': isClickable,
      'e-p-4': enablePadding,
    }),
  };

  return (
    <article aria-label={ariaLabel} className={classes.card} {...rest}>
      {children}
    </article>
  );
};
