export const Colors = [
  // 500
  '#FFEB3B',
  '#9C27B0',
  '#F44336',
  '#4CAF50',
  '#2196F3',
  '#FFC107',
  '#673AB7',
  '#FF5722',
  '#8BC34A',
  '#03A9F4',
  '#FF9800',
  '#3F51B5',
  '#E91E63',
  '#CDDC39',
  '#00BCD4',
  '#795548',
  '#607D8B',
  '#009688',
  // 200
  '#FEF49B',
  '#CE93D8',
  '#ED9999',
  '#A5D5A6',
  '#93D9FF',
  '#FDDF82',
  '#B39DDA',
  '#FEAA90',
  '#C4E0A5',
  '#94EDFF',
  '#FFCC81',
  '#9EA7D9',
  '#F48FB1',
  '#E7EF9D',
  '#99FFFF',
  '#BBA9A3',
  '#AFBDC3',
  '#81CAC4',
  // 900
  '#F47E18',
  '#4B168D',
  '#B71C1B',
  '#1C5F22',
  '#004991',
  '#FE6E01',
  '#2E1B92',
  '#BF370C',
  '#346A1F',
  '#005992',
  '#E75204',
  '#1A227D',
  '#890F4F',
  '#817617',
  '#00687B',
  '#3F2723',
  '#263137',
  '#014D40',
];
