import React from 'react';
import { DayPicker as ReactDayPicker } from 'react-day-picker';
import cn from 'classnames';
import type { Locale } from 'date-fns';
import enUs from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import pt from 'date-fns/locale/pt';
import { DayPickerLocale } from './types';
import 'react-day-picker/dist/style.css';

type ReactDayPickerProps = React.ComponentProps<typeof ReactDayPicker>;

export type DayPickerProps =
  | ReactDayPickerProps
  | {
      /**
       * The locale to use in the component.
       */
      locale?: DayPickerLocale;
      /**
       * Show days outside the current month.
       */
      showOutsideDays?: boolean;
      /**
       * Classes to apply to the container component.
       */
      className?: string;
    };

const locales: { [key in DayPickerLocale]: Locale } = {
  en: enUs,
  es,
  pt,
};

/**
 * This component can be used to display a date picker statically or it can be
 * integrated into an input. It has several modes available including single,
 * multiple and by range.
 * @author Javier Diaz <javier.diaz@evacenter.com>
 * Created at 2022-12-07
 */
export const DayPicker = ({
  className,
  locale = DayPickerLocale.ES,
  showOutsideDays = true,
  ...props
}: DayPickerProps) => {
  const internalLocale = typeof locale === 'string' ? locales[locale as DayPickerLocale] : locale;
  const classes = cn('eva-day-picker', className);

  return (
    <div className={classes}>
      <ReactDayPicker locale={internalLocale} showOutsideDays={showOutsideDays} {...props} />
    </div>
  );
};
