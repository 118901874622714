const CIRCULAR_MENU_ID = 'circular-menu';
const SHEET_STYLES_ID = '#sheetStyles';

const cleanPreviousElements = () => {
  const elements = document.querySelectorAll(`.${CIRCULAR_MENU_ID}`);
  elements.forEach((element, index) => {
    const left = elements.length - index;
    if (left <= 6) return;
    return element.remove();
  });
};

const cleanMainClasses = () => {
  const main = document.querySelector(`#${CIRCULAR_MENU_ID}`);

  const iterableList = Object.values(main.classList);
  const defaultClasses = iterableList.slice(0, 6);
  const newClasses = iterableList.slice(iterableList.length - 5, iterableList.length);
  const finalClasses = [...defaultClasses, ...newClasses];

  main.className = finalClasses.join(' ');
};

const cleanMainChilds = () => {
  const main = document.querySelector(`#${CIRCULAR_MENU_ID}`);
  const childs = main.children;

  const iterableList = Object.values(childs);
  iterableList.forEach((child, index) => child.remove());
};

const cleanCss = () => {
  const style = document.querySelector(SHEET_STYLES_ID);
  style.innerHTML = '';
};

export default function destroy() {
  cleanPreviousElements();
  cleanMainClasses();
  cleanMainChilds();
  setTimeout(() => cleanCss(), 100);
}
