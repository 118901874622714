import React, { useState } from 'react';
import { BottomSheetRoot } from '../../components';

/**
 * A function that takes a result of a variable type and returns nothing.
 * This will close our bottom sheet and return to the caller of `openBottomSheet`.
 */
type CloseBottomSheet<ResultType> = (result?: ResultType) => void;

/**
 * A function that builds the UI for a bottom sheet.
 * It takes the close function as a parameter and returns a `ReactNode`
 * that we can display.
 */
type BottomSheetFactory<ResultType> = (close: CloseBottomSheet<ResultType>) => React.ReactNode;

/**
 * Use `useBottomSheet` to display a bottom sheet which can resolve a value when closing it.
 * @author Sergio Ruiz <sergioruizdavila@gmail.com>
 * Created at 2024-02-12
 * @reference This hook was extracted from https://www.esveo.com/en/blog/O5
 */
export const useBottomSheet = (className?: string) => {
  // The react node has to be stored somewhere
  const [bottomSheetNode, setBottomSheetNode] = useState<React.ReactNode>(null);

  const openBottomSheet = <BottomSheetResult,>(bottomSheetFactory: BottomSheetFactory<BottomSheetResult>) =>
    new Promise<BottomSheetResult | undefined>((resolve) => {
      const close = (value?: BottomSheetResult) => {
        resolve(value);

        // When the bottom sheet should be closed, we set our state to null
        // to stop rendering the component
        setBottomSheetNode(null);
      };

      const bottomSheet = <BottomSheetRoot className={className}>{bottomSheetFactory(close)}</BottomSheetRoot>;

      // To open the bottom sheet, we store the resulting jsx in our state
      setBottomSheetNode(bottomSheet);
    });

  // We return the bottomSheetNode (or null) and the openBottomSheet function
  return { bottomSheetNode, openBottomSheet };
};
