import React from 'react';
import cn from 'classnames';
import { SortDirection } from '@tanstack/react-table';
import { Icon, IconCatalog } from '../../Icon/Icon';

export interface SortingIconProps {
  /**
   * Sorting type.
   */
  sorting?: SortDirection;

  /**
   * Specify an optional className to be added to the component
   */
  className?: string;
}

/**
 * Sorting icon (Table)
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2023-08-16
 */
export const SortingIcon: React.FC<SortingIconProps> = ({ sorting, className }) => {
  const classes = {
    container: cn(className, 'e-inline-flex e-flex-col -e-space-y-4', 'e-w-6 e-h-8'),
    ascIcon: cn({
      'e-text-neutral-400': sorting !== 'asc',
      'e-text-primary-400': sorting === 'asc',
    }),
    descIcon: cn({
      'e-text-neutral-400': sorting !== 'desc',
      'e-text-primary-400': sorting === 'desc',
    }),
  };

  return (
    <span className={classes.container}>
      <Icon className={classes.ascIcon} icon={IconCatalog.caretUp} />
      <Icon className={classes.descIcon} icon={IconCatalog.caretDown} />
    </span>
  );
};
