import React from 'react';
import cn from 'classnames';
import { Icon, IconCatalog } from '../../../Icon/Icon';
import { useSidebarContext } from '../../../../common';

export interface ItemProps {
  /**
   * Descriptive text to be read to screenreaders.
   */
  ariaLabel?: string;

  /**
   * Specify an optional className to be added to the component.
   */
  className?: string;

  /**
   * Whether the option is active
   */
  isActive?: boolean;

  /**
   * The icon to display on the left side.
   */
  icon?: IconCatalog;

  /**
   * Set a title text
   */
  text?: string;

  /**
   * Provide a handler that is called when the item was clicked
   */
  onClick?: () => void;
}

/**
 * It represents the SideNav's item (SideNav)
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2022-07-18
 */
export const Item = ({ ariaLabel, className, text, icon, isActive = false, onClick }: ItemProps) => {
  const { isCollapsed } = useSidebarContext();
  const classes = {
    container: cn(
      className,
      'e-flex e-flex-none e-flex-nowrap e-items-center e-justify-start e-p-2 e-rounded-lg e-w-full',
      {
        'e-cursor-pointer hover:e-bg-neutral-600': !isActive,
        'e-bg-primary-500': isActive,
      },
    ),
    icon: cn('e-text-neutral-50 e-flex-shrink-0 e-flex-grow-0', {
      'e-mr-3': !isCollapsed,
    }),
    text: cn('e-text-neutral-50 e-text-base e-whitespace-nowrap', {
      'e-font-regular': !isActive,
      'e-font-semi-bold': isActive,
    }),
  };

  const handleClick = () => {
    if (onClick) onClick();
  };

  return (
    <div
      role="option"
      aria-label={ariaLabel}
      tabIndex={0}
      className={classes.container}
      onClick={handleClick}
      onKeyPress={handleClick}>
      {icon && <Icon icon={icon} className={classes.icon} />}
      {!isCollapsed && <span className={classes.text}>{text}</span>}
    </div>
  );
};
