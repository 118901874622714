import React, { useState } from 'react';
import cn from 'classnames';
import { Button, ButtonSize, ButtonVariant } from '../../../Buttons';
import { IconCatalog } from '../../../Icon/Icon';
import { Popover, PopoverPlacement } from '../../../Overlays';
import { Locale } from '../../../../common';
import { PaginationGoTo } from '../../PaginationGoTo/PaginationGoTo';

export interface PaginationDotsButtonProps {
  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * Represents the current active page.
   */
  currentPage?: number;

  /**
   * The max number of pages
   */
  maxPages?: number;

  /**
   * Provide a handler that is called when the page changes
   */
  onGoToPageChange?: (pageNumber?: number) => void;
}

/**
 * `PaginationDotsButton` is used to display a button with three dots to navigate to a specific page from a popover menu
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2024-10-04
 */
export const PaginationDotsButton = ({
  className,
  currentPage,
  maxPages = 100,
  onGoToPageChange,
}: PaginationDotsButtonProps) => {
  const classes = {
    menu: cn(className),
  };

  const [isOpen, setIsOpen] = useState(false);

  const handlePageChange = (pageNumber?: number) => {
    setIsOpen(false);
    if (onGoToPageChange) onGoToPageChange(pageNumber);
  };

  const handleBtnClick = (): void => setIsOpen(true);
  const handleClickOutside = (): void => setIsOpen(false);

  return (
    <Popover
      menuClassName={classes.menu}
      isOpen={isOpen}
      content={
        <PaginationGoTo
          locale={Locale.es}
          maxPages={maxPages}
          goToPage={currentPage}
          onGoToPageChange={handlePageChange}
        />
      }
      placement={PopoverPlacement.bottom}
      hasArrow={false}
      onClickOutside={handleClickOutside}>
      <div className="e-rotate-90">
        <Button
          size={ButtonSize.xs}
          endIcon={IconCatalog.moreVert}
          variant={ButtonVariant.ghost}
          onClick={handleBtnClick}
        />
      </div>
    </Popover>
  );
};
