import React, { ReactNode } from 'react';
import cn from 'classnames';
import { Item } from './Item/Item';

export interface TimelineProps {
  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * Elements to display inside the Option.
   */
  children?: ReactNode;

  /**
   * Specify an optional test ID for testing
   */
  dataTestid?: string;
}

/**
 * Represents a timeline to use in the Viewer component
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2024-02-02
 */
export const Timeline = ({ className, children, dataTestid }: TimelineProps) => {
  const classes = {
    container: cn('e-relative', className),
    list: cn('e-mt-2 e-border-l e-border-neutral-500'),
    blurSection: cn(
      'e-w-4 e-h-4',
      'e-absolute e-left-3 e-bottom-4',
      'e-flex e-items-end e-justify-center',
      'e-bg-gradient-to-t e-to-transparent',
      'e-from-neutral-900',
    ),
  };

  /* Render JSX */
  return (
    <div className={classes.container} data-testid={dataTestid}>
      <ul className={classes.list}>{children}</ul>
      <div className={classes.blurSection}></div>
    </div>
  );
};

Timeline.Item = Item;
