import React from 'react';
import classNames from 'classnames';

export interface CardFooterProps {
  /**
   * The children of the modal footer.
   */
  children?: React.ReactNode;

  /**
   * Specify an optional className to be added to the body section.
   */
  className?: string;
}

/**
 * `CardFooter` represents the footer section of a card
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2023-07-10
 */
export const CardFooter = ({ children, className }: CardFooterProps) => {
  const classes = {
    footer: classNames(
      'e-flex e-flex-wrap e-flex-shrink-0 e-items-center',
      'e-w-full e-p-2',
      'e-border-t e-border-neutral-500',
      className,
    ),
  };

  return <div className={classes.footer}>{children}</div>;
};
