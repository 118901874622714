import { CalendarView } from '../types';

export const DEFAULT_CALENDAR_VIEWS = [CalendarView.DAY, CalendarView.WEEK, CalendarView.MONTH];

export const CALENDAR_NAVIGATION_STEP = {
  INCREMENT: 1,
  DECREMENT: -1,
};

export const DEFAULT_TIME_STEP = 30;
export const DEFAULT_TIME_FORMAT = 'h:mm aaa';

export const DEFAULT_POPUP_SKIDDING = 10; // Displaces the popper along the reference element.
export const DEFAULT_POPUP_DISTANCE = 20; // Displaces the popper away from the reference element.
