import React from 'react';
import cn from 'classnames';
import { PaginationOptionsProps, PAGINATION_DOTS, usePagination } from '../../common';
import { Button, ButtonSize, ButtonVariant } from '../Buttons';
import { IconCatalog } from '../Icon/Icon';
import { PaginationDotsButton } from './PaginationButtons/PaginationDotsButton/PaginationDotsButton';

export interface PaginationProps extends PaginationOptionsProps {
  /**
   * Specify an optional className to be added to the component
   */
  className?: string;
  /**
   * Represents the current active page.
   */
  currentPage: number;
  /**
   * Indicate if the `next` page button is enabled
   */
  enableNextPage?: boolean;
  /**
   * Indicate if the `prev` page button is enabled
   */
  enablePrevPage?: boolean;
  /**
   * Provide a handler that is called when the `next` button is clicked
   */
  onNextClick?: () => void;
  /**
   * Provide a handler that is called when the `prev` button is clicked
   */
  onPreviousClick?: () => void;
  /**
   * Provide a handler that is called when the page changes
   */
  onPageChange: (pageNumber?: number) => void;
  /**
   * Provide a handler that is called when a new page size is selected
   */
  onPageSizeChange: (pageSize: number) => void;
  /**
   * Provides access to the page size handler from a given ReactNode
   */
  paginationHelper?: (pageSizeChange: (pageSize: number) => void) => React.ReactNode;
}

/**
 * `Pagination` is used for splitting up content or data into several pages, with a control for navigating to the next or previous page.
 * @author Alan Chávez<alan.chavez@evacenter.com>
 * Created at 2022-09-23
 */
export const Pagination = ({
  className,
  enableNextPage,
  enablePrevPage,
  onPageChange,
  onPreviousClick,
  onNextClick,
  onPageSizeChange,
  paginationHelper,
  ...restProps
}: PaginationProps) => {
  const classes = {
    pagination: cn(
      className,
      'e-flex e-flex-col e-flex-wrap e-space-y-2 lg:e-flex-nowrap lg:e-flex-row lg:e-space-y-0 e-justify-between e-bg-neutral-800 e-p-4 e-text-neutral-50 e-items-center',
    ),
  };

  const paginationRange = usePagination(restProps);
  const { currentPage } = restProps;

  const handleNextPage = () => {
    if (onNextClick) onNextClick();
  };

  const handlePrevPage = () => {
    if (onPreviousClick) onPreviousClick();
  };

  const handlePageChange = (pageNumber?: number) => {
    if (onPageChange) onPageChange(pageNumber);
  };

  const handlePageSizeChange = (pageSize: number) => {
    if (onPageSizeChange) onPageSizeChange(pageSize);
  };

  const renderPillList = paginationRange!.map((pageNumber, index) => {
    if (!paginationRange) return null;

    const lastPage = paginationRange[paginationRange.length - 1];
    if (pageNumber === PAGINATION_DOTS)
      return (
        <PaginationDotsButton
          key={`pagination-dots-${index}`}
          maxPages={Number(lastPage)}
          onGoToPageChange={handlePageChange}
        />
      );

    return (
      <Button
        key={index}
        disabled={currentPage === pageNumber}
        size={ButtonSize.xs}
        onClick={() => handlePageChange(Number(pageNumber))}
        variant={pageNumber !== currentPage ? ButtonVariant.ghost : ButtonVariant.primary}>
        {pageNumber}
      </Button>
    );
  });

  return (
    <div className={classes.pagination}>
      {paginationHelper && paginationHelper(handlePageSizeChange)}
      <div className="e-flex e-space-x-3">
        <Button
          size={ButtonSize.xs}
          onClick={handlePrevPage}
          isDisabled={!enablePrevPage}
          endIcon={IconCatalog.arrowLeft}
          variant={ButtonVariant.tertiary}
        />
        {renderPillList}
        <Button
          size={ButtonSize.xs}
          onClick={handleNextPage}
          isDisabled={!enableNextPage}
          endIcon={IconCatalog.arrowRight}
          variant={ButtonVariant.tertiary}
        />
      </div>
    </div>
  );
};
