import React, { useMemo } from 'react';
import { Calendar as BigCalendar, View, Messages, ResourceHeaderProps } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import cn from 'classnames';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useCalendarContext } from '../hooks/useCalendarContext';
import { localizer } from '../utils/custom-localizer';
import { formats } from '../utils/formats';
import { CalendarView, Event } from '../types';
import { CalendarEvent as EventWrapper } from './Event';
import { MonthEvent } from './Event/MonthEvent';
import { t } from '../i18n';
import { ResourceHeader } from './ResourceHeader';

// Enabling drag and drop features for the calendar
const Calendar = withDragAndDrop<Event>(BigCalendar);

export interface CalendarViewProps {
  /**
   * Class name to customize the overlay component.
   */
  overlayContainerClassName?: string;
  /**
   * Render function to customize the event component.
   */
  eventRenderer?: (event: Event) => JSX.Element;
  /**
   * Render function to customize the popover event component.
   */
  popupRenderer?: (event: Event) => JSX.Element;
  /**
   * Render function to customize the overlay component.
   */
  overlayRendered?: () => JSX.Element;
  /**
   * Render function to customize the resource header component.
   */
  resourceHeaderRenderer?: (resource: ResourceHeaderProps) => JSX.Element;
}

export const CalView = ({
  eventRenderer,
  popupRenderer,
  overlayRendered,
  resourceHeaderRenderer,
  overlayContainerClassName,
}: CalendarViewProps) => {
  const {
    defaultView,
    events,
    views,
    locale,
    date,
    step,
    view,
    resources,
    handleChangeView,
    handleChangeDate,
    handleOnMoveEvent,
    handleOnResizeEvent,
    handleOnSelectEvent,
    handleOnSelectSlot,
    handleOnSelecting,
  } = useCalendarContext();
  const defaultDate = useMemo(() => date, []);
  const scrollToTime = useMemo(() => new Date(), []);

  const components = useMemo(
    () => ({
      event: EventWrapper(eventRenderer, popupRenderer),
      month: {
        event: MonthEvent,
      },
      resourceHeader: ResourceHeader(resourceHeaderRenderer),
    }),
    [eventRenderer, popupRenderer],
  );

  const messages = useMemo<Messages>(
    () => ({
      showMore: (total) => t('showMore', locale).replace('{total}', total.toString()),
    }),
    [locale],
  );

  const props = useMemo(
    () => ({
      defaultDate,
      date,
      events,
      components,
      messages,
      toolbar: false, // Disabled toolbar to avoid the default behavior of the calendar
      popup: false, // Disabled popup to avoid the default behavior of the calendar
      defaultView,
      culture: locale,
      formats,
      localizer,
      views,
      step,
      scrollToTime,
      view: view as View,
      // Required listener for enable two-way binding `date` and `view` value
      // changes internally on react-big-calendar
      onView: (view: View) => handleChangeView(view as CalendarView),
      onNavigate: (date: Date) => handleChangeDate(date),
      // Handlers
      onEventDrop: handleOnMoveEvent,
      onEventResize: handleOnResizeEvent,
      onSelectEvent: handleOnSelectEvent,
      onSelectSlot: handleOnSelectSlot,
      onSelecting: handleOnSelecting,
      // Handling of resources only available for `day` view
      // TODO: Improve the condition to only load resources when the view is `day`
      ...(view === CalendarView.DAY ? { resources: resources.length > 0 ? resources : undefined } : {}),
    }),
    [locale, events, date, formats, view, resources, components],
  );

  return (
    <div className="e-relative e-flex-grow e-h-full e-overflow-auto eva-calendar e-horizontal-scroll e-scrollbar-thumb-neutral-400 e-scrollbar-track-neutral-700 e-scrollbar-thumb-rounded-lg">
      {overlayRendered && (
        <div
          className={cn(
            'e-absolute e-top-0 e-z-10 e-w-full e-h-full e-flex e-items-center e-justify-center',
            overlayContainerClassName,
          )}>
          {overlayRendered()}
        </div>
      )}
      <Calendar
        className={cn({
          'e-opacity-30': overlayRendered,
        })}
        showMultiDayTimes
        {...props}
      />
    </div>
  );
};
