import React from 'react';
import cn from 'classnames';
import { Icon, IconCatalog } from '../../../Icon/Icon';
import { Divider } from '../../../Divider/Divider';

export enum CreatorAlertCardVariant {
  success = 'success',
  warning = 'warning',
  error = 'error',
  info = 'info',
  neutral = 'neutral',
}

export enum CreatorAlertCardSize {
  xs = 'xs',
  full = 'full',
}

const IconVariants: Record<CreatorAlertCardVariant, string> = {
  [CreatorAlertCardVariant.success]: 'e-text-success-500',
  [CreatorAlertCardVariant.warning]: 'e-text-warning-500',
  [CreatorAlertCardVariant.error]: 'e-text-error-500',
  [CreatorAlertCardVariant.info]: 'e-text-primary-400',
  [CreatorAlertCardVariant.neutral]: 'e-text-neutral-50',
};

const SizesVariants: Record<CreatorAlertCardSize, string> = {
  [CreatorAlertCardSize.xs]: 'e-w-full e-text-xs',
  [CreatorAlertCardSize.full]: 'e-w-full',
};

export interface CreatorAlertCardProps extends React.HTMLAttributes<HTMLElement> {
  /**
   * Class to be added to the component
   */
  className?: string;
  /**
   * Descriptive text to be read to screenreaders
   */
  ariaLabel?: string;
  /**
   * Specify an icon to be displayed in the card
   */
  icon?: IconCatalog;
  /**
   * Specify the size of the icon ex. `32` is applied as 32px
   */
  iconSize?: number;
  /**
   * The title of the card
   */
  title: string;
  /**
   * The variant of the card. It determines the importante in the hierarchy
   */
  variant?: CreatorAlertCardVariant;
  /**
   * The size of the card
   */
  size?: CreatorAlertCardSize;
  /**
   * Define a section to include actions
   */
  actions?: React.ReactNode;
}

/**
 * A CreatorAlertCard is a Card that is used to display alert messages for Creator components.
 * @author Salvador Gonzalez<salvador.gonzalez@evacenter.com>
 * Created on 2023-08-15
 */
export const CreatorAlertCard = ({
  className,
  icon,
  iconSize,
  size = CreatorAlertCardSize.xs,
  title,
  variant = CreatorAlertCardVariant.neutral,
  actions,
}: CreatorAlertCardProps) => {
  const classes = {
    container: cn('e-bg-neutral-900', className, SizesVariants[size]),
    icon: cn('e-relative', IconVariants[variant]),
    title: cn('e-font-medium e-text-neutral-50 e-text-center e-leading-tight'),
    bodyContainer: cn('e-text-neutral-200 e-leading-5'),
  };

  return (
    <div className={classes.container}>
      <div className="e-flex e-flex-col e-space-y-4">
        <div className="e-flex e-flex-col e-space-y-2 e-items-center">
          <div className="e-flex e-flex-col e-space-y-3 e-items-center">
            {icon ? <Icon className={classes.icon} icon={icon} width={iconSize} height={iconSize} /> : null}
            <span className={classes.title}>{title}</span>
          </div>
        </div>
        <Divider />
        {actions ? <div className="e-flex e-justify-center">{actions}</div> : null}
      </div>
    </div>
  );
};
