import React, { ChangeEvent, SelectHTMLAttributes, FocusEvent, useState, useEffect, useRef } from 'react';
import {
  Root,
  Trigger,
  Icon as SelectIcon,
  Portal,
  Viewport,
  ScrollDownButton,
  ScrollUpButton,
  Content,
  Value,
  Select as RadixSelect,
  SelectProps as RadixSelectProps,
  ItemIndicator,
  Group,
} from '@radix-ui/react-select';
import { Icon, IconCatalog } from '../../Icon/Icon';
import classnames from 'classnames';
import { FormFieldState, Input, SelectOption, setMaxHeightByOptions } from '../../../common';
import { Spinner, SpinnerSize, SpinnerVariant } from '../../Loaders';
import { SelectItem } from './SelectItem';

const Select = (props: RadixSelectProps) => <RadixSelect {...props} />;

Select.Root = Root;
Select.Trigger = Trigger;
Select.Icon = SelectIcon;
Select.Portal = Portal;
Select.Viewport = Viewport;
Select.ScrollDownButton = ScrollDownButton;
Select.ScrollUpButton = ScrollUpButton;
Select.Content = Content;
Select.ItemIndicator = ItemIndicator;
Select.Value = Value;
Select.Group = Group;

export interface SelectV2Props
  extends Input,
    Omit<SelectHTMLAttributes<HTMLSelectElement>, 'required' | 'onChange' | 'onFocus' | 'onBlur' | 'value'> {
  /**
   * Set the select options list.
   */
  options: Array<SelectOption>;

  /**
   * Provide a handler that is called when the select was clicked.
   */
  onSelectClick?: () => void;

  /**
   * Provide a handler that is called when an option was selected.
   */
  onChange?: (selectedOption: SelectOption, name: string | undefined) => void;

  /**
   * The callback to get notified when the cursor focus the Select component.
   */
  onFocus?: (event: ChangeEvent<HTMLButtonElement>) => void;

  /**
   * The callback to get notified when the cursor blur the Select component.
   */
  onBlur?: (event: ChangeEvent<HTMLButtonElement>) => void;

  /**
   * Current selected text value
   */
  value?: string;

  /**
   * Classname prop to apply to the Select.Content component.
   */
  selectContentClassName?: string;
}

/**
 * This component could be thought of as a styled native select tag, but adding to it more functionalities such as filtering, multi selecting, among others. The source of the Select (Dropdown) is a list of options that the user can choose from and apply to an input field to later be submitted.
 * @author Salvador Gonzalez <salvadorgonmo@gmail.com>
 * Created at 2023-02-26
 */
export const SelectV2 = React.forwardRef<HTMLDivElement, SelectV2Props>(
  (
    {
      id,
      name,
      options = [],
      placeholder,
      label,
      assistiveText,
      fieldState = FormFieldState.default,
      isDisabled = false,
      isRequired = false,
      isFullWidth = true,
      isLoading = false,
      className = '',
      onChange,
      onBlur,
      value,
      onFocus,
      dataTestId,
      onSelectClick,
      selectContentClassName,
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const selectTriggerRef = useRef<HTMLButtonElement>(null);
    const [selectContainerRef, setSelectContainerRef] = useState<HTMLDivElement | null>();
    const [dynamicWidth, setDynamicWidth] = useState<number | undefined>();
    const isEmptyValue = !value?.trim()?.length;
    const classes = {
      container: classnames('e-relative', className),
      select: classnames(
        'e-flex e-justify-between',
        'e-border e-rounded-lg e-appearance-none e-outline-none e-truncate e-p-2 e-pl-4 e-h-10',
        'focus:e-border-primary-500',
        {
          'e-w-full': isFullWidth,
          'e-bg-neutral-700 e-text-neutral-50': !isDisabled,
          'e-bg-neutral-700 e-text-neutral-300': !value || isEmptyValue,
          'e-text-neutral-300 e-bg-neutral-800 e-border-transparent e-cursor-default': isDisabled,
          'e-border-neutral-400': fieldState === FormFieldState.default && !isDisabled,
          'e-border-error-500': fieldState === FormFieldState.error && !isDisabled,
          'e-border-primary-500': fieldState === FormFieldState.default && isFocused,
        },
      ),
      triggerContainer: classnames({ 'e-w-full': isFullWidth }),
      selectContent: classnames(
        selectContentClassName,
        'e-border e-rounded-lg e-text-neutral-50 e-bg-neutral-600 e-w-full',
        'e-border e-border-neutral-500',
        'e-text-base-white e-text-caption',
        'e-transition e-duration-100 e-ease-out',
        'e-scrollbar-w-2 e-scrollbar-thumb-neutral-400 e-scrollbar-track-neutral-700 e-scrollbar-thumb-rounded-lg e-flex-grow e-w-full e-overflow-y-auto',
      ),
      option: (item: SelectOption) =>
        classnames(
          'e-flex e-items-center e-space-x-3 hover:e-outline-0',
          'e-px-3 e-py-4',
          'hover:e-bg-neutral-700 hover:e-cursor-pointer',
          {
            'e-bg-neutral-700': item.value === value,
          },
        ),
      assistiveText: classnames('e-mt-2 e-text-xs e-font-extra-light', {
        'e-text-neutral-200': fieldState === FormFieldState.default,
        'e-text-error-500': fieldState === FormFieldState.error,
      }),
      icon: classnames({
        'e-rotate-0': isOpen,
        'e-rotate-180': !isOpen,
        'e-text-neutral-50': !isDisabled,
      }),
    };

    const onValueChange = (value?: string) => {
      // This callback is called when the value changes by selecting an option
      // or setting the value via injection (from parent component like a default value)
      // so if there is no value on this callback, don't do anything.
      if (!value) return;

      const selectedOption = options.find((option) => option.value === value) as SelectOption;
      if (onChange) onChange(selectedOption, name);
    };

    const handleSelectFocus = (event: FocusEvent<HTMLButtonElement>): void => {
      if (isDisabled) return;

      if (onFocus) onFocus(event);
    };

    const handleSelectBlur = (event: FocusEvent<HTMLButtonElement>): void => {
      if (onBlur) onBlur(event);
    };

    const handleOpenChange = (open: boolean) => {
      setIsOpen(open);
      handleSelectClick();
    };

    const handleSelectClick = () => {
      if (isDisabled) return;
      setIsFocused(true);
      if (onSelectClick) onSelectClick();
    };

    const contentHeight = setMaxHeightByOptions({ listLength: options.length, maxOptions: 7, maxHeight: '300px' });

    // Adding a resize listener for when the width of Select changes
    // because the Popper width is set the same as the Select width
    // to match current design.
    useEffect(() => {
      const handleWindowResize = () => {
        setDynamicWidth(selectContainerRef?.offsetWidth);
      };
      window.addEventListener('resize', handleWindowResize);

      return () => {
        window.removeEventListener('resize', handleWindowResize);
      };
    }, [selectContainerRef]);

    const generateRootProps = (): RadixSelectProps => {
      const rootProps = {
        value,
        onValueChange,
        disabled: isDisabled,
        required: isRequired,
        onOpenChange: handleOpenChange,
      };

      if (isEmptyValue) delete rootProps.value;

      return rootProps;
    };

    return (
      <div ref={ref} className={classes.container}>
        <Select.Root {...generateRootProps()}>
          <Select.Group>
            {/* Using the label tag directly helps aligning and maintaining the curren structure with other Inputs. 
             Label component from Radix is using a div component which has less paddings than labels. */}
            <label className="e-text-neutral-50 e-text-sm e-font-regular" htmlFor={id}>
              {label}
              {isRequired && '*'}
            </label>
          </Select.Group>

          <div id="select-content" ref={(ref) => setSelectContainerRef(ref)} className={classes.triggerContainer}>
            <Select.Trigger
              id={id}
              ref={selectTriggerRef}
              onFocus={handleSelectFocus}
              data-testid={dataTestId}
              className={classes.select}
              onBlur={handleSelectBlur}>
              <Select.Value placeholder={placeholder} />

              <div className="e-flex e-items-center e-space-x-2 e-self-stretch e-flex-shrink-0 e-pl-1 e-ml-auto">
                {/* LOADING SPINNER */}
                {isLoading && <Spinner size={SpinnerSize.xs} variant={SpinnerVariant.neutral} />}

                <Select.Icon>
                  <Icon className={classes.icon} icon={IconCatalog.chevronUp} />
                </Select.Icon>
              </div>
            </Select.Trigger>
          </div>

          <Select.Portal>
            <Select.Content
              id="select-content"
              style={{
                ...contentHeight,
                width: dynamicWidth ?? selectContainerRef?.offsetWidth,
              }}
              position="popper"
              className={classes.selectContent}
              sideOffset={6}
              onCloseAutoFocus={(event) => {
                event.preventDefault();
                setIsFocused(false);
              }}>
              <Select.Viewport>
                {options.map((option: SelectOption) => (
                  <SelectItem
                    key={option.value}
                    classes={classes}
                    option={option}
                    isFullWidth={isFullWidth}
                    value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
              </Select.Viewport>
            </Select.Content>
          </Select.Portal>
        </Select.Root>
        {assistiveText && <span className={classes.assistiveText}>{assistiveText}</span>}
      </div>
    );
  },
);

export default SelectV2;
