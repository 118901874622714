import React from 'react';
import { EventProps } from 'react-big-calendar';
import { Event } from '../../types';
import { Popup } from '../Popup';
import { EventContent } from './Content';

interface CalendarEventRenderers {
  /**
   * Render function to customize the event component.
   */
  eventRenderer?: (event: Event) => JSX.Element;
  /**
   * Render function to customize the popover event component.
   */
  popupRenderer?: (event: Event) => JSX.Element;
}

export const CalendarEvent =
  (eventRenderer?: CalendarEventRenderers['eventRenderer'], popupRenderer?: CalendarEventRenderers['popupRenderer']) =>
  ({ title, event }: EventProps<Event>) => {
    return (
      <div id={'event-calendar' + event.id} className="relative e-h-full e-w-full">
        <Popup render={popupRenderer} event={event}>
          {eventRenderer ? eventRenderer(event) : <EventContent title={title} event={event} />}
        </Popup>
      </div>
    );
  };
