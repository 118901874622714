import { useEffect, useState } from 'react';
/**
 * This hook return a function that, given a pair of nested elements,
 * indicates whether the inner element overflows over the outer component
 * @author Mauricio Campos<mauricio.campos@evacenter.com>
 * Created at 2023-07-04
 * @param {React.RefObject<HTMLDivElement>} containerRef - reference to container element
 * @param {React.RefObject<HTMLDivElement>} contentRef - reference of content element
 * @param {number} tolerance - tolerance in px
 *
 * ```ts
 *  const containerRef = useRef(null);
 *  const contentRef = useRef(null);
 *  const isElementOverflowing = useIsOverflowing(containerRef, contentRef, 5)
 *  // isElementOverflowing return true if span element is overflowing the div parent element
 *  return (
 *   <div ref={containerRef}>
 *      <span ref={contentRef}>
 *        Element
 *      </span>
 *   </div>
 *  )
 * ```
 *
 */

interface UseIsOverflowing {
  (
    /*
     * Reference to the container element.
     */
    containerRef: React.RefObject<HTMLDivElement>,

    /*
     * Reference to the content element that will be observed if it overflows the container element.
     */
    contentRef: React.RefObject<HTMLDivElement>,

    /*
     *  Pixel tolerance to determine if the element overflows.
     *  isOverflowing = contentWidth + tolerance > containerWidth)
     */
    tolerance?: number,
  ): boolean;
}

export const useIsOverflowing: UseIsOverflowing = (containerRef, contentRef, tolerance = 3) => {
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    if (!containerRef.current || !contentRef.current) return;
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === contentRef.current || entry.target === containerRef.current) {
          const containerWidth = containerRef.current?.offsetWidth || 0;
          const contentWidth = contentRef.current?.offsetWidth || 0;
          setIsOverflowing(contentWidth + tolerance > containerWidth);
        }
      }
    });

    observer.observe(containerRef.current);
    observer.observe(contentRef.current);

    return () => observer.disconnect();
  }, [containerRef, contentRef, tolerance]);

  return isOverflowing;
};
