import React from 'react';
import { Row, RowData, Table } from '@tanstack/react-table';
import { Checkbox, CheckboxProps } from '../../../Controls';

interface CheckboxCellProps<T> {
  row?: Row<T>;
  table?: Table<T>;
}

/**
 * `CheckboxCell` is a regular checkbox which allows to select rows from a table instance
 * @author Alan Chávez<alan.chavez@evacenter.com>
 * Created at 2022-09-23
 */
export const CheckboxCell = <T extends RowData>({ row, table }: CheckboxCellProps<T>) => {
  const isMultiRowSelectionEnabled = table?.options?.enableMultiRowSelection;

  // Don't display the checkbox in the `header` is its single selection
  if (!isMultiRowSelectionEnabled && table) return null;

  const checkboxProps: CheckboxProps = {
    checked: row ? row.getIsSelected() : table?.getIsAllRowsSelected(),
    indeterminate: row ? row.getIsSomeSelected() : table?.getIsSomeRowsSelected(),
    onChange: row ? row.getToggleSelectedHandler() : table?.getToggleAllRowsSelectedHandler(),
  };

  return <Checkbox className="e-justify-center" {...checkboxProps} />;
};
