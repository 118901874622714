import React from 'react';
import { ICON_SIZE_DEFAULT } from '../../../common';
import { AlertCard, AlertCardProps, AlertCardVariant } from '../../AlertCard/AlertCard';
import { IconCatalog } from '../../Icon/Icon';

interface SuccessStateProps extends Omit<AlertCardProps, 'icon' | 'iconSize' | 'variant'> {}

export const SuccessState = (props: SuccessStateProps) => {
  return (
    <AlertCard
      {...props}
      iconSize={ICON_SIZE_DEFAULT}
      icon={IconCatalog.checkRounded}
      variant={AlertCardVariant.success}
    />
  );
};
