import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { Button, ButtonSize, ButtonVariant } from '../../Buttons';
import { CounterInput } from '../../Inputs';
import { Translations, Locale, Key, useKeyPress } from '../../../common';
import { IconCatalog } from '../../Icon/Icon';

export interface PaginationGoToProps {
  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * The locale to be used by the component
   */
  locale: Locale;

  /**
   * The goTo page number
   */
  goToPage?: number;

  /**
   * The max number of pages
   */
  maxPages?: number;

  /**
   * Provide a handler that is called when the page changes
   */
  onGoToPageChange?: (pageNumber?: number) => void;
}

const translations: Translations = {
  en: {
    goTo: 'Go to page',
  },
  es: {
    goTo: 'Ir a página',
  },
  pt: {
    goTo: 'Ir para a página',
  },
};

/**
 * `PaginationGoTo` helps to navigate to a specific page from a popover menu
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2024-10-04
 */
export const PaginationGoTo = ({
  className,
  locale,
  goToPage,
  maxPages = 100,
  onGoToPageChange,
}: PaginationGoToProps) => {
  const classes = {
    container: cn('e-flex e-items-center e-p-3 e-gap-2', className),
  };

  const nodeRef = useRef<HTMLInputElement>(null);
  const [isFocus, setIsFocus] = useState(false);
  const pressedEnter = useKeyPress(Key.Enter);

  useEffect(() => {
    if (!nodeRef.current) return;
    nodeRef.current.focus();
    setIsFocus(true);
  }, [nodeRef.current]);

  const [inputValue, setInputValue] = useState(goToPage);

  const handleInputChange = (value: number) => setInputValue(value);

  const handleButtonClick = () => {
    if (onGoToPageChange) onGoToPageChange(inputValue);
  };

  useEffect(() => {
    if (pressedEnter) handleButtonClick();
  }, [pressedEnter]);

  return (
    <div className={classes.container}>
      <span className="text-sm e-font-light e-text-neutral-300">{translations[locale].goTo}</span>
      <CounterInput
        ref={nodeRef}
        className="e-w-12"
        value={inputValue}
        onChange={handleInputChange}
        max={maxPages}
        showPicker={false}
        autoFocus={isFocus}
      />
      <Button
        size={ButtonSize.sm}
        variant={ButtonVariant.primary}
        startIcon={IconCatalog.arrowRight}
        onClick={handleButtonClick}
      />
    </div>
  );
};
