import React, { cloneElement, Children, ReactNode, isValidElement, useState, useEffect, useMemo, useRef } from 'react';
import cn from 'classnames';
import { useElementSize, useWindowSize } from '../../../../common';
import { Button } from './Button/Button';

export enum WidthBreakpoint {
  xs = 'xs',
  sm = 'sm',
  base = 'base',
}

export interface PillTabsProps {
  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * Elements to display inside the button.
   */
  children: ReactNode;

  /**
   * Default tab to be selected
   */
  defaultTab?: string;
}

/**
 * The PillTabs component is used to allow users to navigate between two or more views and groups of content.
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2024-01-18
 */
export const PillTabs = ({ className, defaultTab, children }: PillTabsProps) => {
  const [pillPos, setPillPos] = useState<DOMRect>();
  const [hintPos, setHintPos] = useState<DOMRect>();
  const [currentTab, setCurrentTab] = useState(defaultTab);
  const [childMounted, setChildMounted] = useState(false);
  const intervalAnimation = useRef<NodeJS.Timer | null>(null);
  const node = useRef<HTMLDivElement>(null);
  const { elementWidth } = useElementSize(node);
  const { windowWidth } = useWindowSize();
  const PARENT_POSITION = node.current ? node.current.getBoundingClientRect().left : 0;

  const classes = {
    container: cn('e-flex e-relative e-items-center e-rounded-full', 'e-p-1 e-h-10', 'e-bg-neutral-600', className),
    pill: cn('e-rounded-full e-px-2 e-py-1.5 e-h-8 e-absolute e-transition-all e-ease-out'),
    activePill: cn('e-bg-base-white e-z-1 e-duration-300'),
    hint: cn('e-bg-gradient-to-r e-z-0 e-from-transparent e-via-neutral-400 e-to-transparent e-duration-700'),
  };

  const isSmallScreen = (tabs: number): boolean => {
    if (!elementWidth) return false;
    if (elementWidth < 250 && tabs === 2) return true;
    if (elementWidth < 300 && tabs === 3) return true;
    if (elementWidth < 379 && tabs === 4) return true;
    return false;
  };

  // This is needed to set the pill position over the selected tab
  useEffect(() => {
    if (currentTab) updatePill(currentTab);
  }, [elementWidth, windowWidth, children]);

  // Listen father tab changes
  useEffect(() => {
    if (defaultTab === currentTab || !defaultTab) return;
    setCurrentTab(defaultTab);
    updatePill(defaultTab);
  }, [defaultTab]);

  // Listen children tab changes
  useEffect(() => {
    if (!currentTab) return;
    updatePill(currentTab);
  }, [currentTab]);

  const childrenArray = Children.toArray(children);

  const getTabPosition = (tabId: string) => {
    const tab = document.querySelector(`[data-tab-id="${tabId}"]`);
    if (tab) return tab.getBoundingClientRect();
  };

  const pillsDOMRect = useMemo(
    () =>
      childrenArray.map((child) => {
        if (!isValidElement(child)) return null;
        return getTabPosition(child.props.tabId);
      }),
    [childrenArray, windowWidth],
  );

  const stopHintAnimation = (interval: NodeJS.Timer, delay?: number) => {
    return setTimeout(() => {
      clearInterval(interval);
      setHintPos(undefined);
    }, delay);
  };

  const cleanPrevAnimation = () => {
    if (intervalAnimation.current) {
      clearInterval(intervalAnimation.current);
      intervalAnimation.current = null;
    }
  };

  const manageHintAnimation = () => {
    if (!pillsDOMRect || pillsDOMRect.length === 0) return;

    // Get the index of the current tab to start the animation from there
    const currentTabIndex = childrenArray.findIndex((child) => {
      if (!isValidElement(child)) return null;
      return child.props.tabId === currentTab;
    });

    let index = currentTabIndex === -1 ? 0 : currentTabIndex;
    intervalAnimation.current = setInterval(() => {
      const hintPosition = pillsDOMRect[index];
      if (hintPosition) setHintPos(hintPosition);
      index = index === pillsDOMRect.length - 1 ? 0 : index + 1;
    }, 1100);

    /*
     * Stop the interval when it reaches 2 times
     * the length of the array and hide the hint
     */
    return stopHintAnimation(intervalAnimation.current, 1400 * pillsDOMRect.length * 2);
  };

  // Start the hint animation when the children are mounted
  useEffect(() => {
    if (!Boolean(childMounted)) return;
    cleanPrevAnimation();
    const handler = manageHintAnimation();
    return () => clearTimeout(handler);
  }, [childMounted, windowWidth]);

  const updatePill = (tab: string) => {
    const position = getTabPosition(tab);
    if (position) setPillPos(position);
  };

  const newChildren = childrenArray.map((child) => {
    if (!isValidElement(child)) return null;

    const handleClick = (tabId: string) => (event: React.MouseEvent) => {
      setCurrentTab(tabId);
      stopHintAnimation(intervalAnimation.current as NodeJS.Timer);
      if (child.props.onClick) child.props.onClick(event);
    };

    const element = cloneElement(child, {
      ...child.props,
      isTabTruncated: isSmallScreen(childrenArray.length),
      isActive: currentTab === child.props.tabId,
      onClick: handleClick(child.props.tabId),
      onMounted: () => setChildMounted(true),
    });

    return element;
  });

  /* Render JSX */
  return (
    <div ref={node} className={classes.container}>
      {/* Floating pill */}
      {pillPos && (
        <div
          className={cn(classes.pill, classes.activePill)}
          style={{ left: pillPos.left - PARENT_POSITION, width: pillPos.width }}
        />
      )}

      {newChildren}

      {/* Animation pill */}
      {hintPos && (
        <div
          className={cn(classes.pill, classes.hint)}
          style={{ left: hintPos.left - PARENT_POSITION, width: hintPos.width }}
        />
      )}
    </div>
  );
};

PillTabs.Button = Button;
