import React from 'react';
import cn from 'classnames';
import { Button, ButtonVariant, ButtonSize } from '../../../Buttons/Button/Button';
import { BaseCard, BaseCardProps } from '../BaseCard/BaseCard';
import { RichTextarea } from '../../../Inputs/RichTextarea/RichTextarea';
import { CreatorSpinner, CreatorSpinnerSize } from '../../Loaders/CreatorSpinner/CreatorSpinner';

export interface EditableCardProps extends Omit<BaseCardProps, 'body' | 'footer'> {
  /**
   * The label for the primary button
   */
  btnLabelPrimary: string;

  /**
   * The label for the secondary button
   */
  btnLabelSecondary: string;

  /**
   * The default value for the rich text area
   */
  defaultValue?: string;

  /**
   * Whether the component is loading or not
   */
  isLoading?: boolean;

  /**
   * The text to show when the component is loading
   */
  loadingText?: string;

  /**
   * The callback to call when the primary button is clicked
   */
  onPrimaryButtonClick?: () => void;

  /**
   * The callback to call when the secondary button is clicked
   */
  onSecondaryButtonClick?: () => void;

  /**
   * The callback to call when the textarea value changes
   */
  onTextareaChange?: (value: string) => void;

  /**
   * The callback to call when the textarea loses focus
   */
  onTextareaBlur?: () => void;

  /**
   * The callback to call when the textarea gains focus
   */
  onTextareaFocus?: () => void;
}

/**
 * A EditableCard is a container for a richtext and actions about a single subject.
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 */
export const EditableCard = ({
  className,
  btnLabelPrimary,
  btnLabelSecondary,
  defaultValue,
  isLoading = false,
  loadingText,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  onTextareaChange,
  onTextareaBlur,
  onTextareaFocus,
  ...restProps
}: EditableCardProps) => {
  const classes = {
    container: cn(className, 'e-w-full', {
      'e-glow-100': isLoading,
    }),
  };

  const handleSecondaryBtnClick = () => {
    if (onSecondaryButtonClick) onSecondaryButtonClick();
  };

  const handlePrimaryBtnClick = () => {
    if (onPrimaryButtonClick) onPrimaryButtonClick();
  };

  const handleTextareaChange = (value: string) => {
    if (onTextareaChange) onTextareaChange(value);
  };

  const handleTextareaBlur = () => {
    if (onTextareaBlur) onTextareaBlur();
  };

  const handleTextareaFocus = () => {
    if (onTextareaFocus) onTextareaFocus();
  };

  const renderBody = () => {
    if (isLoading)
      return (
        <div className="e-flex e-w-full e-items-center e-justify-center e-p-3.5 e-gap-1">
          <span className="e-text-creator-primary-50 e-text-xs">{loadingText}</span>
          <CreatorSpinner size={CreatorSpinnerSize.xs} />
        </div>
      );

    return (
      <RichTextarea
        value={defaultValue}
        onBlur={handleTextareaBlur}
        onFocus={handleTextareaFocus}
        onChange={handleTextareaChange}
      />
    );
  };

  const renderFooter = () => {
    if (isLoading) return null;

    return (
      <div className="e-flex e-items-center e-w-full e-gap-3">
        <Button variant={ButtonVariant.ghostNeutral} size={ButtonSize.xs} onClick={handleSecondaryBtnClick} isFullWidth>
          {btnLabelSecondary}
        </Button>
        <Button
          variant={ButtonVariant.tertiaryNeutral}
          size={ButtonSize.xs}
          onClick={handlePrimaryBtnClick}
          isFullWidth>
          {btnLabelPrimary}
        </Button>
      </div>
    );
  };

  return <BaseCard {...restProps} className={classes.container} body={renderBody()} footer={renderFooter()} />;
};
