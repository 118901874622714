import { CalendarView } from '../types';

/**
 * A hook to get the available views for use in the calendar
 * to prevent an error when the `views` changes and the default view is not available
 * in the current `views` prop.
 */
export const useDefaultView = (views: CalendarView[], defaultView?: CalendarView): CalendarView => {
  const defaultCalendarView = defaultView || CalendarView.WEEK;
  if (views.includes(defaultCalendarView)) return defaultCalendarView;
  return views[0];
};
