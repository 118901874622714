import React, { useState, forwardRef } from 'react';
import cn from 'classnames';
import { Icon, IconCatalog } from '../../../Icon/Icon';

export enum ThumbnailVariant {
  primary = 'primary',
  secondary = 'secondary',
}

const VariantBorder: Record<ThumbnailVariant, string> = {
  [ThumbnailVariant.primary]: 'e-border-primary-800',
  [ThumbnailVariant.secondary]: 'e-border-error-800',
};

const VariantActive: Record<ThumbnailVariant, string> = {
  [ThumbnailVariant.primary]: 'e-border-primary-500',
  [ThumbnailVariant.secondary]: 'e-border-error-500',
};

const VariantShadow: Record<ThumbnailVariant, string> = {
  [ThumbnailVariant.primary]: 'e-shadow-center-sm e-shadow-primary-500/50',
  [ThumbnailVariant.secondary]: 'e-shadow-center-sm e-shadow-error-500/50',
};

export interface BaseThumbnailProps {
  /**
   * Descriptive text to be read to screenreaders
   */
  ariaLabel?: string;

  /**
   * The CSS class to apply to the component.
   */
  className?: string;

  /**
   * This is a counter for the stack of images.
   */
  counter: number;

  /**
   * The title of the thumbnail.
   */
  title: string;

  /**
   * Elements to display inside the Thumbnail.
   */
  children: React.ReactNode;

  /**
   * Whether the thumbnail is active.
   */
  isActive?: boolean;

  /**
   * Whether the thumbnail is selected.
   */
  isSelected?: boolean;

  /**
   * Whether the thumbnail is being dragged.
   */
  isDragging?: boolean;

  /**
   * Thumnail active color.
   */
  thumbnailVariant?: ThumbnailVariant;

  /**
   * Callback fired when the thumbnail is clicked.
   */
  onClick?: () => void;
}

/**
 * A Thumbnail is a visual representation of a file or folder.
 */
export const BaseThumbnail = forwardRef<HTMLButtonElement, BaseThumbnailProps>(
  (
    {
      ariaLabel,
      className,
      counter,
      title,
      children,
      isActive = false,
      isSelected = false,
      isDragging = false,
      thumbnailVariant = ThumbnailVariant.primary,
      onClick,
    },
    ref,
  ) => {
    const [dragging, setDragging] = useState(isDragging);

    const classes = {
      button: cn(
        'e-h-18 e-w-18',
        'e-relative e-overflow-hidden e-transform e-translate-x-0 e-translate-y-0',
        'e-bg-base-black e-border e-border-neutral-500 e-rounded-lg',
        {
          [`${VariantBorder[thumbnailVariant]}`]: isActive,
          [`${VariantActive[thumbnailVariant]}`]: isSelected && !isActive,
          [`${VariantShadow[thumbnailVariant]}`]: isSelected && !isActive && !dragging,
        },
        className,
      ),
      header: cn(
        'e-flex e-items-center e-gap-1',
        'e-absolute e-top-0 e-left-0 e-right-0',
        'e-rounded-t-lg',
        'e-h-5',
        'e-z-1',
        'e-truncate',
        'e-text-base-white',
        'e-shadow-lg',
        'e-bg-gradient-to-b e-to-transparent e-from-base-black',
      ),
      headerContent: cn('e-flex e-items-center e-ml-auto e-mr-1'),
      headerText: cn('e-font-bold e-text-2xs'),
      footer: cn(
        'e-absolute e-bottom-0 e-left-0 e-right-0',
        'e-rounded-b-lg',
        'e-h-6 e-p-1.5',
        'e-z-1',
        'e-truncate',
        'e-bg-gradient-to-t e-to-transparent e-from-base-black',
      ),
      footerText: cn('e-text-base-white e-font-semi-bold e-text-2xs e-text-center e-truncate', 'e-shadow-sm'),
    };

    const handleClick = () => {
      if (onClick) onClick();
    };

    const handleDragStart = () => setDragging(true);
    const handleDragEnd = () => setDragging(false);

    return (
      <button
        ref={ref}
        aria-label={ariaLabel}
        className={classes.button}
        onClick={handleClick}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}>
        {/* Header */}
        {counter && (
          <div className={classes.header}>
            <div className={classes.headerContent}>
              <Icon icon={IconCatalog.copy} width={12} height={12} />
              <div className={classes.headerText}>{counter}</div>
            </div>
          </div>
        )}

        {children}

        {/* Footer */}
        {title && (
          <div className={classes.footer}>
            <div className={classes.footerText}>{title}</div>
          </div>
        )}
      </button>
    );
  },
);
