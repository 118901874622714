import React from 'react';
import cn from 'classnames';

interface BaseThumbnailSkeletonProps {
  /**
   * The CSS class to apply to the component.
   */
  className?: string;

  /**
   * Whether the Skeleton has animation.
   */
  hasAnimation?: boolean;
}

export const BaseThumbnailSkeleton = ({ className, hasAnimation = false }: BaseThumbnailSkeletonProps) => {
  const classes = {
    container: cn(
      'e-h-18 e-w-18',
      'e-rounded-lg e-transition-colors',
      'e-bg-neutral-600',
      {
        'e-animate-pulse': hasAnimation,
      },
      className,
    ),
  };

  return <div className={classes.container}></div>;
};
