import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  ChildNestedSelectOption,
  NestedSelectOption,
  MultiSelectorOutput,
  ParentCheckStatus,
  SelectedOption,
  checkParentStatus as checkParentStatusHelper,
  changeChildCheck as changeChildCheckHelper,
  changeParentCheck as changeParentCheckHelper,
  filterOptionsByTerm,
  Colors,
} from '../../../common';
import { TreeOptionsProps, ColorsType } from '../TreeOptions';
import { ParentOptionProps } from '../components/ParentOption/ParentOption';

export interface TreeOptionsContextValues extends Pick<TreeOptionsProps, 'defaultColors' | 'colored'> {
  /**
   * Current option list
   */
  options: Array<NestedSelectOption>;
  /**
   * Output dataset based on the current option state
   */
  outputData: Array<MultiSelectorOutput>;
  /**
   * If true, the component will not have borders
   */
  borderless: boolean;
  /**
   * Config to apply on ParentOption elements
   */
  parentOptionConfig?: Pick<ParentOptionProps, 'selectOnParentClick' | 'parentItemClassName'>;
  /**
   * Ref object with the reference of the colors applied to the checkboxes.
   */
  colorsRef: React.MutableRefObject<ColorsType | undefined>;
  /**
   * Callback that handles the logical assignment of the checkboxes' colors
   */
  pickNextColor: (keyId: string) => string | undefined;
  /**
   * Method to change a Parent check state
   */
  changeParentCheck: (parentValue: string, checked: boolean) => Array<MultiSelectorOutput>;
  /**
   * Method to change a Child check state
   */
  changeChildCheck: (childValue: string, checked: boolean) => Array<MultiSelectorOutput>;
  /**
   * A method to check the children's status of an option (parent)
   */
  checkParentStatus: (option: NestedSelectOption) => ParentCheckStatus;
  /**
   * Method to change an Item's isVisible prop
   */
  showOptions: (term: string) => void;
  /**
   * Reset Option list current state
   */
  resetOptions: () => void;
  /**
   * Emitter for when an option was selected or unselected (Can be parent or child).
   */
  onSelectedOptionChange(option: any, checked: boolean, output?: Array<MultiSelectorOutput>): void;
}

/**
 * Context for TreeOptions component
 * @author Sergio Ruiz Davila<sergio.ruiz@evacenter.com>
 * @contributor Javier Diaz<javier.diaz@evacenter.com>
 * Created at 2022-09-30
 */
export const TreeOptionsContext = createContext<TreeOptionsContextValues>({} as TreeOptionsContextValues);

export interface TreeOptionsProviderProps extends Pick<TreeOptionsProps, 'defaultColors' | 'colored'> {
  /**
   * Default first option list structure
   */
  defaultOptions: Array<NestedSelectOption>;
  /**
   * Default value
   */
  value: Array<MultiSelectorOutput>;
  /**
   * Provide a handler that is called when an option was selected.
   */
  onChange: (output: Array<MultiSelectorOutput>) => void;
  /**
   * Children node
   */
  children: React.ReactNode;
  /**
   * If true, the component will not have borders
   */
  borderless?: boolean;
  /**
   * Config to apply on ParentOption elements
   */
  parentOptionConfig?: Pick<ParentOptionProps, 'selectOnParentClick' | 'parentItemClassName'>;
  /**
   * Provide a handler that is called when an option was selected (Can be parent or child).
   */
  onSelectOption?: (option: SelectedOption, output: Array<MultiSelectorOutput>, colors: ColorsType | undefined) => void;
  /**
   * Provide a handler that is called when an option was unselected (Can be parent or child).
   */
  onUnselectOption?: (
    option: SelectedOption,
    output: Array<MultiSelectorOutput>,
    colors: ColorsType | undefined,
  ) => void;
}

/**
 * `TreeOptionsProvider` component to provide a context
 * @author Javier Diaz<javier.diaz@evacenter.com>
 * Created on 2023-02-01
 */
export const TreeOptionsProvider = ({
  defaultOptions = [],
  value,
  children,
  onChange,
  borderless = false,
  defaultColors,
  parentOptionConfig = {},
  onSelectOption,
  onUnselectOption,
  colored,
}: TreeOptionsProviderProps) => {
  const [options, setOptions] = useState<Array<NestedSelectOption>>(defaultOptions); // NOTE: These options are being mutated directly
  const buildOptionsTreeLoaded = useRef(false);
  const [outputData, setOutputData] = useState<Array<MultiSelectorOutput>>([]);
  const [defaultValue, setDefaultValue] = useState(value);
  const [isAvailableColorsProcessed, setIsAvailableColorsProcessed] = useState(false);
  const [enabledColors, setEnabledColors] = useState<boolean>(colored || Boolean(defaultColors));
  const availableColorsRef = useRef([...Colors]);
  const colorsRef = useRef<ColorsType | undefined>();

  useEffect(() => {
    if (colored || defaultColors) return setEnabledColors(true);
    setEnabledColors(false);
  }, [colored, defaultColors]);

  useEffect(() => {
    if (!defaultColors || colorsRef.current) return;
    colorsRef.current = defaultColors;
  }, [defaultColors]);

  useEffect(() => {
    if (!defaultColors || isAvailableColorsProcessed) return;
    const filteredColors = availableColorsRef.current.filter((color) => {
      for (const key in defaultColors) {
        if (defaultColors[key].value === color) return false; // Exclude the color from the filtered array
      }
      return true; // Include the color in the filtered array
    });
    availableColorsRef.current = filteredColors;
    setIsAvailableColorsProcessed(true);
  }, [defaultColors]);

  const pickNextColor = (keyId: string) => {
    if (!enabledColors) return;

    // Check if the key id has already assigned a color.
    // If yes, don't assign a new color to the list.
    if (colorsRef.current) {
      if (colorsRef.current[keyId]) return;
    }

    if (availableColorsRef.current.length === 0) {
      availableColorsRef.current = [...Colors];
    }

    const color = availableColorsRef.current.shift(); // Remove the first element from availableColors

    const colorsFormatted = addAndFormatColor(color as string, keyId);
    colorsRef.current = colorsFormatted;
    return color;
  };

  const addAndFormatColor = (color: string, keyId: string) => ({
    ...colorsRef?.current,
    [keyId]: { value: color },
  });

  const buildOutputData = (newOptions: Array<NestedSelectOption>) => {
    const output = newOptions
      .filter((option) => option.checked || option.indeterminate)
      .map((option) => {
        const checkedOptionsValues = option.options?.reduce((prevOptions, item) => {
          if (!item.checked) return prevOptions;
          return [...prevOptions, item.value];
        }, [] as Array<NestedSelectOption['value']>);
        return {
          parent: { label: option.label, value: option.value, status: checkParentStatus(option) },
          options: option.options ? checkedOptionsValues : [],
        };
      });

    showOptions('');
    setOptions(newOptions);
    setOutputData(output as Array<MultiSelectorOutput>);

    if (onChange) onChange(output as Array<MultiSelectorOutput>);

    return output as Array<MultiSelectorOutput>;
  };

  const checkParentStatus = (parent: NestedSelectOption): ParentCheckStatus => checkParentStatusHelper(parent);

  const changeParentCheck = (parentValue: string, checked: boolean) => {
    const newOptions = changeParentCheckHelper(options, parentValue, checked, colored ? pickNextColor : undefined);
    return buildOutputData(newOptions);
  };

  const changeChildCheck = (childValue: string, checked: boolean) => {
    const newOptions = changeChildCheckHelper(options, childValue, checked);
    return buildOutputData(newOptions);
  };

  const onSelectedOptionChange = (
    { label, value, type }: NestedSelectOption | ChildNestedSelectOption,
    checked: boolean = false,
    output?: Array<MultiSelectorOutput>,
  ) => {
    if (checked && onSelectOption) onSelectOption({ label, value, type, checked }, output || [], colorsRef.current);
    if (!checked && onUnselectOption)
      onUnselectOption({ label, value, type, checked }, output || [], colorsRef.current);
  };

  const buildOptionsTree = (output: Array<MultiSelectorOutput>) => {
    output.forEach((element) => {
      if (element.options.length === 0) {
        changeParentCheck(element.parent.value, true);
        return;
      }
      element.options.forEach((option) => changeChildCheck(option, true));
    });
    // Clean default value since the options are already configurated
    setDefaultValue([]);
  };

  // This effect helps to build the options tree listening the value and option changes.
  // We need to wait for both options and values in order to build properly the first load only.
  useEffect(() => {
    if (buildOptionsTreeLoaded.current || !value.length || !options.length) return;
    buildOptionsTree(value);
    buildOptionsTreeLoaded.current = true;
  }, [value, options]);

  useEffect(() => {
    setOptions(defaultOptions);
  }, [defaultOptions]);

  const showOptions = (term: string) => {
    if (!options.length) return;
    const filteredOptions = filterOptionsByTerm(options, term);
    setOptions(filteredOptions);
  };

  const resetOptions = () => options.forEach((parent) => changeParentCheck(parent.value, false));

  const treeOptionsProviderValue = useMemo(
    () => ({
      options,
      outputData,
      changeParentCheck,
      changeChildCheck,
      checkParentStatus,
      showOptions,
      resetOptions,
      colorsRef,
      pickNextColor,
      defaultColors,
      colored: enabledColors,
      borderless,
      onSelectedOptionChange,
      parentOptionConfig,
    }),
    [options, borderless, defaultValue],
  );

  return <TreeOptionsContext.Provider value={treeOptionsProviderValue}>{children}</TreeOptionsContext.Provider>;
};

/**
 * Hook to use the TreeOptionsContext
 * @author Javier Diaz<javier.diaz@evacenter.com>
 * Created on 2023-02-01
 */
export const useTreeOptions = (): TreeOptionsContextValues => {
  const context = useContext(TreeOptionsContext);
  if (!context) throw new Error('useTreeOptions must be used within a TreeOptionsProvider');
  return context;
};
