import React, { useEffect } from 'react';
import cn from 'classnames';
import CMenu from './utils/CMenu';
import { IconCatalog } from '../Icon/Icon';
import './CircularMenu.css';

export interface MenuType {
  icon: IconCatalog;
  menus?: MenuType[];
}

export interface Coordinate {
  x: number;
  y: number;
}

export interface CircularMenuProps {
  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * Set the list of the menu items
   */
  menu: Array<MenuType>;

  /**
   * Set the menu open state
   */
  isOpen?: boolean;

  /**
   * Set the menu position
   */
  coordinates?: Coordinate;

  /**
   * Set the menu close state after click
   */
  hideAfterClick?: boolean;
}

/**
 * This is a circular menu component that allows you to display a menu in a circular shape.
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2023-10-27
 */
export const CircularMenu = ({
  className,
  menu = [],
  isOpen = false,
  coordinates = { x: 0, y: 0 },
  hideAfterClick = true,
}: CircularMenuProps) => {
  const classes = {
    container: cn(className),
  };

  const { x, y } = coordinates;

  const CMenuInstance = (selector: string) => new CMenu(document.querySelector(selector));

  const getInstance: any = () =>
    CMenuInstance('#circular-menu').config({
      totalAngle: 360, //deg,
      spaceDeg: 0, //deg
      background: '#0E0E0E',
      backgroundHover: 'rgba(3, 49, 127, 0.8)',
      pageBackground: 'transparent',
      percent: 0.32, //%
      diameter: 300, //px
      position: 'top',
      horizontal: true,
      start: 0,
      animation: 'into',
      menus: menu,
      hideAfterClick,
    });

  useEffect(() => {
    const instance = getInstance();
    if (isOpen) instance.show([x, y]);
    else instance.destroy();
  }, [isOpen]);

  return <div id="circular-menu" onContextMenu={(event) => event.preventDefault()} className={classes.container}></div>;
};

/**
 * reference:
 * https://github.com/yandongCoder/circular-menu
 */
