export enum ViewerInformationState {
  visible = 'visible',
  hiddenL = 'hiddenL',
  hiddenR = 'hiddenR',
  leftAligned = 'leftAligned',
  rightAligned = 'rightAligned',
}

export type ViewerInformationPatientInfo = {
  name: string;
  age: string;
  gender: string;
  birthDate: string;
  studyName: string;
  organization: string;
  studyDate: string;
  studyDateFormatted: string;
  facility: string;
};
