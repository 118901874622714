import cn from 'classnames';
import React from 'react';

enum DESIGN_SIZES {
  mobile = 320,
  tablet = 991,
  desktop = 1199,
}

interface ViewerInfoTextProps {
  /**
   * The width of the parent container.
   */
  parentWidth?: number;
  /**
   * The CSS class to apply to the component.
   */
  className?: string;
  /**
   * The CSS class to apply to the title.
   */
  titleClassName?: string;
  /**
   * The CSS class to apply to the info.
   */
  infoClassName?: string;
  /**
   * The info to display.
   */
  info?: string;
  /**
   * The title of the text.
   */
  title?: string;
}

export const ViewerInfoText = ({
  info,
  title,
  className,
  parentWidth,
  infoClassName,
  titleClassName,
}: ViewerInfoTextProps) => {
  const classes = {
    text: cn('e-flex', className),
  };

  const titleExist = Boolean(title?.length);
  const infoExist = Boolean(info?.length);
  const style = parentWidth === undefined ? parentWidth : getViewerTextStyles(parentWidth);

  if (!titleExist && !infoExist) return null;

  return (
    <p className={cn(classes.text)} style={style}>
      {titleExist && <span className={titleClassName}>{title}</span>}
      {infoExist && <span className={infoClassName}>{info}</span>}
    </p>
  );
};

const getViewerTextStyles = (parentWidth: number) => {
  if (parentWidth < DESIGN_SIZES.mobile) return { display: 'none' };
  if (parentWidth < DESIGN_SIZES.tablet) return { fontSize: '8px', fontWeight: 500, lineHeight: '9.6px' };
  if (parentWidth < DESIGN_SIZES.desktop) return { fontSize: '10px', fontWeight: 500, lineHeight: '12px' };
  return { fontSize: '12px', fontWeight: 500, lineHeight: '15.6px' };
};
