import React, { ReactNode, ButtonHTMLAttributes } from 'react';
import cn from 'classnames';
import { CreatorLogo } from '../../Logo/Logo';

export enum CreatorButtonSize {
  xs = 'xs',
  sm = 'sm',
}

const CreatorButtonSizes: Record<CreatorButtonSize, string> = {
  [CreatorButtonSize.xs]: 'e-py-1 e-px-6 e-text-base e-font-medium e-h-8',
  [CreatorButtonSize.sm]: 'e-py-2 e-px-6 e-text-base e-font-medium e-h-10',
};

const CreatorButtonLogoSizes: Record<CreatorButtonSize, string> = {
  [CreatorButtonSize.xs]: 'e-w-24 e-h-3',
  [CreatorButtonSize.sm]: 'e-w-24 e-h-3',
};

export enum CreatorButtonVariant {
  primary = 'primary',
}

export enum CreatorButtonContentAlignment {
  center = 'center',
  spaceBetween = 'space-between',
  evenly = 'evenly',
}

const Variants: Record<CreatorButtonVariant, string> = {
  [CreatorButtonVariant.primary]:
    'e-bg-transparent hover:e-bg-creator-primary-50 e-border e-border-creator-primary-50 e-text-creator-primary-50 e-glow-50 hover:e-glow-100',
};

const LogoVariants: Record<CreatorButtonVariant, string> = {
  [CreatorButtonVariant.primary]: 'group-hover:e-text-base-black',
};

const DisabledVariants: Record<CreatorButtonVariant, string> = {
  [CreatorButtonVariant.primary]: 'e-border e-text-base-white e-border-neutral-300 e-opacity-60',
};

export enum CreatorButtonHtmlType {
  button = 'button',
  reset = 'reset',
  submit = 'submit',
}

export interface CreatorButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * The shape of the component. It determines the importance in the hierarchy, for example, the contained button commands the most attention
   */
  variant?: CreatorButtonVariant;

  /**
   * Set the size of the button.
   */
  size?: CreatorButtonSize;

  /**
   * Disables the button, disallowing user interaction.
   */
  isDisabled?: boolean;

  /**
   * If set to true, the button will display a loading effect.
   */
  isLoading?: boolean;

  /**
   * Set a diferent loading text for your button.
   */
  loadingText?: string;

  /**
   * Extends the button to 100% width.
   */
  isFullWidth?: boolean;

  /**
   * HTML type attribute of the button.
   */
  htmlType?: CreatorButtonHtmlType;

  /**
   * Elements to display inside the Navbar.
   */
  children?: ReactNode;
}

/**
 * This component represents the button used in the creator app.
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2023-07-07
 */
export const CreatorButton = ({
  children,
  isDisabled = false,
  isLoading = false,
  loadingText,
  isFullWidth = false,
  size = CreatorButtonSize.sm,
  variant = CreatorButtonVariant.primary,
  htmlType = CreatorButtonHtmlType.button,
  className,
  onClick,
  ...restOfProps
}: CreatorButtonProps) => {
  const setVariants = () => {
    if (isDisabled) return DisabledVariants[variant];

    return Variants[variant];
  };

  const classes = {
    button: cn(
      className,
      'e-group e-flex e-items-center e-justify-center e-relative e-overflow-hidden e-min-w-fit',
      'e-text-center e-whitespace-nowrap',
      'e-transition e-duration-100 e-ease-out',
      'e-rounded-lg',
      CreatorButtonSizes[size],
      setVariants(),
      {
        'e-w-full': isFullWidth,
      },
    ),
    logo: cn(CreatorButtonLogoSizes[size], !isDisabled && LogoVariants[variant]),
    loading: cn(
      'e-absolute e-left-0 e-top-0 e-opacity-30',
      'e-w-full e-h-full',
      'e-flex e-items-center e-justify-center',
      'after:e-content-[""] after:e-absolute after:e-h-full after:e-w-full after:e-animate-translation-x',
      'before:e-content-[""] before:e-absolute before:e-h-full before:e-w-full before:e-animate-translation-x',
      {
        'after:e-bg-creator-primary-100 before:e-bg-creator-primary-100': variant === CreatorButtonVariant.primary,
      },
    ),
  };

  /* Render JSX */
  return (
    <button
      className={classes.button}
      type={htmlType}
      disabled={isDisabled || isLoading}
      onClick={onClick}
      {...restOfProps}>
      {/* Eden Creator Logo */}
      <CreatorLogo className={classes.logo} />
      {isLoading && <span className={classes.loading}></span>}
    </button>
  );
};
