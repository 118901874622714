import React, { useEffect } from 'react';
import { useClipboard } from 'use-clipboard-copy';
import { Button, ButtonProps } from '../Button/Button';
import { COPIED_TIMEOUT, Locale, Translations } from '../../../common';

const translations: Translations = {
  en: {
    copy: 'Copy',
    copied: 'Copied',
  },
  es: {
    copy: 'Copiar',
    copied: 'Copiado',
  },
  pt: {
    copy: 'Cópia',
    copied: 'Copiado',
  },
};

export interface ClipboardCopyButtonProps extends ButtonProps {
  /**
   * The locale to be used by the component
   */
  locale: Locale;

  /**
   * The text to copy into the clipboard
   */
  textToCopy: string;

  /**
   * The text to display when the text was copied
   */
  btnLabelCopied?: string;

  /**
   * The text to display when the text was not copied
   */
  btnLabelCopy?: string;

  /**
   * The time in milliseconds to display the copied text
   */
  copiedTimeout?: number;

  /**
   * The callback to get notified when the button was clicked
   */
  onCopyBtnClick?: (textCopied: string) => void;

  /**
   * The callback to get notified when the text was copied
   */
  onCopied?: (copied: boolean) => void;
}

/**
 * Use this button component to copy a text into the clipboard
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2023-07-20
 */
export const ClipboardCopyButton = ({
  locale = Locale.es,
  btnLabelCopied,
  btnLabelCopy,
  copiedTimeout = COPIED_TIMEOUT,
  textToCopy,
  onCopyBtnClick,
  onCopied,
  ...restOfProps
}: ClipboardCopyButtonProps) => {
  const clipboard = useClipboard({ copiedTimeout });
  const btnCopied = btnLabelCopied || translations[locale].copied;
  const btnCopy = btnLabelCopy || translations[locale].copy;

  useEffect(() => {
    if (onCopied) onCopied(clipboard.copied);
  }, [clipboard.copied]);

  const handleClick = (): void => {
    clipboard.copy(textToCopy);
    if (onCopyBtnClick) onCopyBtnClick(textToCopy);
  };

  const renderBtnLabel = () => (clipboard.copied ? btnCopied : btnCopy);

  return (
    <Button onClick={handleClick} {...restOfProps}>
      {renderBtnLabel()}
    </Button>
  );
};
