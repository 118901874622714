import { useState, CSSProperties, useEffect } from 'react';

interface UseNumCberInputStyleProps {
  /**
   * The value of the input.
   */
  value?: number;

  /**
   * The flag that indicates whether the input is focused.
   */
  isFocused?: boolean;

  /**
   * The unit of the input value.
   */
  unit?: string;
}

/**
 * A hook that returns the style of the input.
 * @author Carlos Cuatin<carlos.cuatin@edenmed.com
 * Created at 2023-11-02
 */
export const useNumberInputStyle = ({ value, isFocused, unit }: UseNumCberInputStyleProps) => {
  const [style, setStyle] = useState<CSSProperties>({});

  useEffect(() => {
    if (unit) {
      if (value || value === 0) {
        setStyle({ width: `${String(value).length}ch` });
      } else if (isFocused && !value) {
        setStyle({ width: '1ch' });
      } else if (!isFocused && !value) setStyle({ width: '0ch' });
    } else {
      setStyle({ width: '100%' });
    }
  }, [unit, value, isFocused]);

  return style;
};
