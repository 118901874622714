import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import { Trigger, TabsTriggerProps } from '@radix-ui/react-tabs';

import { Icon, IconCatalog, getDataStateAttribute } from '../../../..';

const tabIconSize = 24;

export enum TabMobileType {
  default,
  danger,
}

/**
 * @deprecated Use `react-tabs` instead
 * https://github.com/eva-tech/ui-kit/pkgs/npm/react-tabs
 */
export interface TabMobileItemProps extends TabsTriggerProps {
  /**
   * The custom class name for the component.
   */
  className?: string;

  /**
   * The icon to display.
   */
  icon: IconCatalog;

  /**
   * Disables the button, disallowing user interaction.
   */
  isDisabled?: boolean;

  /**
   * Text to display inside the Navbar.
   */
  label: string;

  /**
   * The type of the button.
   */
  tabType?: TabMobileType;
}

export const TabMobileItem = ({
  icon,
  label,
  className,
  isDisabled,
  tabType = TabMobileType.default,
  ...restOfProps
}: TabMobileItemProps) => {
  const itemReference = useRef<HTMLButtonElement>(null);
  const [selected, setSelected] = useState(false);

  const setSelectedClasses = () => {
    if (isDisabled) return 'e-border-t-2 e-border-transparent';
    if (selected) return 'e-border-t-2 e-border-primary-500 e-bg-neutral-700 e-text-neutral-50';
    return 'e-border-t-2 e-border-transparent e-bg-neutral-900 e-text-neutral-200';
  };

  const classes = {
    tab: cn(
      className,
      'e-gap-1 e-p-2 e-text-sm e-flex e-items-center e-justify-center e-flex-col eva-hide-scrollbar',
      'e-font-medium e-text-2xs e-outline-none',
      setSelectedClasses(),
      {
        'e-opacity-30 e-cursor-not-allowed': isDisabled,
      },
    ),
    icon: cn({
      'e-text-error-500': tabType === TabMobileType.danger,
    }),
  };

  // Listen when a component change it's active behavior
  useEffect(() => {
    const currentElement = itemReference.current;
    if (!currentElement) return;

    const observer = new MutationObserver((mutationsList) => {
      const isActive = getDataStateAttribute(mutationsList, currentElement);
      setSelected(isActive || false);
    });

    observer.observe(currentElement, { attributes: true });

    return () => observer.disconnect();
  }, []);

  return (
    <Trigger ref={itemReference} className={classes.tab} {...restOfProps}>
      <Icon className={classes.icon} icon={icon} width={tabIconSize} height={tabIconSize} />
      <span>{label}</span>
    </Trigger>
  );
};
