import React from 'react';
import cn from 'classnames';

interface StudyCardSkeletonProps {
  /**
   * The CSS class to apply to the component.
   */
  className?: string;

  /**
   * Whether the Skeleton has animation.
   */
  hasAnimation?: boolean;
}

export const StudyCardSkeleton = ({ className, hasAnimation = false }: StudyCardSkeletonProps) => {
  const classes = {
    container: cn(
      'e-flex e-items-center e-justify-between',
      'e-rounded-lg e-p-3',
      'e-h-28',
      'e-rounded-lg e-transition-colors',
      'e-bg-neutral-800',
      {
        'e-animate-pulse': hasAnimation,
      },
      className,
    ),
    children: cn('e-rounded-sm e-h-4 e-bg-neutral-700 e-animate-skeleton'),
    thumbnail: cn(
      'e-flex e-shrink-0 e-grow-0',
      'e-w-12 e-h-12',
      'e-bg-neutral-700',
      'e-rounded-lg',
      'e-animate-skeleton',
    ),
  };

  return (
    <div className={classes.container}>
      <div className="e-flex e-flex-col e-gap-2 e-pr-4 e-w-full">
        <div className={cn(classes.children, 'e-w-3/4')}></div>
        <div className={cn(classes.children, 'e-w-1/3')}></div>
        <div className={cn(classes.children, 'e-w-1/3')}></div>
      </div>
      <div className={classes.thumbnail}></div>
    </div>
  );
};
