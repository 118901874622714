import React, { ChangeEvent } from 'react';
import monthsEs from '../../../../common/constants/months.es';
import monthsEn from '../../../../common/constants/months.en';
import monthsPt from '../../../../common/constants/months.pt';
import { generateYearsBetween } from '../../../../common/utils/helpers';
import { DatePickerSelect } from '../DatePickerSelect/DatePickerSelect';
import { Locale, type SelectOption } from '../../../../common';

const monthsLocale: Record<string, Array<SelectOption>> = {
  es: monthsEs,
  en: monthsEn,
  pt: monthsPt,
};

export interface DatePickerHeaderProps {
  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * Selected date
   */
  date: Date;

  /**
   * Set a minimum Year on the Year Select Dropdown
   */
  minYear?: number;
  /**
   * Set a maximum Year on the Year Select Dropdown
   */
  maxYear?: number;

  /**
   * Provide a method that is called when the Select year has changed.
   */
  changeYear: (year: number) => void;

  /**
   * Provide a method that is called when the Select month has changed.
   */
  changeMonth: (month: number) => void;

  /**
   * Provide a method that is called when the Left Arrow was clicked.
   */
  decreaseMonth: () => void;

  /**
   * Provide a method that is called when the Right Arrow was clicked.
   */
  increaseMonth: () => void;

  /**
   * Whether the Left arrow is disabled
   */
  prevMonthButtonDisabled: boolean;

  /**
   * Whether the Right arrow is disabled
   */
  nextMonthButtonDisabled: boolean;

  /**
   * Set the locale to be used by the component
   */
  locale?: string;
}

/**
 * This component represent the Date Picker Header (DatePicker)
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2022-08-25
 */
export const DatePickerHeader = ({ locale = Locale.es, date, minYear, maxYear, changeYear, changeMonth }: DatePickerHeaderProps) => {
  const datepickerMonths = monthsLocale[locale];
  const handleMonthChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const {
      target: { value },
    } = event;
    changeMonth(datepickerMonths.findIndex((month) => month.value === value));
  };

  const handleYearChange = (event: ChangeEvent<HTMLSelectElement>) => changeYear(parseInt(event.target.value));

  /*
   * Render JSX
   */
  return (
    <div className="e-flex e-justify-center e-items-center">
      <DatePickerSelect
        options={generateYearsBetween(minYear, maxYear)}
        value={date.getFullYear()}
        onChange={handleYearChange}
      />
      <DatePickerSelect
        options={datepickerMonths}
        value={datepickerMonths[date.getMonth()].value}
        onChange={handleMonthChange}
      />
    </div>
  );
};
