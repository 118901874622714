import { createContext, useContext } from 'react';
import { withDragAndDropProps } from 'react-big-calendar/lib/addons/dragAndDrop';
import { CalendarProps } from 'react-big-calendar';
import { CalendarLocale, CalendarView, Event, Resource } from '../types';

/**
 * Calendar's context definition
 */
export const CalendarContext = createContext<CalendarContextProps>({} as CalendarContextProps);

export interface CalendarContextProps {
  /**
   * The current date of the calendar.
   */
  date: Date;
  /**
   * The current view of the calendar.
   */
  view: CalendarView;
  /**
   * The current view of the calendar.
   */
  defaultView?: CalendarView;
  /**
   * The views availables in the calendar
   */
  views: Array<CalendarView>;
  /**
   * The locale of the calendar
   */
  locale: CalendarLocale;
  /**
   * Events to display in the calendar.
   */
  events: Array<Event>;
  /**
   * The resources to display in the calendar on `day` view.
   */
  resources: Array<Resource>;
  /**
   * Determines the selectable time increments in week and day views
   */
  step: number;
  /**
   * Function to change the current view of the calendar.
   */
  handleChangeView: (view: CalendarView) => void;
  /**
   * Function to change the current date of the calendar.
   */
  handleChangeDate: (date: Date) => void;
  /**
   * Function to handle when a event is moved.
   */
  handleOnMoveEvent: withDragAndDropProps<Event>['onEventDrop'];
  /**
   * Function to handle when a event is resized.
   */
  handleOnResizeEvent: withDragAndDropProps<Event>['onEventResize'];
  /**
   * Function to handle when a event is selected.
   */
  handleOnSelectEvent: CalendarProps<Event>['onSelectEvent'];
  /**
   * Function to handle when a slot is selected (only for month view).
   */
  handleOnSelectSlot: CalendarProps<Event>['onSelectSlot'];
  /**
   * Function to handle when a slot is selected (only for time views).
   */
  handleOnSelecting: CalendarProps<Event>['onSelecting'];
}

export const useCalendarContext = () => {
  const context = useContext(CalendarContext);
  if (!context) throw new Error('useCalendarContext must be used within a Calendar');

  return context;
};
