import { Event as BigCalendarEvent, ResourceHeaderProps } from 'react-big-calendar';

export enum CalendarView {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

export enum WeekStartsOn {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

export enum CalendarLocale {
  EN = 'en',
  ES = 'es',
  PT = 'pt',
}

export interface Resource {
  id: number | string;
  title: string;
  [key: string]: any;
}

export interface Event extends BigCalendarEvent {
  [key: string]: any;
}

export enum Hover {
  ENTER = 'enter',
  LEAVE = 'leave',
}

export interface CustomEvents {
  onHoverEvent?: (event: Event, type: Hover, e: React.SyntheticEvent) => void;
}

export interface DateRange {
  start: Date;
  end: Date;
}

export interface ResourceHeaderRenderProps extends ResourceHeaderProps {}
