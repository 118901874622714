import React, { ReactNode, useEffect } from 'react';
import cn from 'classnames';
import { Icon, IconCatalog } from '../../../../Icon/Icon';

export interface ButtonProps {
  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * Set the tab id.
   */
  tabId: string;

  /**
   * Elements to display inside the Option.
   */
  children?: ReactNode;

  /**
   * Whether the tab is truncated or not
   */
  isTabTruncated?: boolean;

  /**
   * Icon to display when the container width is too small
   */
  iconTruncate?: IconCatalog;

  /**
   * Whether the button is active or not
   */
  isActive?: boolean;

  /**
   * Callback when the button was clicked
   */
  onClick: (event: React.MouseEvent, tabId: string) => void;

  /**
   * Callback when the button was mounted
   */
  onMounted?: (tabId: string) => void;
}

/**
 * Represents a button to use in the PillTabs component
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2024-01-18
 */
export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, tabId, isTabTruncated = false, isActive = false, children, iconTruncate, onClick, onMounted }, ref) => {
    const classes = cn(
      'e-px-2 e-py-1.5 e-h-8',
      'e-leading-none e-z-1 e-box-border',
      'e-transition-colors e-duration-300',
      'e-text-sm',
      {
        'e-text-neutral-900 e-font-bold': isActive,
        'e-text-neutral-100 e-font-medium': !isActive,
        'e-flex-grow e-truncate e-flex-1': !isTabTruncated || isActive,
        'e-flex-none e-flex e-items-center e-justify-center e-w-18': isTabTruncated && !isActive,
      },
      className,
    );

    useEffect(() => {
      if (onMounted) onMounted(tabId);
    }, []);

    const handleClick = (event: React.MouseEvent) => {
      if (onClick) onClick(event, tabId);
    };

    /* Render JSX */
    return (
      <button ref={ref} data-tab-id={tabId} className={classes} onClick={handleClick}>
        {isTabTruncated && iconTruncate && !isActive ? <Icon icon={iconTruncate} width={24} height={24} /> : children}
      </button>
    );
  },
);
