import React, { ChangeEvent, FocusEvent, forwardRef, InputHTMLAttributes } from 'react';
import cn from 'classnames';
import { FormFieldState, Input } from '../../../../common';

export interface DigitInputProps extends Input, Omit<InputHTMLAttributes<HTMLInputElement>, 'required' | 'onChange'> {
  /**
   * Set if th input allows 1 digit or 2 digits.
   */
  maxLength?: 1 | 2 | 4;

  /**
   * The minimum value of the input.
   */
  min?: number;

  /**
   * The handler to get notified when the value changes.
   */
  onChange: (value: string) => void;
}

/**
 * An Digit Input allows the user to interact with and input 1-time code or sensitive data.
 * @author Sergio Ruiz<sergio.ruiz@evacenter.com>
 * Created at 2024-06-18
 */
export const DigitInput = forwardRef<HTMLInputElement, DigitInputProps>(
  (
    {
      id,
      isReadOnly = false,
      isLoading = false,
      isRequired = false,
      isDisabled = false,
      fieldState = FormFieldState.default,
      autoComplete = 'off',
      className,
      dataTestId,
      value = '',
      maxLength = 1,
      name,
      onChange,
      onBlur,
      onFocus,
      ...restOfProps
    },
    ref,
  ) => {
    const classes = {
      input: cn(
        'e-border e-bg-neutral-700 e-text-base-white e-text-xl e-text-center',
        'e-rounded-2xl',
        'e-transition e-duration-100 e-ease-out e-outline-none',
        'placeholder:e-text-neutral-500 e-font-light focus:e-border-neutral-400 e-caret-base-white',
        {
          'e-border-neutral-500': fieldState === FormFieldState.default,
          'e-border-error-500': fieldState === FormFieldState.error,
          'e-border-neutral-400': fieldState === FormFieldState.active,
          'e-animate-pulse': isLoading,
        },
        {
          'e-w-28 e-h-16': maxLength === 4,
          'e-w-16 e-h-16': maxLength !== 4,
        },
      ),
    };

    const formatValue = (value: string): string => {
      if (value.length === 1 && maxLength === 2 && value !== '0') return `0${value}`;
      return value;
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
      const newValue = event.target.value;
      if (!/^\d*$/.test(newValue)) return;
      if (newValue.length > maxLength) return;

      if (onChange) onChange(newValue);
    };

    const handleInputBlur = (event: FocusEvent<HTMLInputElement>): void => {
      const value = event.target.value;
      if (onBlur) onBlur(event);

      // Format value to 2 digits
      const newValue = formatValue(value);
      if (onChange && maxLength === 2) onChange(newValue);
    };

    const handleInputFocus = (event: FocusEvent<HTMLInputElement>): void => {
      if (onFocus) onFocus(event);
    };

    /* Render JSX */
    return (
      <input
        id={id}
        data-testid={dataTestId}
        ref={ref}
        type="text"
        inputMode="numeric"
        name={name}
        className={classes.input}
        value={value}
        readOnly={isReadOnly}
        min={0}
        required={isRequired}
        autoComplete={autoComplete}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        onFocus={handleInputFocus}
        disabled={isDisabled}
        {...restOfProps}
      />
    );
  },
);
