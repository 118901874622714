import style from './../style';
import classed from './../classed';
import on from './../on';
import styleSheet from './styleSheet';

function hasSubMenus(menus) {
  return menus instanceof Array && menus.length !== 0;
}
function ifDisabled(disabled) {
  if (disabled instanceof Function) return disabled();
  else return Boolean(disabled);
}

function setHref(ele, href) {
  if (!href) return;

  if (href instanceof Object) {
    ele.href = href.url;
    ele.target = href.blank ? '_blank' : '';
  } else {
    ele.href = href;
  }
}

function setAfterContent(ele, content) {
  ele.setAttribute('data-content', content);
}

var delayShow = null; // delayShow reference the last setTimeout triggered by any one of menu item(anchor)

export default function (parent, data, index) {
  var self = this;

  const menuContainer = document.querySelector('#circular-menu');

  var delayHide = null; // delayHide reference the last setTimeout triggered by the menu item itself

  var a = document.createElement('a');

  setHref(a, data.href);

  a.setDisabled = function () {
    classed(a, 'disabled', ifDisabled(data.disabled));
  };
  this._anchors.push(a);

  a.setAttribute('class', 'e-group hover:e-text-primary-200');

  style(a, 'width', this._calc.clickZoneSize.width);
  style(a, 'height', this._calc.clickZoneSize.height);
  style(a, 'right', this._calc.clickZoneSize.marginRight);
  style(a, 'bottom', this._calc.clickZoneSize.marginBottom);
  style(a, 'transform', 'skew(' + -this._calc.skewDeg + 'deg) rotate(' + this._calc.unskewDeg + 'deg) scale(1)');

  classed(a, 'disabled', ifDisabled(data.disabled));

  var percent = this._config.percent * 100 + '%';
  styleSheet(
    a,
    'background',
    'radial-gradient(transparent ' + percent + ', ' + this._config.background + ' ' + percent + ')',
  );
  styleSheet(
    a,
    'background',
    //NOTE: Original implementation: 'radial-gradient(transparent ' + percent + ', ' + this._config.backgroundHover + ' ' + percent + ')',
    'linear-gradient(192.53deg, #03317F 11.08%, #2173FF 30.00%), linear-gradient(0deg, #0A3F99, #0A3F99), linear-gradient(0deg, #181818, #181818),linear-gradient(0deg, #2173FF, #2173FF)',
    'hover',
  );

  function clickCallBack(e, data) {
    if (data.click) data.click.call(this, e, data);

    if (self._config.hideAfterClick) {
      self._cMenu.hide();
      if (self._cMenu._pMenu) self._cMenu._pMenu.hide();
      if (subMenu) subMenu.hide();
    }
  }

  on(a, 'click', clickCallBack, data);

  on(a, 'contextmenu', (event) => event.preventDefault(), {});

  on(a, 'mouseenter', function () {
    // Asign label to the content center
    setAfterContent(menuContainer, data.label);
  });

  on(a, 'mouseleave', function (e) {
    // Remove label from the content center
    setAfterContent(menuContainer, '');
  });

  parent.appendChild(a);

  this._createHorizontal(a, data, index);

  //toggle subMenu
  if (hasSubMenus(data.menus)) {
    var subMenu = this._createSubMenu(self, data.menus, index);

    on(a, 'mouseenter', function () {
      delayShow = setTimeout(function () {
        subMenu
          .styles({
            top: self._container.offsetTop + self._calc.radius + 'px',
            left: self._container.offsetLeft + self._calc.radius + 'px',
          })
          .show();
      }, 150);
    });

    on(a, 'mouseleave', function (e) {
      if (!subMenu._container.contains(e.toElement)) {
        delayHide = setTimeout(function () {
          subMenu.hide();
        }, 200);
      }
    });

    on(subMenu._container, 'mouseenter', function () {
      clearTimeout(delayShow);
      clearTimeout(delayHide);
    });

    on(subMenu._container, 'mouseleave', function (e) {
      if (!a.contains(e.toElement) || e.toElement.children[0] === a) {
        subMenu.hide();
      }
    });
  }
}
