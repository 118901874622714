import style from './../style';
import classed from './../classed';

const sizeRatio = 0.9;
const marginTopRatio = 0.2;
const fontHeight = 13;

export function hasIcon(icon) {
  if (icon === undefined) return false;
  else if (typeof icon === 'string') return icon !== '';
  else return icon.length && icon[0] !== '';
}

function getIcon(icon) {
  return typeof icon === 'string' ? icon : icon[0];
}

function getIconColor(icon) {
  return typeof icon === 'string' ? null : icon[1];
}

export default function (parent, data, index) {
  if (!hasIcon(data.icon)) return;

  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');

  // add attributes
  svg.setAttribute('id', index);
  svg.setAttribute('class', 'cm-icon e-text-neutral-300 group-hover:e-text-primary-100');
  svg.setAttribute('width', this._calc.clickZoneRadius * sizeRatio - fontHeight);
  svg.setAttribute('height', this._calc.clickZoneRadius * sizeRatio - fontHeight);
  svg.setAttribute('fill', 'currentColor');
  svg.setAttribute('viewBox', '0 0 24 24');
  svg.setAttribute('focusable', 'false');
  svg.setAttribute('aria-hidden', 'true');

  const m = this._calc.clickZoneRadius * marginTopRatio - fontHeight + 'px';

  style(svg, 'margin-top', m);
  style(svg, 'padding', '0.9rem');

  // add path
  const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');

  path.setAttribute('fill-rule', 'evenodd');
  path.setAttribute('clip-rule', 'evenodd');
  path.setAttribute('d', data.icon);

  // append path to svg
  svg.appendChild(path);

  // append svg to parent
  parent.appendChild(svg);
}
