import React from 'react';
import cn from 'classnames';

import { ViewerInformationPatientInfo, ViewerInformationState } from '../ViewerInfoContainerTypes';
import { ViewerInfoText } from '../ViewerInfoText/ViewerInfoText';
import { t } from '../../../Calendar/i18n';
import { CalendarLocale } from '../../../Calendar';
import { twMerge } from '../../../../common';
import { useViewerInfoProviderContext } from '../ViewerInfoContainerContext';

export interface ViewerInfoHeaderDesktopProps {
  /**
   * The CSS class to apply to the component.
   */
  className?: string;

  /**
   * The width of the parent container.
   */
  parentWidth?: number;

  /**
   * The locale to be used.
   */
  locale: CalendarLocale | string;

  /**
   * Whether the component is being rendered on a mobile device or not.
   */
  isMobile?: boolean;

  /**
   * The patient information.
   */
  patientInfo?: ViewerInformationPatientInfo;

  /**
   * The information state of the component.
   */
  informationState?: ViewerInformationState;

  /**
   * Callback fired when the patient's select is clicked.
   */
  onSelectClick?: () => void;
}

/**
 * This component is used to display the patient on the viewer's header.
 */
export const ViewerInfoHeaderDesktop = ({
  informationState,
  parentWidth,
  patientInfo,
  className,
  isMobile,
  locale,
}: ViewerInfoHeaderDesktopProps) => {
  const { showPatientInfomation, classes: classesExtension } = useViewerInfoProviderContext();

  if (!showPatientInfomation) return null;

  const informationStateHidden =
    informationState === ViewerInformationState.hiddenL || informationState === ViewerInformationState.hiddenR;

  const classes = {
    header: twMerge(
      cn(
        'e-py-4 e-px-5',
        className,
        {
          'e-opacity-0': informationStateHidden,
          'e-opacity-100': !informationStateHidden,
        },
        classesExtension?.headerDesktop?.header,
      ),
    ),
    text: twMerge(
      cn(
        'e-line-clamp-1',
        {
          'e-text-viewer': !isMobile,
          'e-text-left':
            informationState === ViewerInformationState.leftAligned ||
            informationState === ViewerInformationState.hiddenL ||
            informationState === ViewerInformationState.visible,
          'e-text-right':
            informationState === ViewerInformationState.rightAligned ||
            informationState === ViewerInformationState.hiddenR,
        },
        'e-text-neutral-400',
        classesExtension?.headerDesktop?.text,
      ),
    ),
  };

  const textPatientName = Boolean(patientInfo?.name?.length) ? `${patientInfo?.name}`.toUpperCase() : '';
  const textGender = Boolean(patientInfo?.gender?.length) ? `${patientInfo?.gender}` : '';
  const textAge = Boolean(patientInfo?.age?.length) ? `${patientInfo?.age}` : '';
  const textBirthDate = Boolean(patientInfo?.birthDate?.length) ? `(${patientInfo?.birthDate})` : '';
  const textAgeAndBirthDate = `${textAge}${textBirthDate}`;
  const textStudyName = Boolean(patientInfo?.studyName?.length) ? `${patientInfo?.studyName}` : '';
  const textStudyDate = Boolean(patientInfo?.studyDate?.length) ? `${patientInfo?.studyDate}` : '';
  const textOrganization = Boolean(patientInfo?.organization?.length) ? `${patientInfo?.organization}` : '';
  const textStudyDateFormatted = Boolean(patientInfo?.studyDateFormatted?.length)
    ? `${patientInfo?.studyDateFormatted}`
    : '';
  const textDate = `${textStudyDate}${textStudyDateFormatted}`;
  const textFacility = Boolean(patientInfo?.facility?.length) ? `${patientInfo?.facility}` : '';

  return (
    <div className={classes.header}>
      <ViewerInfoText
        parentWidth={parentWidth}
        className={twMerge(cn(classes.text, 'e-text-base-white'))}
        title={`${t('patient', locale)}: `}
        info={textPatientName}
      />
      <ViewerInfoText
        parentWidth={parentWidth}
        className={cn(classes.text)}
        title={`${t('sex', locale)}: `}
        info={textGender}
      />
      <ViewerInfoText
        parentWidth={parentWidth}
        className={cn(classes.text)}
        title={`${t('age', locale)}: `}
        info={textAgeAndBirthDate}
      />
      <ViewerInfoText
        parentWidth={parentWidth}
        className={cn(classes.text)}
        title={`${t('capture', locale)}: `}
        info={textDate}
      />
      <ViewerInfoText
        parentWidth={parentWidth}
        className={cn(classes.text)}
        title={`${t('study', locale)}: `}
        info={textStudyName}
      />
      <ViewerInfoText
        parentWidth={parentWidth}
        className={cn(classes.text)}
        title={`${t('organization', locale)}: `}
        info={textOrganization}
      />
      <ViewerInfoText
        parentWidth={parentWidth}
        className={cn(classes.text)}
        title={`${t('branch', locale)}: `}
        info={textFacility}
      />
    </div>
  );
};
