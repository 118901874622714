import React from 'react';
import cn from 'classnames';
import { Root, Track, Range, Thumb } from '@radix-ui/react-slider';
import { Icon, IconCatalog } from '../../Icon/Icon';

export enum SliderVariant {
  inline = 'inline',
  stacked = 'stacked',
}

interface SliderProps {
  /**
   * Specify an optional className to be added to the container
   */
  className?: string;

  /**
   * Specify the minimum value of the slider
   */
  min?: number;

  /**
   * Specify the maximum value of the slider
   */
  max: number;

  /**
   * Specify the current value of the slider
   */
  value: number;

  /**
   * Specify the step value of the slider
   */
  step?: number;

  /**
   * Specify the variant of the slider
   */
  variant?: SliderVariant;

  /**
   * Whether to show the labels or not
   */
  showLabels?: boolean;

  /**
   * Specify an optional icon to be added next to the label
   */
  labelIcon?: IconCatalog;

  /**
   * Whether to make the slider full width or not
   */
  isFullWidth?: boolean;

  /**
   * An optional onChange handler that is called whenever the value of the slider changes
   */
  onChange?: (value: number) => void;
}

export const Slider = ({
  className,
  min = 0,
  max,
  value,
  step = 1,
  variant = SliderVariant.inline,
  showLabels = true,
  labelIcon,
  isFullWidth = true,
  onChange,
}: SliderProps) => {
  const classes = {
    container: cn('e-flex e-items-center e-gap-2', className, {
      'e-relative e-pt-4': variant === SliderVariant.stacked,
      'e-w-full': isFullWidth,
    }),
    root: cn('e-flex e-items-center', 'e-relative e-select-none e-touch-none', 'e-w-full', 'e-h-2'),
    track: cn('e-bg-neutral-400 e-relative e-grow e-rounded e-h-1'),
    range: cn('e-absolute e-bg-primary-500 e-rounded e-h-full'),
    thumb: cn(
      'e-block e-w-3 e-h-3 e-cursor-pointer',
      'e-bg-base-white e-shadow-md e-rounded-full',
      'focus:e-outline-primary-200',
    ),
    labelContainer: (placement: string) =>
      cn('e-flex e-items-center e-gap-0.5', 'e-text-neutral-400', {
        'e-absolute e-top-0': variant === SliderVariant.stacked && placement === 'left',
        'e-absolute e-top-0 e-right-0': variant === SliderVariant.stacked && placement === 'right',
      }),
  };

  const onValueChange = (event: Array<number>) => {
    if (!onChange) return;
    onChange(event[0]);
  };

  const renderLabel = (label: number, placement: string) => (
    <div className={classes.labelContainer(placement)}>
      {labelIcon && <Icon icon={labelIcon} width={12} height={12} />}
      <span className="e-text-2xs e-font-medium">{label}</span>
    </div>
  );

  return (
    <div className={classes.container}>
      {showLabels && renderLabel(min, 'left')}
      <Root className={classes.root} min={min} max={max} value={[value]} step={step} onValueChange={onValueChange}>
        <Track className={classes.track}>
          <Range className={classes.range} />
        </Track>
        <Thumb className={classes.thumb} />
      </Root>
      {showLabels && renderLabel(max, 'right')}
    </div>
  );
};
