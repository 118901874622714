import { MultiSelectorOutput, NestedSelectOption, OptionItemType, SelectOption } from '../types';

/**
 * Turns a SelectOption object into a MultiSelectorOutput
 * @param selectedOption
 * @returns MultiSelectorOutput
 */
export const formatSelectOptionToMultiSelectorOutput = (selectedOption: SelectOption): MultiSelectorOutput => ({
  parent: selectedOption,
  options: [],
});

/**
 * Turns a `MultiSelectorOutput` object into a `SelectOption`
 * @param multiSelectorOutput
 * @returns SelectOption
 */
export const formatMultiSelectorOutputToSelectOption = ({ parent }: MultiSelectorOutput): SelectOption => ({
  label: parent.label,
  value: parent.value,
});

/**
 * Turns a `SelectOption` object into a `NestedSelectOption`
 * @param selectedOption
 * @param isChecked
 * @returns object NestedSelectOption
 */
export const formatSelectOptionToNestedSelectOption = (
  selectedOption: SelectOption,
  isChecked = false,
): NestedSelectOption => ({
  isVisible: true,
  type: OptionItemType.parent,
  label: selectedOption.label,
  value: selectedOption.value,
  checked: isChecked,
});
