import React from 'react';
import cn from 'classnames';

import { LineClamp } from '../../../../common';
import { Icon, IconCatalog } from '../../../Icon/Icon';
import { AutoTooltip, TooltipPosition } from '../../../Overlays';

interface QuickEditProps {
  /**
   * The label to display.
   */
  label: string;

  /**
   * The fallback label to display if the label is empty.
   */
  fallbackLabel: string;

  /**
   * The second label to display.
   * @default undefined
   */
  secondLabel?: string;

  /**
   * Whether to show the edit icon or not.
   * @default false
   */
  showEditIcon: boolean;

  /**
   * The callback to execute when the edit icon is clicked.
   * @default undefined
   */
  onEditClick?: () => void;

  /**
   * The number of lines to display.
   */
  numberOfLines?: number;

  /**
   * Whether to disable the tooltip or not.
   * @default false
   */
  disabledTooltip?: boolean;
}

/**
 * `QuickEdit` edit the comment of the order if there's any, otherwise it displays a fallback message.
 * @author Carlos Cuatin<carlos.cuatin@evacenter.com>
 * Created at 2023-09-06
 */
export const QuickEditCell = ({
  label,
  fallbackLabel,
  secondLabel,
  showEditIcon = false,
  onEditClick,
  numberOfLines = 2,
  disabledTooltip = false,
}: QuickEditProps) => {
  const classes = {
    container: cn('e-flex e-flex-col e-cursor-pointer'),
    label: cn('e-flex e-flex-row e-space-x-1 e-justify-start', {
      'e-items-center': !label,
    }),
    labelContent: cn('e-text-neutral-50 e-text-sm e-font-normal', {
      [LineClamp[numberOfLines]]: disabledTooltip,
    }),
    fallbackLabel: cn('e-text-base-white e-text-sm e-font-medium e-truncate'),
    secondLabel: cn('e-text-neutral-300 e-text-sm'),
    icon: cn('e-flex-shrink-0'),
  };

  const renderLabel = () => {
    if (!label) return <span className={classes.fallbackLabel}>{fallbackLabel}</span>;
    if (disabledTooltip) return <span className={classes.labelContent}>{label}</span>;

    return (
      <AutoTooltip description={label} numberOfLines={numberOfLines} position={TooltipPosition.RIGHT}>
        <span className={classes.labelContent}>{label}</span>
      </AutoTooltip>
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.label} onClick={onEditClick}>
        {renderLabel()}
        {showEditIcon && <Icon className={classes.icon} icon={IconCatalog.edit} width={24} height={24} />}
      </div>
      {secondLabel && <div className={classes.secondLabel}>{secondLabel}</div>}
    </div>
  );
};
