import { CalendarLocale } from './types';
import { Translations } from '../../common';

// TODO: Fix typo error
export const traslations: Translations = {
  en: {
    today: 'Today',
    month: 'Month View',
    week: 'Weekly View',
    day: 'Day View',
    showMore: '{total} events more',
    sexAge: 'Sex and age',
    study: 'Study',
    capture: 'Capture',
    branch: 'Facility',
    sex: 'Sex',
    age: 'Age',
    organization: 'Organization',
    patient: 'Patient',
  },
  es: {
    today: 'Hoy',
    month: 'Vista por mes',
    week: 'Vista por semana',
    day: 'Vista por día',
    showMore: '{total} eventos más',
    sexAge: 'Sexo y edad',
    study: 'Estudio',
    capture: 'Captura',
    branch: 'Sucursal',
    sex: 'Sexo',
    age: 'Edad',
    organization: 'Organización',
    patient: 'Paciente',
  },
  pt: {
    today: 'Hoje',
    month: 'Visualização por mês',
    week: 'Visualização por semana',
    day: 'visualização por dia',
    showMore: '{total} mais eventos',
    sexAge: 'Sexo e idade',
    study: 'Exame',
    capture: 'Data',
    branch: 'Filial',
    sex: 'Sexo',
    age: 'Idade',
    organization: 'Organização',
    patient: 'Paciente',
  },
};

/**
 * Helper for getting the translation for a given key and the given locale
 * @param key A key to get the translation
 * @param locale A locale to get the translation
 * @returns A translation for the given key
 */
export const t = (key: string, locale: CalendarLocale | string = CalendarLocale.ES): string => {
  return getTranslation(locale, key) ?? key;
};

/**
 * Helper function to get a key from the translations with a dot notation
 * @param locale A locale to get the translation messages
 * @param key A key to find in the translations
 * @returns A translation message for the given key
 */
const getTranslation = (locale: CalendarLocale | string, key: string): string | undefined => {
  const dictionary = traslations[locale as CalendarLocale];
  return dictionary ? dictionary[key] : undefined;
};
