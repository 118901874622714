import React, { forwardRef } from 'react';
import cn from 'classnames';
import { BaseThumbnail, BaseThumbnailProps } from '../BaseThumbnail/BaseThumbnail';
import { BaseThumbnailSkeleton } from '../BaseThumbnail/BaseThumbnailSkeleton';

interface SingleImageThumbnailProps extends BaseThumbnailProps {
  /**
   * The source URL of the image.
   */
  imgSrcUrl: string;

  /**
   * Whether the thumbnail is loading, show a skeleton.
   */
  isLoading?: boolean;
}

/**
 * Represent a single image thumbnail in the viewer.
 */
export const SingleImageThumbnail = forwardRef<HTMLButtonElement, SingleImageThumbnailProps>(
  ({ className, imgSrcUrl, title, isLoading = false, ...restProps }, ref) => {
    const classes = {
      container: cn(className),
      image: cn('e-object-cover e-h-full e-rounded-lg'),
    };

    if (isLoading) return <BaseThumbnailSkeleton hasAnimation />;

    return (
      <BaseThumbnail {...restProps} ref={ref} title={title} className={classes.container}>
        <img src={imgSrcUrl} alt={title} className={classes.image} />
      </BaseThumbnail>
    );
  },
);
