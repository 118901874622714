import React, { useState } from 'react';
import { NestedSelectOption, MultiSelectorOutput } from '../../../common';
import { InnerMultiSelect, InnerMultiSelectProps } from './InnerMultiSelect/InnerMultiSelect';
import { TreeOptionsProvider } from '../../TreeOptions/hooks/useTreeOptionsContext';
import { Options, OptionsProps } from '../../TreeOptions/components/Options';
import { TreeOptionsProps } from '../../TreeOptions/TreeOptions';
import { Popover, PopoverPlacement } from '../../Overlays';

export enum NestedMultiSelectVariant {
  MultiSelect,
  NestedMultiSelect,
}

export interface NestedMultiSelectProps
  extends Omit<InnerMultiSelectProps, 'onChange' | 'options' | 'onInputChange' | 'value'>,
    Pick<OptionsProps, 'noResultsText'>,
    Pick<TreeOptionsProps, 'borderless'> {
  /**
   * Set the options list tree.
   */
  options?: Array<NestedSelectOption>;

  /**
   * Default value
   */
  value?: Array<MultiSelectorOutput>;

  /**
   * Variant to identify the component desired behavior
   */
  variant?: NestedMultiSelectVariant;

  /**
   * Specify an optional classname for the option items
   */
  optionItemClassName?: string;

  /**
   * Specify an optional className to be added to the popover menu.
   */
  menuClassName?: string;

  /**
   * Provide a handler that is called when an option was selected.
   */
  onChange?: (selectedOptions: Array<MultiSelectorOutput>) => void;
}

/**
 * This component could be thought of as a styled native select tag, but adding to it more functionalities such as filtering, multi selecting, among others. But it includes a nested menu options
 * @author Sergio Ruiz Davila<sergio.ruiz@evacenter.com>
 * Created at 2022-09-30
 */
export const NestedMultiSelect = ({
  options = [],
  value = [],
  menuClassName,
  onChange,
  noResultsText,
  borderless,
  optionItemClassName,
  variant = NestedMultiSelectVariant.NestedMultiSelect,
  ...restProps
}: NestedMultiSelectProps) => {
  const [inputValue, setInputValue] = useState('');
  const isMultiSelectVariant = variant === NestedMultiSelectVariant.MultiSelect;

  const handleOnChange = (output: Array<MultiSelectorOutput>) => {
    setInputValue('');
    if (onChange) onChange(output);
  };

  const handleBlur = () => setInputValue('');

  const handleOnSelectOption = () => {
    if (!inputValue) return;
    setInputValue('');
  };

  return (
    <TreeOptionsProvider
      value={value}
      defaultOptions={options}
      borderless={borderless}
      onChange={handleOnChange}
      onSelectOption={handleOnSelectOption}
      parentOptionConfig={{
        parentItemClassName: optionItemClassName,
        selectOnParentClick: isMultiSelectVariant,
      }}>
      <Popover
        content={<Options noResultsText={noResultsText} />}
        placement={PopoverPlacement.bottomStart}
        allowTriggerClickBinding={false}
        disableOnClickOutside
        menuFullWidth>
        <InnerMultiSelect {...restProps} value={inputValue} onInputValueChange={setInputValue} onBlur={handleBlur} />
      </Popover>
    </TreeOptionsProvider>
  );
};
