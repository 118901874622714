import React, { ChangeEvent, useState, forwardRef, InputHTMLAttributes } from 'react';
import cn from 'classnames';
import { FormFieldState, Input } from '../../../../common';
import { Icon, IconCatalog } from '../../../Icon/Icon';

export interface OTPInputProps extends Input, Omit<InputHTMLAttributes<HTMLInputElement>, 'required' | 'onChange'> {
  /**
   * The handler to get notified when the value changes.
   */
  onChange: (value: string) => void;
}

/**
 * An OTP Input allows the user to interact with and input one time password.
 * @author Sergio Ruiz<sergio.ruiz@evacenter.com>
 * Created at 2024-06-30
 */
export const OTPInput = forwardRef<HTMLInputElement, OTPInputProps>(
  (
    {
      id,
      isReadOnly = false,
      isLoading = false,
      isRequired = false,
      isFullWidth = false,
      fieldState = FormFieldState.default,
      className,
      dataTestId,
      value = '',
      onChange,
      ...restOfProps
    },
    ref,
  ) => {
    const classes = {
      container: cn('e-relative e-flex e-items-center e-overflow-hidden e-space-x-2', className),
      input: cn(
        'e-border e-bg-neutral-700 e-text-base-white e-text-2xl',
        'e-transition e-duration-100 e-ease-out e-outline-none',
        'placeholder:e-text-neutral-500 e-font-light focus:e-border-neutral-400 e-caret-base-white',
        'e-w-full e-rounded-2xl e-px-5 e-pl-4 e-pr-14 e-h-16',
        {
          'e-border-neutral-500': fieldState === FormFieldState.default,
          'e-border-error-500': fieldState === FormFieldState.error,
          'e-border-neutral-400': fieldState === FormFieldState.active,
          'e-animate-pulse': isLoading,
        },
      ),
      button: cn('e-absolute e-right-4 e-text-base-white e-bg-neutral-700 focus:e-outline-none'),
      icon: cn('e-w-8 e-h-8 e-text-base-white'),
    };

    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => setShowPassword((previous) => !previous);

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
      const newValue = event.target.value;
      if (onChange) onChange(newValue);
    };

    /* Render JSX */
    return (
      <div className={classes.container}>
        <input
          id={id}
          data-testid={dataTestId}
          ref={ref}
          type={showPassword ? 'text' : 'password'}
          className={classes.input}
          value={value}
          readOnly={isReadOnly}
          required={isRequired}
          autoComplete="off"
          onChange={handleInputChange}
          {...restOfProps}
        />
        <button
          type="button"
          data-testid="toggle-password-button"
          onClick={toggleShowPassword}
          className={classes.button}>
          <Icon icon={showPassword ? IconCatalog.hide : IconCatalog.view} className={classes.icon} />
        </button>
      </div>
    );
  },
);
