import React, { useEffect, useMemo, useState } from 'react';
import cn from 'classnames';

import { PATIENT_INFO_CONTAINER_INFO_TEST_ID, PATIENT_INFO_CONTAINER_SCROLL_TEST_ID } from '../../../common';
import { StudyInformationPatientHeaderSkeleton } from '../..';

export type PatientInfoHeader = {
  caption: string;
  title: string;
  extraInfo: string;
};

export interface PatientInfoContainerProps {
  /**
   * The class name to apply to the container.
   */
  className?: string;

  /**
   * The class name to apply to the scrollable area.
   */
  scrollableClassName?: string;

  /**
   * The class name to apply to the content content.
   */
  contentClassName?: string;

  /**
   * The patient's information.
   */
  header: PatientInfoHeader;

  /**
   * The content to display.
   */
  content: React.ReactNode;
  /**
   * If true, the header will animate when scrolling.
   */
  animate?: boolean;

  /**
   * If true, the loading state will be displayed.
   */
  isLoading?: boolean;
}

/**
 * This component is a container which includes the patient's information and the content.
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 */
export const PatientInfoContainer = ({
  className,
  scrollableClassName,
  header,
  content,
  animate = true,
  isLoading,
}: PatientInfoContainerProps) => {
  const classes = {
    container: cn('e-text-base-white', className),
    headerContainer: cn('e-flex e-sticky e-top-0 e-flex-col e-w-full e-bg-neutral-900 e-z-1'),
    headerCaption: cn('e-text-neutral-300'),
    headerTitle: cn('e-text-base-white e-font-semi-bold'),
    headerExtra: cn('e-text-neutral-300 e-text-xs'),
    scrollableArea: cn('e-relative e-flex e-overflow-y-scroll', scrollableClassName),
  };

  const [scrollY, setScrollY] = useState(0);

  const extra = useMemo(() => {
    let opacity = 1;
    let height = '16px';

    if (!animate) return { opacity, height };

    opacity = getPercentualLimit(0, 1, scrollY);
    height = `${getPercentualLimit(0, 16, scrollY)}px`;

    return { opacity, height };
  }, [scrollY]);

  const title = useMemo(() => {
    let fontSize = '20px';
    let marginBottom = '4px';

    if (!animate) return { fontSize, marginBottom };

    fontSize = `${getPercentualLimit(16, 20, scrollY)}px`;
    marginBottom = `${getPercentualLimit(2, 4, scrollY)}px`;

    return { fontSize, marginBottom };
  }, [scrollY]);

  const caption = useMemo(() => {
    let fontSize = '12px';
    let marginBottom = '4px';

    if (!animate) return { fontSize, marginBottom };

    fontSize = `${getPercentualLimit(10, 12, scrollY)}px`;
    marginBottom = `${getPercentualLimit(2, 4, scrollY)}px`;

    return { fontSize, marginBottom };
  }, [scrollY]);

  const container = useMemo(() => {
    let padding = '12px 20px 12px 20px';

    if (!animate) return { padding };

    const paddingY = `${getPercentualLimit(8, 12, scrollY)}px`;
    padding = `${paddingY} 20px ${paddingY} 20px`;

    return { padding };
  }, [scrollY]);

  const updateScrollY = (element: HTMLDivElement) => {
    const scrollPercentage = (element.scrollTop / (element.scrollHeight - element.clientHeight)) * 100;
    const roundValue = Math.round(scrollPercentage * 10);

    if (roundValue < 0 && scrollY === 0) return;
    if (roundValue < 0) return setScrollY(0);

    if (roundValue > 100 && scrollY === 100) return;
    if (roundValue > 100) return setScrollY(100);

    setScrollY(roundValue);
  };

  useEffect(() => {
    const currentElement = document.querySelector(`[data-testid="${PATIENT_INFO_CONTAINER_SCROLL_TEST_ID}"]`);
    if (!currentElement) return;

    const onScroll = (data: Event) => updateScrollY(data?.target as HTMLDivElement);

    currentElement.addEventListener('scroll', onScroll);
    return () => {
      currentElement.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <div className={classes.container}>
      {/* PATIENT INFO HEADER */}
      {!isLoading ? (
        <div
          data-testid={PATIENT_INFO_CONTAINER_INFO_TEST_ID}
          className={classes.headerContainer}
          style={{ padding: container.padding }}>
          <h3
            className={classes.headerCaption}
            style={{ fontSize: caption.fontSize, marginBottom: caption.marginBottom }}>
            {header.caption}
          </h3>
          <h1 className={classes.headerTitle} style={{ fontSize: title.fontSize, marginBottom: title.marginBottom }}>
            {header.title}
          </h1>
          <h2 className={classes.headerExtra} style={{ opacity: extra.opacity, height: extra.height }}>
            {header.extraInfo}
          </h2>
        </div>
      ) : (
        <StudyInformationPatientHeaderSkeleton />
      )}
      <section data-testid={PATIENT_INFO_CONTAINER_SCROLL_TEST_ID} className={classes.scrollableArea}>
        {/* SCROLLABLE CONTENT */}
        {content}
      </section>
    </div>
  );
};

const getPercentualLimit = (start: number, end: number, value: number, invert = true) => {
  let result = (end - start) * (value / 100) + start;
  if (invert) result = end - result + start;

  return result;
};
