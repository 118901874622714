import React, { ReactNode, useState } from 'react';
import cn from 'classnames';
import { SidebarContext } from '../../../common';

export interface SidebarProviderProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Descriptive text to be read to screenreaders.
   */
  ariaLabel?: string;

  /**
   * Set the width when the Sidebar is collapsed
   */
  collapsedWidth?: string;

  /**
   * Set the width when the Sidebar is expanded
   */
  expandedWidth?: string;

  /**
   * Specify an optional className to be added to the component.
   */
  className?: string;

  /**
   * Elements to display inside the Navbar.
   */
  children?: ReactNode;
}

export const Sidebar = ({
  children,
  ariaLabel,
  collapsedWidth = '3.75rem',
  expandedWidth = '16rem',
  className,
  ...restProps
}: SidebarProviderProps): JSX.Element => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const classes = cn(
    className,
    'e-flex e-flex-shrink-0',
    'e-bg-neutral-900 e-border-r e-border-r-neutral-600',
    'e-transition-[width] e-duration-300 e-ease-in-out',
  );

  const handleMouseEnter = () => setIsCollapsed(false);
  const handleMouseLeave = () => setIsCollapsed(true);

  /* GROUP VALUES */
  const sidebarProviderValue = { isCollapsed, setIsCollapsed };

  /* RENDER PROVIDER */
  return (
    <SidebarContext.Provider value={sidebarProviderValue}>
      <div
        aria-label={ariaLabel}
        className={classes}
        style={{ width: isCollapsed ? collapsedWidth : expandedWidth }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        {...restProps}>
        {children}
      </div>
    </SidebarContext.Provider>
  );
};
