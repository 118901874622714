import React from 'react';
import cn from 'classnames';
import { CardFooter } from '../CardContent/CardFooter';

export interface BaseCardProps extends React.HTMLAttributes<HTMLElement> {
  /**
   * Descriptive text to be read to screenreaders
   */
  ariaLabel?: string;

  /**
   * Specify an optional className to be added to the component.
   */
  className?: string;

  /**
   * Whether the Card is clickable, adding some extra styles.
   */
  isClickable?: boolean;

  /**
   * Elements to display inside the Card.
   */
  body: React.ReactNode;

  /**
   * Elements to display inside the Footer.
   */
  footer?: React.ReactNode;
}

/**
 * A BaseCard is a base element from where other components can extend to style
 * their content as cards
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 */
export const BaseCard = ({ ariaLabel, className, isClickable, body, footer, ...rest }: BaseCardProps) => {
  const classes = {
    card: cn(
      className,
      'e-relative e-overflow-hidden',
      'e-rounded-lg',
      'e-bg-neutral-900',
      'e-border e-border-creator-primary-50',
      {
        'hover:e-glow-100': isClickable,
      },
    ),
    footer: cn('e-bg-neutral-900'),
  };

  return (
    <article aria-label={ariaLabel} className={classes.card} {...rest}>
      {body}
      {footer && <CardFooter className={classes.footer}>{footer}</CardFooter>}
    </article>
  );
};
