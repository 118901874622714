import React from 'react';
import { Button, ButtonVariant, ButtonSize } from '../../Buttons';
import { DropdownMenu } from '../../DropdownMenu/DropdownMenu';
import { Icon, IconCatalog } from '../../Icon/Icon';
import { PopoverPlacement } from '../../Overlays';
import { useCalendarContext } from '../hooks/useCalendarContext';
import { t } from '../i18n';

export const ViewSwitcher = () => {
  const { handleChangeView, view, views, locale } = useCalendarContext();

  const trigger = (
    <div className="e-inline-flex">
      <Button
        className="e-border-neutral-400 e-border-r-neutral-400"
        size={ButtonSize.sm}
        variant={ButtonVariant.secondary}>
        <div className="e-flex e-items-center e-space-x-1">
          <span className="e-inline-block">{t(view, locale)}</span>
          <Icon className="e-relative -e-right-2" icon={IconCatalog.chevronDown} />
        </div>
      </Button>
    </div>
  );

  /**
   * For better legibility we are using a function component to render the options
   * because the `DropdownMenu` component is expecting a `ReactNode` as a child.
   */
  const Options = () => (
    <DropdownMenu.Menu>
      {views.map((view) => (
        <DropdownMenu.Option key={view} onClick={() => handleChangeView(view)}>
          {t(view, locale)}
        </DropdownMenu.Option>
      ))}
    </DropdownMenu.Menu>
  );

  return <DropdownMenu menuFullWidth menu={<Options />} menuPlacement={PopoverPlacement.bottomEnd} trigger={trigger} />;
};
