import React, { useEffect, useRef } from 'react';
import cn from 'classnames';
import { Root, Track, Range, Thumb } from '@radix-ui/react-slider';
import { Icon, IconCatalog } from '../../Icon/Icon';

export enum ViewerScrollbarOrientation {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

interface ViewerScrollbarProps {
  /*
   * Specify an optional className to be added to the component
   */
  className?: string;

  /*
   * The maximum value of the scrollbar
   */
  max: number;

  /*
   * The minimum value of the scrollbar
   */
  min?: number;

  /*
   * The current value of the scrollbar
   */
  value: number;

  /*
   * Whether the scrollbar is inverted or not
   */
  inverted?: boolean;

  /*
   * The height of the scrollbar
   */
  height?: string;

  /*
   * The orientation of the scrollbar
   */
  orientation?: ViewerScrollbarOrientation;

  /*
   * Whether the scrollbar is mobile or not
   */
  isMobile?: boolean;

  /*
   * The callback to get notified when the value changes
   */
  onChange?: (value: number) => void;
}

export const ViewerScrollbar = ({
  max,
  value,
  height,
  inverted = true,
  min = 0,
  className,
  orientation = ViewerScrollbarOrientation.Vertical,
  isMobile = false,
  onChange,
}: ViewerScrollbarProps) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const isVertical = orientation === ViewerScrollbarOrientation.Vertical;

  const classes = {
    container: cn('e-relative e-flex e-items-center e-flex-col', 'e-select-none e-touch-none e-h-full', className, {
      'e-bg-neutral-700': !isMobile,
      'e-bg-transparent': isMobile,
      'e-w-0 e-py-4': isVertical && isMobile,
      'e-w-3.5 e-py-4': isVertical && !isMobile,
      'e-h-3.5 e-px-4': !isVertical,
    }),
    track: cn('e-relative e-rounded', {
      'e-bg-neutral-400': !isMobile,
      'e-bg-transparent': isMobile,
      'e-h-full e-w-0.5 e-grow': isVertical,
      'e-w-full e-h-0.5 e-my-auto': !isVertical,
    }),
    thumb: cn('e-shadow-md e-rounded-2xl', {
      'e-bg-neutral-500 active:e-bg-neutral-400': isMobile,
      'e-bg-base-white e-block': !isMobile,
      'e-w-2 e-h-7': isVertical && !isMobile,
      'e-w-7 e-h-2 e-mt-0.5': !isVertical && !isMobile,
      'e-flex e-items-center e-justify-center e-w-8 e-h-10 e-rounded-tl-full e-rounded-bl-full e-overflow-hidden e-outline-none e-border-none':
        isVertical && isMobile,
      'e-flex e-items-center e-justify-center e-w-10 e-h-8 e-rounded-tl-full e-rounded-tr-full e-overflow-hidden e-outline-none e-border-none':
        !isVertical && isMobile,
    }),
    icon: cn('e-text-base-white'),
  };

  useEffect(() => {
    if (!isMobile) return;

    const uncontrlledThumbElement = scrollRef.current?.children[1];
    uncontrlledThumbElement?.classList.add('e-right-0');
  }, [scrollRef]);

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // eslint-disable-next-line i18next/no-literal-string
    const keys = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'];
    if (keys.includes(event.key)) event.preventDefault();
  };

  const onValueChange = (event: Array<number>) => {
    if (!onChange) return;
    onChange(event[0]);
  };

  return (
    <Root
      ref={scrollRef}
      min={min}
      max={max}
      inverted={inverted}
      value={[value]}
      onKeyDown={onKeyDown}
      orientation={orientation}
      className={classes.container}
      onValueChange={onValueChange}
      style={{ height }}>
      <Track className={classes.track}>
        <Range className="e-absolute e-bg-neutral-50 e-rounded" />
      </Track>
      <Thumb className={classes.thumb}>
        {isMobile && <Icon className={classes.icon} icon={IconCatalog.scrollMobile} />}
      </Thumb>
    </Root>
  );
};
