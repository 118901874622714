import React, { ReactNode } from 'react';
import cn from 'classnames';

export interface SideNavProps {
  /**
   * Descriptive text to be read to screenreaders.
   */
  ariaLabel?: string;

  /**
   * Specify an optional className to be added to the component.
   */
  className?: string;

  /**
   * Elements to display inside the Navbar.
   */
  children?: ReactNode;
}

/**
 * Side navigation allows users to locate information and features within the UI. It can be used for either hierarchical or flat navigation, and gives the ability to group navigable items categorically.
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2022-07-16
 */
export const SideNav = ({ ariaLabel, className, children }: SideNavProps) => {
  const classes = cn(
    className,
    'e-flex e-flex-col e-flex-none e-flex-nowrap e-overflow-x-hidden e-overflow-y-auto e-p-2 e-w-full',
  );

  return (
    <div aria-label={ariaLabel} className={classes}>
      {children}
    </div>
  );
};
