import React from 'react';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import cn from 'classnames';
import { localizer } from '../../utils/custom-localizer';
import type { Event } from '../../types';
import { DEFAULT_TIME_FORMAT } from '../../constants';

const EVENT_FIFTEEN_MINUTES = 15;
const EVENT_THIRTY_MINUTES = 30;

interface EventContentProps {
  /**
   * The title of the event.
   */
  title?: string;
  /**
   * The event to display.
   */
  event: Event;
}

export const EventContent = ({ title, event }: EventContentProps) => {
  if (!event.end || !event.start) return null;
  const time = differenceInMinutes(event.end, event.start);

  const classes = {
    container:
      'e-border-neutral-400 e-border-y-2 e-border-x-2 e-border-l-8 e-rounded-md e-w-full e-h-full e-flex e-bg-neutral-900 e-justify-between e-overflow-hidden',
    wrapper: cn('e-flex e-min-w-0 e-pl-2 e-truncate', {
      'e-flex-col e-pt-0.5': time > EVENT_FIFTEEN_MINUTES && time <= EVENT_THIRTY_MINUTES,
      'e-flex-col e-py-2': time > EVENT_THIRTY_MINUTES,
      'e-flex-row e-items-center e-py-1 e-space-x-1': time <= EVENT_FIFTEEN_MINUTES,
    }),
    title: cn('e-text-sm e-text-white e-font-semibold w-full', {
      'e-flex-shrink-0': time > EVENT_FIFTEEN_MINUTES,
      'e-truncate': time <= EVENT_FIFTEEN_MINUTES,
    }),
  };

  return (
    <div className={classes.container} style={{ borderColor: event.borderColor }}>
      <div className={classes.wrapper}>
        <span className={classes.title}>{title}</span>
        <span className="e-text-sm e-text-neutral-300 e-truncate">
          {localizer.format(event.start, DEFAULT_TIME_FORMAT)} — {localizer.format(event.end, DEFAULT_TIME_FORMAT)}
        </span>
      </div>
    </div>
  );
};
