import React from 'react';
import type { VirtualElement } from '@popperjs/core';
import { usePopper } from 'react-popper';
import cn from 'classnames';
import { useVirtualElement } from '../../hooks/useVirtualElement';
import { Event } from '../../types';
import { PopupContent } from './Content';
import { DEFAULT_POPUP_DISTANCE, DEFAULT_POPUP_SKIDDING } from '../../constants';

export interface PopupProps {
  /**
   * The children of the popup.
   */
  children?: React.ReactNode;
  /**
   * A render function to customize the popup content.
   * @param event The event object to render.
   */
  render?: (event: Event) => JSX.Element;
  /**
   * The event to display.
   */
  event: Event;
}

export const Popup = ({ children, event, render }: PopupProps) => {
  const popperElement = React.useRef<HTMLDivElement>(null);
  const [show, setShow] = React.useState(false);
  const { virtualElement, handleMouseMove } = useVirtualElement();

  const { styles, attributes } = usePopper(virtualElement as VirtualElement, popperElement.current, {
    placement: 'right',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [DEFAULT_POPUP_SKIDDING, DEFAULT_POPUP_DISTANCE], // [skidding, distance]
        },
      },
    ],
  });

  return (
    <div
      className="e-relative e-w-full e-h-full"
      onMouseMove={handleMouseMove}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}>
      {children}
      <div
        className={cn('e-absolute e-z-50 e-w-max', {
          'e-hidden': !show,
        })}
        ref={popperElement}
        style={styles.popper}
        {...attributes.popper}>
        {render ? render(event) : <PopupContent event={event} />}
      </div>
    </div>
  );
};
