import React from 'react';
import { NestedSelectOption, MultiSelectorOutput, SelectedOption } from '../../common';
import { Options } from './components/Options';
import { TreeOptionsProvider } from './hooks/useTreeOptionsContext';

export type ColorsType = Record<string, { value: string }>;

export interface TreeOptionsProps {
  /**
   * Set the options list tree.
   */
  options?: Array<NestedSelectOption>;
  /**
   * Default value
   */
  value?: Array<MultiSelectorOutput>;
  /**
   * Color list with the default color values to apply to a specific child checkbox.
   */
  defaultColors?: ColorsType | undefined;
  /**
   * Specify whether the component should be borderless
   */
  borderless?: boolean;
  /**
   * Provide a handler that is called when an option was selected.
   */
  onChange?: (output: Array<MultiSelectorOutput>) => void;
  /**
   * Provide a handler that is called when an option was selected (Can be parent or child).
   */
  onSelectOption?: (option: SelectedOption, output: Array<MultiSelectorOutput>, colors: ColorsType | undefined) => void;
  /**
   * Provide a handler that is called when an option was unselected (Can be parent or child).
   */
  onUnselectOption?: (
    option: SelectedOption,
    output: Array<MultiSelectorOutput>,
    colors: ColorsType | undefined,
  ) => void;
  /**
   * Flag to enable colored checkboxes for children options.
   */
  colored?: boolean;
}

/**
 * `TreeOptions` component to render a tree of options
 * @author Javier Diaz<javier.diaz@evacenter.com>
 * Created on 2023-02-01
 */
export const TreeOptions = ({
  options = [],
  defaultColors,
  value = [],
  onChange,
  borderless = false,
  onSelectOption,
  onUnselectOption,
  colored,
}: TreeOptionsProps) => {
  const handleOnChange = (output: Array<MultiSelectorOutput>) => {
    if (onChange) onChange(output);
  };

  const handleOnSelectOption = (
    option: SelectedOption,
    output: Array<MultiSelectorOutput>,
    colors: ColorsType | undefined,
  ) => {
    if (onSelectOption) onSelectOption(option, output, colors);
  };

  const handleOnUnselectOption = (
    option: SelectedOption,
    output: Array<MultiSelectorOutput>,
    colors: ColorsType | undefined,
  ) => {
    if (onUnselectOption) onUnselectOption(option, output, colors);
  };

  return (
    <TreeOptionsProvider
      value={value}
      defaultColors={defaultColors}
      colored={colored}
      borderless={borderless}
      defaultOptions={options}
      onChange={handleOnChange}
      onSelectOption={handleOnSelectOption}
      onUnselectOption={handleOnUnselectOption}>
      <Options />
    </TreeOptionsProvider>
  );
};
