import { useMemo } from 'react';

const DEFAULT_MIDDLE_PAGES = 5;
const DEFAULT_PAGE_COUNT = -1;
const PAGE_INDEX_OFFSET = 1;
const PAGE_ITEMS_VISIBLE_BEFORE_TRUNCATE = 3;

export const PAGINATION_DOTS = '...';

export const getTotalPageCount = (totalCount: number, pageSize: number) => Math.ceil(totalCount / pageSize);

export interface PaginationOptionsProps {
  /**
   * Represents the current active page.
   */
  currentPage: number;
  /**
   * Represents the total count of data available from the source.
   */
  totalCount?: number;
  /**
   * Represents the maximum rows that are visible in a single page.
   */
  pageSize: number;
  /**
   * Represents the min number of page buttons to be shown on each side of the current page button.
   */
  siblingCount?: number;
  /**
   * Represents the number of pages visible before slicing the items and adding `...` at the start/end of the range.
   */
  middlePages?: number;
}

/**
 * Use `usePagination` to build a set of page items based on the total count of results and the page size.
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2021-05-27
 */
export const usePagination = ({
  totalCount,
  pageSize,
  siblingCount = 1,
  currentPage,
  middlePages = DEFAULT_MIDDLE_PAGES,
}: PaginationOptionsProps) => {
  const range = (start: number, end: number) => {
    const length = end - start + PAGE_INDEX_OFFSET;
    return Array.from({ length }, (_, idx) => idx + start);
  };

  const paginationRange = useMemo(() => {
    const totalPageCount = getTotalPageCount(totalCount ?? DEFAULT_PAGE_COUNT, pageSize);

    // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
    const totalPageNumbers = siblingCount + middlePages;

    /**
     * Case 1:
     * If the number of pages is less than the page numbers we want to show in our
     * paginationComponent, we return the range [1..totalPageCount]
     */
    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    // Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount - PAGE_INDEX_OFFSET;

    /**
     * We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalPageCount.
     * Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
     */
    const shouldShowLeftDots = leftSiblingIndex > firstPageIndex;
    const shouldShowRightDots = rightSiblingIndex < lastPageIndex;

    /**
     * Case 2: No left dots to show, but rights dots to be shown
     */
    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = PAGE_ITEMS_VISIBLE_BEFORE_TRUNCATE * siblingCount;
      const leftRange = range(firstPageIndex, leftItemCount);

      return [...leftRange, PAGINATION_DOTS, totalPageCount];
    }

    /**
     * Case 3: No right dots to show, but left dots to be shown
     */
    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = PAGE_ITEMS_VISIBLE_BEFORE_TRUNCATE * siblingCount;
      const rightRange = range(totalPageCount - rightItemCount, totalPageCount);
      return [firstPageIndex, PAGINATION_DOTS, ...rightRange];
    }

    /**
     * Case 4: Both left and right dots to be shown
     */
    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, PAGINATION_DOTS, ...middleRange, PAGINATION_DOTS, totalPageCount];
    }
  }, [totalCount, pageSize, siblingCount, currentPage]);

  return paginationRange;
};
