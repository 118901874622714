import React, { createContext, useContext, useMemo } from 'react';
import { RowData, Table } from '@tanstack/react-table';

interface TableProviderProps<T = RowData> {
  children: React.ReactNode;
  tableInstance: Table<T>;
}

interface TableContextProps extends Partial<Omit<TableProviderProps, 'children'>> {}

const TableContext = createContext({} as TableContextProps);

export const TableProvider = ({ tableInstance, children }: TableProviderProps) => {
  const value = useMemo(
    () => ({
      tableInstance,
    }),
    [tableInstance],
  );
  return <TableContext.Provider value={value}>{children}</TableContext.Provider>;
};

export const useTableContext = () => {
  const context = useContext(TableContext);
  if (!context) throw new Error('useTableContext must be wrapped within TableProvider');
  return context;
};
