import React, { ChangeEvent, FocusEvent, SelectHTMLAttributes, useState } from 'react';
import cn from 'classnames';
import { FormFieldState } from '../../../common/constants';
import { Input, SelectOption } from '../../../common/types/input';
import { Icon, IconCatalog } from '../../Icon/Icon';
import { Spinner, SpinnerSize, SpinnerVariant } from '../../Loaders';

export interface NativeSelectProps extends Input, Omit<SelectHTMLAttributes<HTMLSelectElement>, 'required'> {
  /**
   * Set the select options list.
   */
  options: Array<SelectOption>;
}

/**
 * A NativeSelect is an unstyled dropdown form element that allows users to select a value from a list.
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2022-05-26
 */
export const NativeSelect = React.forwardRef<HTMLSelectElement, NativeSelectProps>(
  (
    {
      id,
      name,
      options = [],
      placeholder,
      value,
      fieldState = FormFieldState.default,
      isDisabled = false,
      isRequired = false,
      isFullWidth = false,
      isLoading = false,
      className,
      label,
      assistiveText,
      onChange,
      onBlur,
      onFocus,
      dataTestId,
      ...restOfProps
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);
    const isSelectActive = fieldState === FormFieldState.active && !isDisabled;

    const classes = {
      container: cn(className),
      selectContainer: cn(
        'e-relative e-flex e-items-center e-overflow-hidden e-p-2',
        'e-bg-neutral-700 e-text-neutral-50 e-rounded-lg e-border',
        'e-h-10',
        {
          'e-w-full': isFullWidth,
          'e-border-neutral-400': fieldState === FormFieldState.default && !isDisabled,
          'e-border-error-500': fieldState === FormFieldState.error && !isDisabled,
          'e-border-primary-500': (fieldState === FormFieldState.default && isFocused) || isSelectActive,
          'e-border-transparent': isDisabled,
        },
      ),
      select: cn(
        'e-w-full e-pr-5',
        'e-transition e-duration-100 e-ease-out',
        'e-appearance-none e-outline-none e-truncate',
        'placeholder:e-text-neutral-300 e-text-base e-font-regular',
        {
          'e-bg-neutral-700': !isDisabled,
          'e-bg-neutral-800': isDisabled,
        },
      ),
      endContainer: cn(
        'e-flex e-items-center e-space-x-2 e-self-stretch e-flex-shrink-0 e-pl-1 e-ml-auto e-pointer-events-none e-absolute e-right-2',
      ),
      assistiveText: cn('e-mt-2 e-text-xs e-font-extra-light', {
        'e-text-neutral-200': fieldState === FormFieldState.default,
        'e-text-error-500': fieldState === FormFieldState.error,
      }),
    };

    const renderOptions = options.map((item, index) => (
      <option key={index} value={item.value}>
        {item.label}
      </option>
    ));

    const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
      if (onChange) onChange(event);
    };

    const handleSelectBlur = (event: FocusEvent<HTMLSelectElement>): void => {
      setIsFocused(false);
      if (onBlur) onBlur(event);
    };

    const handleSelectFocus = (event: FocusEvent<HTMLSelectElement>): void => {
      setIsFocused(true);
      if (onFocus) onFocus(event);
    };

    const defaultValue = options.find((option) => option.value === value);

    return (
      <div className={classes.container}>
        {label && (
          <label className="e-mb-2 e-text-neutral-50 e-text-sm e-font-regular" htmlFor={id}>
            {label}
            {isRequired && '*'}
          </label>
        )}
        <div className={classes.selectContainer}>
          <select
            id={id}
            ref={ref}
            className={classes.select}
            name={name}
            value={defaultValue?.value || ''}
            disabled={isDisabled}
            required={isRequired}
            data-testid={dataTestId}
            onChange={handleChange}
            onFocus={handleSelectFocus}
            onBlur={handleSelectBlur}
            {...restOfProps}>
            {placeholder && (
              <option disabled value="">
                {placeholder}
              </option>
            )}
            {renderOptions}
          </select>
          <div className={classes.endContainer}>
            {/* LOADING SPINNER */}
            {isLoading && <Spinner size={SpinnerSize.xs} variant={SpinnerVariant.neutral} />}

            {/* CHEVRON ICON */}
            <Icon icon={isFocused ? IconCatalog.chevronUp : IconCatalog.chevronDown} />
          </div>
        </div>
        {assistiveText && <span className={classes.assistiveText}>{assistiveText}</span>}
      </div>
    );
  },
);
