import React, { useState } from 'react';
import cn from 'classnames';
import { useClipboard } from 'use-clipboard-copy';

import { Translations, Locale } from '../../../common';
import { COPIED_TIMEOUT, SimpleTooltip, TooltipPosition } from '../..';

interface ClickToCopyProps {
  /**
   * The locale to be used by the component
   */
  locale: Locale;
  /**
   * The className of the content
   */
  className?: string;
  /**
   * The position of the tooltip
   */
  position?: TooltipPosition;
  /**
   * The time in milliseconds to display the copied text
   */
  copiedTimeout?: number;
  /**
   * Child element
   */
  children: JSX.Element;
  /**
   * Text to be copied
   */
  textToCopy: string;
}

const translations: Translations = {
  en: {
    copy: 'Copy',
    copied: 'Copied text',
  },
  es: {
    copy: 'Copiar',
    copied: 'Texto copiado',
  },
  pt: {
    copy: 'Cópia',
    copied: 'Texto copiado',
  },
};

export const ClickToCopy = ({
  className,
  children,
  textToCopy,
  locale,
  copiedTimeout = COPIED_TIMEOUT,
  position = TooltipPosition.RIGHT,
}: ClickToCopyProps) => {
  const [open, setOpen] = useState(false);
  const clipboard = useClipboard({ copiedTimeout });
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);

  const classes = {
    container: cn(
      'e-cursor-pointer hover:e-bg-neutral-500 active:e-bg-neutral-500 e-left-1/4 e-inline-block',
      className,
      {
        'e-bg-neutral-500': tooltipIsOpen,
      },
    ),
  };

  const copiedTranslation = translations[locale].copied;
  const copyTranslation = translations[locale].copy;
  const copyText = clipboard.copied ? copiedTranslation : copyTranslation;

  const handleOnClick = () => clipboard.copy(textToCopy);

  return (
    <SimpleTooltip
      title=""
      description={copyText}
      onClick={handleOnClick}
      onOpenChange={setTooltipIsOpen}
      preventClose={true}
      controlled={true}
      open={open}
      contentClassName="e-z-30 e-cursor-pointer"
      position={position}>
      <span
        className={classes.container}
        onClick={handleOnClick}
        onMouseOver={() => setOpen(true)}
        onMouseOut={() => setOpen(false)}>
        {children}
      </span>
    </SimpleTooltip>
  );
};
